import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Avatar, Button, Divider, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Text, useBreakpointValue, useColorModeValue, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Link from "../../../../components/buttons/link";
import { selectedConversationAtom } from "../../../../recoil/atoms";
import { paths } from "../../../../utils/constants";
import { getCustomerFromConversationMembers, getPartnerFromConversationMembers } from "../../../../utils/functions";
import Messages from "./messages";
import SendMessageControl from "./send-message-control";

export default function Content() {

  const conversation = useRecoilValue(selectedConversationAtom);
  const customer = useMemo(() => getCustomerFromConversationMembers(conversation.conversation.members), [conversation]);

  const navigate = useNavigate();

  return (
    <VStack
      spacing={5}
      p={5}
      height={"full"}
      width={"full"}
      overflowY={"scroll"}
      position={'relative'}
    >
      <HStack
        width={"full"}
        spacing={3}
      >
        <ChevronLeftIcon display={useBreakpointValue({ base: "block", md: "none" })} w={6} h={6} cursor={"pointer"} onClick={() => navigate(-1)} />
        <Avatar
          width={"44px"}
          height={"44px"}
          src={customer.imageS3Name}
        />
        <VStack
          width={"full"}
          alignItems={"start"}
          spacing={"1px"}
        >
          <Text
            fontWeight={"semibold"}
            fontSize={"sm"}
            color={useColorModeValue("gray.800", "gray.100")}
          > {customer.firstName} { customer.familyName } </Text>
          <Text
            fontSize={"xs"}
            color={useColorModeValue("green.400", "green.200")}
          > online </Text>
        </VStack>
        <Menu>
          <MenuButton as={Button} size={"sm"} variant={'ghost'}>
            <FiMoreVertical size={"16px"} />
          </MenuButton>
          <MenuList fontSize={"sm"}>
            <MenuItem><Link href={paths.customer + "/" + customer.id}> Partner Details </Link></MenuItem>
            <MenuItem><Link href={paths.customer + "/" + customer.id} color={"red"} fontWeight={"semibold"}> Report </Link></MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Divider />

      <Messages />
      
      <SendMessageControl />
      
    </VStack>
  )
}