import { Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getNegotiationMessages } from "../../../../api/queries";
import Skeletons from "../../../../components/loaders/skeletons";
import ScrollableFeed from "../../../../components/scrollable-feed";
import useMessageSubscription from "../../../../hooks/use-message-subscription";
import { cognitoPartnerAtom, lastMessageAtom, postRenderMessagesAtom, selectedConversationAtom } from "../../../../recoil/atoms";
import { getCustomerFromConversationMembers, unStringfy } from "../../../../utils/functions";
import Offer from "./message-types/offer";
import OpeningMessage from "./message-types/opening";
import ProposalMessage from "./message-types/proposal";
import ProposalAcceptance from "./message-types/proposal-acceptance";
import ProposalDecline from "./message-types/proposal-decline"
import CustomerAccepted from "./message-types/customer-acceptance";
import CustomerDecline from "./message-types/customer-decline";


export default function Messages() {

  const user = useRecoilValue(cognitoPartnerAtom);
  const conversation = useRecoilValue(selectedConversationAtom);
  const [postRenderMessages, setPostRenderMessages] = useRecoilState(postRenderMessagesAtom);
  const setLastMessage = useSetRecoilState(lastMessageAtom);

  /* FETCH MESSAGES FUNCTIONALITY */
  const limit = 16;
  const {
    isFetching,
    data,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["negotiation-messages", { user, limit, conversation }], getNegotiationMessages, {
    getNextPageParam: previousPage => previousPage.nextToken,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  /* SUBSCRIPTION */
  const { incomingMessage } = useMessageSubscription({ conversationId: conversation.conversation.id });
  useEffect(() => {
    if (incomingMessage) {
      setPostRenderMessages(messages => [...messages, incomingMessage]);
    }
  }, [incomingMessage, setPostRenderMessages]);

  const { inView, ref } = useInView();
  useEffect(() => {
    inView && hasNextPage && fetchNextPage();
  }, [inView]);


  // set lastMessageState to be used in the send-message-controls
  useEffect(() => {
    if (data && data.pages) {
      setLastMessage(postRenderMessages.length ? postRenderMessages[postRenderMessages.length - 1] : data.pages[0].messages[0]);
    }
  }, [data, postRenderMessages])

  /* PARSE MESSAGES */
  function returnProperMessageTypeComponent({ messageData, props }) {
    if (messageData.type === "opening") return <OpeningMessage {...props} />
    else if (messageData.type === "proposal") return <ProposalMessage {...props} />
    else if (messageData.type === "offer") return <Offer {...props} />
    else if (messageData.type === "proposal-acceptance") return <ProposalAcceptance {...props} />
    else if (messageData.type === "proposal-decline") return <ProposalDecline {...props} />
    else if (messageData.type === "customer-accepted") return <CustomerAccepted {...props} />
    else if (messageData.type === "customer-declined") return <CustomerDecline {...props} />
  }
  // parses message JSON data, configure message props, generates proper message component and appends to the messages array;
  function configureMessage({ message, messageIndex = -1, pageIndex = -1, page = { messages: [] }, isLastMessageBySenderInSubMessageList }) {
    // custom json data we send in the message body.
    const messageData = unStringfy(message.message.message);
    if (!messageData) return console.log(message);;
    const props = {
      message: { ...message, data: messageData },
      key: message.message.id,
      isLastMessageBySenderInSubMessageList,
      ref: ((postRenderMessages.length && pageIndex === data.pages.length - 1) || pageIndex === data.pages.length - 1) && messageIndex === page.messages.length - 1 ? ref : null
    };
    return returnProperMessageTypeComponent({ messageData, props });
  }

  const messages = useMemo(() => {
    if (data && data.pages) {
      const messages = [];
      const numberOfPages = data.pages.length - 1;
      for (let pageIndex = numberOfPages; pageIndex >= 0; pageIndex--) {
        let page = data.pages[pageIndex];
        const numberOfMessagesInPage = page.messages.length - 1;
        for (let messageIndex = numberOfMessagesInPage; messageIndex >= 0; messageIndex--) {
          let message = page.messages[messageIndex];
          const previousPage = data.pages[pageIndex-1];
          const nextMessage = page.messages[messageIndex-1] || (previousPage && previousPage.messages[previousPage.messages.length-1]) || postRenderMessages[0];
          const isLastMessageBySenderInSubMessageList = !nextMessage || nextMessage.message.senderId !== message.message.senderId;
          messages.push(configureMessage({ message, pageIndex, messageIndex, page, isLastMessageBySenderInSubMessageList }));
        }
      }
      postRenderMessages.forEach((message, messageIndex) => {
        const nextMessage = postRenderMessages[messageIndex+1];
        const isLastMessageBySenderInSubMessageList = !nextMessage || nextMessage && nextMessage.message.senderId !== message.message.senderId;
        messages.push(configureMessage({ message, pageIndex: data.pages.length, messageIndex, page: { messages: postRenderMessages }, isLastMessageBySenderInSubMessageList }));
      });
      return messages;
    }
  }, [data, postRenderMessages]);


  return (
    <ScrollableFeed
      height={"full"}
      overflowY={"scroll"}
      className={"no-scroll"}
    >
      {
       isFetching && (
        <Flex
          align={"center"}
          justify={"center"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={"medium"}
            mr={4}
          > loading messages </Text>
          <Spinner size={"sm"} />
        </Flex>
       )
      }

      {messages}
    </ScrollableFeed>
  )
}


/*


<ScrollableFeed
      ref={scrollableFeedRef}
      spacing={3}
      height={"full"}
      width={"full"}
      overflowY={"scroll"}
      className={"no-scroll"}
      onScrollComplete={handleScroll}
    >
     

    </ScrollableFeed>


*/