import Auth from '@aws-amplify/auth';
import axios from 'axios';
import Compressor from 'compressorjs';
import { DefaultValue } from 'recoil';
import { selectorPrefix } from './constants';

const CUSTOM_AUTH_TTL = 1000 * 60 * 60 * 24 * 30; // 30 days in Milliseconds

export const capitalizeEveryFirstLetter = mySentence =>
  mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
export function unStringfy(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const textFromCamelCase = text =>
  text
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, str => str.toUpperCase());


export function clearCustomAuthSession() {
  window.localStorage.removeItem('CustomAuthSession');
}

export async function loadCustomAuthSession() {
  const raw = window.localStorage.getItem('CustomAuthSession');
  if (!raw) {
    throw new Error('No custom auth session');
  }
  const storedSession = window.JSON.parse(raw);
  if (storedSession.expiresAt < window.Date.now()) {
    clearCustomAuthSession();
    throw new Error('Stored custom auth session has expired');
  }
  const username = storedSession.username;
  // Accessing private method of Auth here which is BAD, but it's still the
  // safest way to restore the custom auth session from local storage, as there
  // is no interface that lets us do it.
  // (If we created a new user pool object here instead to pass to a
  // CognitoUser constructor that would likely result in hard to catch bugs,
  // as Auth can assume that all CognitoUsers passed to it come from its pool
  // object.)
  const user = await Auth.createCognitoUser(username);
  // Session is not exposed to TypeScript, but it's a public member in the
  // JS code.
  user.Session = storedSession.session;

  return user;
}

export function storeCustomAuthSession(cognitoUser) {
  // Session isn't exposed to TypeScript, but it's a public member in JS
  const session = cognitoUser.Session;
  const expiresAt = window.Date.now() + CUSTOM_AUTH_TTL;
  const otpSession = {
    session,
    expiresAt,
    username: cognitoUser.getUsername(),
  };
  const json = window.JSON.stringify(otpSession);
  window.localStorage.setItem('CustomAuthSession', json);
  // window.localStorage.setItem('isAuthenticated', true);
}

export const localPersist = ({ onSet, setSelf, node }) => {
  const storedItems = localStorage.getItem(node.key);
  if (storedItems != null) {
    setSelf(unStringfy(storedItems));
  }

  onSet(newItems => {
    if (newItems instanceof DefaultValue) {
      localStorage.removeItem(node.key);
    } else {
      localStorage.setItem(node.key, JSON.stringify(newItems));
    }
  });
};

export const localSetPersist = ({ onSet, setSelf, node }) => {
  const storedItems = localStorage.getItem(node.key);
  if (storedItems != null) {
    setSelf(unStringfy(storedItems));
  }
};

export function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
}

export function intToHex(nr) {
  return nr.toString(16).padStart(2, '0');
}

export function validateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export const formatDate = date => {
  let d = date ? date : new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export function getCustomerFromConversationMembers(members) {
  if (!Array.isArray(members)) return;

  return members.filter(m => m.entityType === 'CUSTOMER')[0];
}
export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1].sender.id !== userId &&
    messages[messages.length - 1].sender.id
  );
};

export function getPartnerFromConversationMembers(members) {
  if (!Array.isArray(members)) return;
  if (members.length > 2) return;

  return members.filter(m => m.entityType === 'PARTNER')[0];
}

export function formatPrice(value, opts = {}) {
  const { locale = 'en-ZM', currency = 'ZMW' } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function isMTNNumber(num) {
  return ['26076', '26096'].includes(num.slice(0, 5));
}

export function isAirtelNumber(num) {
  return ['26077', '26097'].includes(num.slice(0, 5));
}

export function isValidMobileMoneyNumber(num) {
  return ['26076', '26096', '26077', '26097'].includes(num.slice(0, 5));
}



export async function uploadPhoto({
  contentType = '',
  description = '',
  file = '',
  title = '',
  uploadUrl = '',
  creatorType,
  creatorId,
}) {
  var data = JSON.stringify({
    contentType,
    description,
    file,
    title,
    creatorType,
    creatorId,
  });
  console.log(creatorType, '=:', creatorId);
  var config = {
    method: 'post',
    url: uploadUrl,
    data,
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    return error;
  }
}

export const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object

      baseURL = reader.result;

      resolve(baseURL);
    };
  });
};

export const compressImage = file => {
  return new Promise(resolve => {
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      // eslint-disable-next-line no-loop-func
      success: async compressedResult => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        resolve(compressedResult);
      },
    });
  });
};

export const toKebabCase = (sentence) =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(x => removeNonAlphanumerics(x))
    .filter(x => x)
    .join('-');

export const removeNonAlphanumerics = (sentence) =>
  sentence.replace(/[^A-Za-z0-9]/g, '');

export const toPascalCase = (sentence) =>
  sentence
    .toLowerCase()
    .split(' ')
    .map(x => removeNonAlphanumerics(x))
    .filter(x => x)
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join('');

export const getPinpointButtonClickEventProp = ({
  command,
  currentPage,
}) => {
  /** 
   * INPUTS:
        command: The action the button executes. EG create request
        currentPage: The page on which the button is on. EG: /orders#cart
    * OUTPUTS: {data-pinpoint-analytics-create-request: OrdersCartPage_CreateRequest}

*/
  let value = toPascalCase(command);
  if (currentPage) {
    currentPage = currentPage.replace(/[^A-Za-z0-9]/g, ' ');
    value = currentPage.toLowerCase().includes('page')
      ? toPascalCase(currentPage) + '_' + value
      : toPascalCase(currentPage + ' Page') + '_' + value;
  }
  return { [selectorPrefix + toKebabCase(command)]: value };
};
