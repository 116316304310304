import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import Carousel from ".";
import { modalCarouselChildrenAtom, modalCarouselStateAtom } from "../../recoil/atoms";




export default function CarouselModal() {

  const [open, setOpen] = useRecoilState(modalCarouselStateAtom);
  const [children, setChildren] = useRecoilState(modalCarouselChildrenAtom);

  const close = () => setOpen(false);

  const modalSize = useBreakpointValue({
    base: "full",
    md: "2xl"
  });

  const motionPreset = useBreakpointValue({
    base: 'slideInBottom',
    md: 'scale',
  });

  return (
    <Modal
      isOpen={open}
      onClose={close}
      size={"full"}
      scrollBehavior={'inside'}
      isCentered
      blockScrollOnMount
      closeOnEsc
      motionPreset={motionPreset}
    >
      <ModalOverlay
        bg="blackAlpha.400"
        backdropFilter="auto"
        backdropBlur="5px"
      />
      <ModalContent maxH={'400px'} height={'full'} p={0}>
        <ModalHeader position={'absolute'} width={'full'}>
          <ModalCloseButton color={'white'} />
        </ModalHeader>
        <ModalBody
          as={Flex}
          justifyContent={"space-around"}
          alignItems={"center"}
          height={"full"}
          bg={"black"}
        >
          <Carousel
            children={children}
            showModal={false}
            automatic={true}
            buttonPosition={"bottom"}
            displayButtons={true}
            width={"full"}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}