import {
  Box,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Image,
  Input, Text,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ButtonPrimary } from '../components/buttons';
import Link from '../components/buttons/link';
import CountrySelect from '../components/inputs/country-select';
import Main from "../components/layouts/Main";
import { cognitoPartnerAtom, OTPPhoneNumberAtom, partnerContactDetailsAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';
import countryCodes from "../utils/countries";
import {
  getRandomString,
  storeCustomAuthSession,
  validateEmail
} from '../utils/functions';



export default function Register() {

  const [country, setCountry] = useState(countryCodes[countryCodes.length - 2]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [OTPPhoneNumber, setOTPPhoneNumber] =
    useRecoilState(OTPPhoneNumberAtom);
  const setCognitoPartner = useSetRecoilState(cognitoPartnerAtom);
  const partnerContactDetails = useRecoilValue(partnerContactDetailsAtom);

  const toast = useToast();

  const signUp = useMutation(async ({ firstName = " ", familyName = " ", businessName, email, phoneNumber }) => {
    let attributes = {
      'custom:givenName': firstName,
      'custom:familyName': familyName,
      'custom:userType': 'PARTNER',
      'custom:businessName': businessName,
      'email': email,
    };
    setOTPPhoneNumber(phoneNumber);
    const password = getRandomString(8);
    return await Auth.signUp({
      username: phoneNumber,
      password,
      attributes,
    });
  });

  const signIn = useMutation(async ({ phoneNumber }) => {
    return await Auth.signIn(phoneNumber);
  })

  useEffect(() => {
    if (signUp.isSuccess) {
      signIn.mutate({ phoneNumber: OTPPhoneNumber });
    }
    else if (signUp.isError) {
      if (signUp.error instanceof Error && signUp.error.name === 'UsernameExistsException') {
        toast({
          status: 'info',
          title: 'The phone number provided is already in use by an account.',
          description:
            'We have sent an OTP to ' +
            OTPPhoneNumber +
            '. Enter it on the next screen to sign-in.',
          position: 'bottom',
          duration: 7000,
          isClosable: true,
        });
        signIn.mutate({ phoneNumber: OTPPhoneNumber });
      }
      else toast({
        status: "error",
        title: "Something went wrong",
        description: "Please try again",
        duration: 2000,
        position: "bottom",
        isClosable: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUp.isSuccess, signUp.isError]);

  useEffect(() => {
    (function () {
      if (signIn.isSuccess) {
        setCognitoPartner(signIn.data);
        storeCustomAuthSession(signIn.data);
        navigate(paths.otp);
        
      } else if (signIn.isError) {
        toast({
          title: "An error occured while trying to log you in",
          description: "Please try again here",
          duration: 3000,
          position: "bottom",
          isClosable: true
        });
        navigate(paths.login);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn.isSuccess, signIn.isError]);

  function onSubmit(formData) {
    const phoneNumberWithPrependedCountryCode =
      formData.phoneNumber.length === 10
        ? '+26' + formData.phoneNumber
        : country?.dial_code + formData.phoneNumber;

    formData.phoneNumber = isValidPhoneNumber(
      phoneNumberWithPrependedCountryCode,
    )
      ? phoneNumberWithPrependedCountryCode
      : isValidPhoneNumber(formData.phoneNumber)
      ? formData.phoneNumber
      : null;
      
      if (!formData.phoneNumber) {
      return toast({
        status: "warning",
        title: "Enter a valid phone number",
        position: "bottom",
        duration: 1500
      });
    }

    setOTPPhoneNumber(formData.phoneNumber);
    signUp.mutate(formData);

  }



  return (
    <Main
      justifyContent={"center"}
      alignItems={"center"}
      bg={useColorModeValue("gray.100", "gray.800")}
    >

      <Text
        fontSize={"3xl"}
        fontWeight={"bold"}
        cursor={"pointer"}
      >  </Text>

      <Image
        src={"/512.png"}
        width={"54px"}
        height={"54px"}
        m={"auto"}
        rounded={"full"}
        cursor={"pointer"}
        onClick={() => navigate(paths.home)}
      />

      <Heading
        textAlign={"center"}
      > Riaparts Partner </Heading>

      <Box
        as={"form"}
        width={"full"}
        maxW={"lg"}
        bg={useColorModeValue("white", "blackAlpha.700")}
        className={useColorModeValue("bxs-uh", "")}
        px={4}
        py={6}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          isInvalid={Object.values(errors).length > 0}
          as={VStack}
          spacing={3}
          alignItems={"start"}
          justifyContent={"flex-start"}
        >
          <Input
            placeholder="Business name"
            variant={"outline"}
            color={useColorModeValue('black', 'gray.500')}
            _placeholder={{
              color: useColorModeValue('black', 'gray.500'),
            }}
            defaultValue={partnerContactDetails.businessName}
            {
            ...register('businessName', {
              required: 'The business name is required',
            })
            }
          />
          <HStack
            width={'full'}
          >
            <CountrySelect
              mini={true}
              onChangeCallback={
                country => setCountry(country)
              }
              bg={useColorModeValue('white', 'gray.700')}
            />
            <Input
              placeholder="Business Phone Number"
              type="tel"
              bg={useColorModeValue('white', 'gray.700')}
              variant={"outline"}
              width={"full"}
              my="1"
              {...register('phoneNumber', {
                required: 'The phone number is required',
                validate: value => {
                  return isValidPhoneNumber(country.dial_code + value) || isValidPhoneNumber(value)
                },
                minLength: {
                  value: 7,
                  message: 'Minimum phone number length should be 10 including the country code'
                },
              })}
              defaultValue={OTPPhoneNumber}
              color={useColorModeValue('black', 'white')}
              _placeholder={{
                color: useColorModeValue('black', 'white'),
              }}
            />
          </HStack>

          <Input
            placeholder="Business email"
            type="email"
            variant={"outline"}
            color={useColorModeValue('black', 'gray.500')}
            _placeholder={{
              color: useColorModeValue('black', 'gray.500'),
            }}
            defaultValue={partnerContactDetails.email}
            {
              ...register('email', {
                validate: value => {
                  if (value) {
                    return validateEmail(value);
                  }
                  return true;
                },
                required: 'The email is required',
              })
            }
          />

          <FormErrorMessage>
            {errors.phoneNumber && (errors.phoneNumber.message || "Please enter a valid phone number.") }
          </FormErrorMessage>

          <FormErrorMessage>
            {errors.email && (errors.email.message || "Please enter a valid email address.")}
          </FormErrorMessage>

          <ButtonPrimary
            type={"submit"}
            isLoading={signUp.isLoading || signIn.isLoading}
            width={"full"}
          > Next </ButtonPrimary>

          <Text
            fontSize={"sm"}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            Already have an account ?{" "}
            <Link
              fontWeight={"medium"}
              textDecoration={"underline"}
              href={paths.login}
            >Login Here</Link>
          </Text>
        </FormControl>
      </Box>

    </Main>
  );
}


/*







import React, { useState, useEffect, createRef } from 'react';
import {
  Heading,
  Stack,
  useColorModeValue,
  Box,
  Input,
  Button,
  Text,
  Alert,
  AlertIcon,
  FormControl,
  InputLeftAddon,
  InputGroup,
  Select,
  HStack,
  FormErrorMessage,
  Center,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { NavLink, useNavigate } from 'react-router-dom';
import Section from '../components/layouts/Section';
import { paths, countryCodes } from '../utils/constants';
import { useForm } from 'react-hook-form';
import ReactCountryFlag from "react-country-flag";
import {
  cognitoPartnerAtom,
  OTPPhoneNumberAtom,
  partnerContactDetailsAtom,
} from '../recoil/atoms';
import { Auth } from 'aws-amplify';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { onError } from '../utils/errorLib';
import { getRandomString, storeCustomAuthSession, validateEmail } from '../utils/functions';

export default function Register() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const history = useNavigate();
  const toast = useToast();
  const [OTPPhoneNumber, setOTPPhoneNumber] =
    useRecoilState(OTPPhoneNumberAtom);
  const [partnerContactDetails, setPartnerContactDetails] = useRecoilState(
    partnerContactDetailsAtom
  );
  const setCognitoPartner = useSetRecoilState(cognitoPartnerAtom);
  const [authError, setAuthError] = useState({
    error: false,
    message: '',
  });
  const [country, setCountry] = useState(countryCodes[countryCodes.length-2]);
  const countryRef = createRef();

  useEffect(() => {
    console.log(authError)
    if (authError.error) {
      setTimeout(() => {
        setAuthError({
          error: !authError.error,
          message: '',
        });
      }, 10000);
    }
  }, [authError.error]);

  async function onSubmit(data) {
    console.log('🚀 ~ file: Register.js ~ line 45 ~ onSubmit ~ data', data);
    let { businessName, phoneNumber, address, email, firstName, familyName, country } =
      data;
  // country is stored as a json string. JSON.parse() gives you a country object with name, country_code and dial_code;
  // instead, you can use the country state, created on line 46. its value should be the same as values.country; Prolly the best way to do it.
  country = JSON.parse(country);
  // append country-code to phoneNumber if phoneNumber length is lesser than 9
  phoneNumber = phoneNumber.length <= 10 ? country.dial_code + phoneNumber : phoneNumber;
  // doing phone-number validation here for now
  if(!isValidPhoneNumber(phoneNumber)) {
    return toast({
      title: 'Invald Phone Number',
      description: "Enter a valid phone number excluding your country dial code",
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
   }
    let partner;
    setOTPPhoneNumber(phoneNumber);
    setPartnerContactDetails({
      ...data,
    });
    try {
      let attributes = {
        'custom:givenName': firstName,
        'custom:familyName': familyName,
        'custom:userType': 'PARTNER',
        'custom:businessName': businessName,
        email,
      };

      const password = getRandomString(8);
      await Auth.signUp({
        username: phoneNumber,
        password,
        attributes,
      });

      partner = await Auth.signIn(phoneNumber);
      console.log(partner);
      setCognitoPartner(partner);
      storeCustomAuthSession(partner)
      //! If signup succeeds, go to the OTP page
      console.log('got to otp page after sign up');
      history.push(paths.otp);
    } catch (err) {
      //! if sign up fails, show error
      setAuthError({
        error: true,
        message:
          'There was an error with the login/sign up process, please try with a different number or try later',
      });
      console.log(err)
      onError(err);
      return;
    }
  }

  return (
    <Center
      backgroundColor={useColorModeValue("aliceblue","gray.800")}
      minH={"80vh"}
      p={6}
    >
      <Stack
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={{ base: 4, sm: 6, md: 8 }}
        spacing={5}
        width={'full'}
        maxW={"xl"}
        className={"bxs-uh"}
      >
        <Stack spacing={4}>
          <Heading
            color={useColorModeValue('gray.800', 'gray.50')}
            lineHeight={1.1}
            fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            textAlign="center"
          >
            Hi Partner!
          </Heading>
        </Stack>
        <Box as={'form'} mt={10} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {authError.error && (
              <Alert status="error">
                <AlertIcon />
                {authError.message}
              </Alert>
            )}
            <FormControl isInvalid={Object.values(errors).length > 0}>
              <Input
                placeholder="Business name"
                bg={'gray.100'}
                border={0}
                color={useColorModeValue('black', 'gray.500')}
                _placeholder={{
                  color: useColorModeValue('black', 'gray.500'),
                }}
                defaultValue={partnerContactDetails.businessName}
                my="1"
                {...register('businessName', {
                  required: 'The business name is required',
                })}
              />
              <HStack
              my="1"
              >
                <InputGroup
            bg={'gray.100'}
            width={"30%"}
            borderRadius={"md"}
            >
            <InputLeftAddon>
            <ReactCountryFlag
                countryCode={country.code}
                svg
                style={{
                    width: '1.5em',
                    height: '1.5em',
                }}
                title={country.code}
            />

            </InputLeftAddon>
            <Select
            value={JSON.stringify(country)}
            bg={'gray.100'}
            border={0}
            color={useColorModeValue('black', 'gray.500')}
            _placeholder={{
              color: useColorModeValue('black', 'gray.500'),
            }}
            _focus={{
              border: 0,
              outline: 0
            }}
            {...register('country', {
              onChange: (e) => {
                setCountry(JSON.parse(e.target.value))
              }
            })
          }
          
            >
              { countryCodes.map(country => {
              return (
                <option key={country.code} value={JSON.stringify(country)}> { country.dial_code.toString() + ', ' + country.name } </option>
              )
              })
            }
            </Select>
            </InputGroup>
              <Input
                placeholder="Business number"
                bg={'gray.100'}
                border={0}
                color={useColorModeValue('black', 'gray.500')}
                _placeholder={{
                  color: useColorModeValue('black', 'gray.500'),
                }}
                defaultValue={OTPPhoneNumber}
                {...register('phoneNumber', {
                  required: 'The phone number is required',
                  // validate: value => isValidPhoneNumber(value),
                  minLength: {
                    value: 9,
                    message: 'Minimum length should be 9, for your phone number',
                  },
                })}
              />
              </HStack>

              <Input
                placeholder="Business email"
                bg={'gray.100'}
                type="email"
                border={0}
                color={useColorModeValue('black', 'gray.500')}
                _placeholder={{
                  color: useColorModeValue('black', 'gray.500'),
                }}
                defaultValue={partnerContactDetails.email}
                my="1"
                {...register('email', {
                  validate: value => {
                    if (value) {
                      return validateEmail(value);
                    }
                    return true;
                  },
                  required: 'The email is required',
                })}
              />
              <Input
                placeholder="First Name of your contact person"
                bg={'gray.100'}
                border={0}
                color={useColorModeValue('black', 'gray.500')}
                _placeholder={{
                  color: useColorModeValue('black', 'gray.500'),
                }}
                my="1"
                defaultValue={partnerContactDetails.firstName}
                {...register('firstName', {
                  required: 'The first name is required',
                })}
              />
              <Input
                placeholder="Last name of your contact person"
                bg={'gray.100'}
                border={0}
                color={useColorModeValue('black', 'gray.500')}
                _placeholder={{
                  color: useColorModeValue('black', 'gray.500'),
                }}
                my="1"
                defaultValue={partnerContactDetails.familyName}
                {...register('familyName', {
                  required: 'The family name is required',
                })}
              />
              <FormErrorMessage>
                {errors.phoneNumber && errors.phoneNumber.message}
              </FormErrorMessage>
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
              <FormErrorMessage>
                {errors.familyName && errors.familyName.message}
              </FormErrorMessage>
              <FormErrorMessage>
                {errors.businessName && errors.businessName.message}
              </FormErrorMessage>
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
              <FormErrorMessage>
                {errors.address && errors.address.message}
              </FormErrorMessage>
            </FormControl>

            <Text color={useColorModeValue('blue', 'pink')}>
              <NavLink to={paths.customerLogin}>
                Already have a partner account? login here
              </NavLink>
              <br />
              <NavLink to={paths.customerLogin}>Customers login here</NavLink>
            </Text>
          </Stack>
          <Button
            fontFamily={'heading'}
            mt={8}
            w={'full'}
            type="submit"
            bgGradient="linear(to-r, red.400,pink.400)"
            color={'black'}
            _hover={{
              bgGradient: 'linear(to-r, red.400,pink.400)',
              boxShadow: 'xl',
            }}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </Center>
  );
}












*/