import {
  Flex,
  HStack,
  Image,
  Spacer,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { quoteAtom } from '../../recoil/atoms';
import { formatPrice, getPinpointButtonClickEventProp } from '../../utils/functions';
import Link from '../buttons/link';
import Carousel from '../carousel';
import ImageContainer from '../carousel/image-container';
import { MomentsAgo } from '../miscellaneous';

export default forwardRef(function ({ quote, ...props }, ref) {
  const setSelectedQuoted = useSetRecoilState(quoteAtom);

  const navigate = useNavigate();
  const location = useLocation();

  const { photos, autoPart, request } = quote;
  return (
    <VStack
      alignItems={'start'}
      p={3}
      backgroundColor={useColorModeValue('white', 'gray.700')}
      borderRadius={'4px'}
      boxShadow={'xs'}
      ref={ref}
    >
      <Flex width={'full'} px={1} marginTop={'-26px'}>
        <Spacer />
        <Tag
          as={HStack}
          spacing={2}
          size={'xs'}
          transform={'translateY(22px)'}
          colorScheme={
            quote.quote.status === 'IN_PROGRESS'
              ? 'blue'
              : quote.quote.status === 'OPEN'
              ? 'green'
              : 'gray'
          }
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'xs'}
          textTransform={'lowercase'}
          borderRadius={'12px'}
          px={'6px'}
          py={'4px'}

        >
          <Text
            fontSize={'10px'}
            fontWeight={'semibold'}
            color={
              quote.quote.status === 'IN_PROGRESS'
                ? 'blue'
                : quote.quote.status === 'OPEN'
                ? 'green'
                : 'gray'
            }
          >
            {' '}
            {quote.quote.status === 'IN_PROGRESS'
              ? 'accepted'
              : quote.quote.status === 'OPEN'
              ? 'received'
              : 'closed'}{' '}
          </Text>
        </Tag>
      </Flex>
      <Carousel width={'full'} height={'160px'} borderRadius={'4px'}>
        {photos &&
          photos.length > 0 &&
          photos.map(photo => {
            return (
              photo && (
                <ImageContainer key={photo.imageS3Name}>
                  <Image
                    src={photo.imageS3Name}
                    objectFit={'cover'}
                    width={'full'}
                    height={'full'}
                  />
                </ImageContainer>
              )
            );
          })}
      </Carousel>

      <HStack width={'full'} align={'start'} justify={'start'}>
        <VStack
          align={'start'}
          justify={'start'}
          spacing={'1px'}
          width={'full'}
        >
          <Text
            fontWeight={'semibold'}
            fontSize={{
              base: '14px',
              xl: '12px',
            }}
          >
            {request.autoPart.quantity}{' '}
            <Text as={'span'} fontWeight={'light'}>
              &times;
            </Text>{' '}
            {autoPart.autoPart.name}
          </Text>
          <Text
            fontSize={{
              base: '12px',
            }}
            color={useColorModeValue('gray.500', 'gray.300')}
          >
            {request.request.make} - {request.request.model} -{' '}
            {request.request.variant}
          </Text>
          <Text
            fontSize={'sm'}
            color={useColorModeValue('gray.800', 'gray.100')}
          >
            <Text as={'b'} fontWeight={'bold'}>
              {' '}
              {formatPrice(
                autoPart.quotedPrice * quote.request.autoPart.quantity
              )}{' '}
            </Text>
            <Text as={'span'}> total </Text>
          </Text>
        </VStack>
      </HStack>

      <HStack width={'full'}>
        <MomentsAgo date={quote.quote.created} />
        <Spacer />
        <Link
          fontSize={'xs'}
          fontWeight={'medium'}
          href={location.pathname + '#quote-' + quote.quote.id}
          {...getPinpointButtonClickEventProp({
            command: `${
              quote.quote.status === 'OPEN' ? 'edit quote' : 'more details'
            } button link`,
            currentPage: location.pathname,
          })}
          onClick={e => {
            e.preventDefault();
            // flushSync(() => setSelectedQuoted(quote));
            setSelectedQuoted(quote);
            navigate(location.pathname + '#quote-' + quote.quote.id);
          }}
        >
          {' '}
          {quote.quote.status === 'OPEN' ? 'edit quote' : 'more details'}{' '}
        </Link>
      </HStack>
    </VStack>
  );
});

/*

export default forwardRef(function(props, ref) {

    const [selectedQuote, setSelectedQuote] = useRecoilState(quoteAtom);
    const [directLink, setDirectLink] = useState(null);
    
    const history = useNavigate();
    
    useEffect(
        () => {
            if(selectedQuote && directLink) {
                history.push(directLink+"/"+selectedQuote.quote.id);
            }
        },
        [selectedQuote]
    );
    
    useEffect(
        () => {
            if(directLink) {
                setSelectedQuote(props);
            }
        },
        [directLink]
    );

      const { quote, photos, request, autoPart } = props;


    return (
        <Box
            width={"full"}
        >
            <ScaleFade
                in={true}
            >
                <Box
                    p={4}
                    width={'full'}
                    maxW={"lg"}
                    mx={"auto"}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'sm'}
                    rounded={'lg'}
                    ref={ref}
                >
                    <Box
                        width={"full"}
                    >
                        <HStack>
                        <Text
                            fontSize={"lg"}
                            fontWeight={"bold"}
                        >
                            { request.request.make }
                        </Text>
                        <Spacer />
                        <Text fontSize={"xs"} fontWeight={"400"} color={quote.status === "OPEN" ? "green.300" : quote.status === "closed" ? "red" : "white" }> { quote.status } </Text>
                        </HStack>
                        <Text fontWeight={"semibold"}> { request.request.model } | { request.request.year } | { request.request.gearbox } </Text>
                        <Text fontWeight={"semibold"} > { autoPart.autoPart.name } <span style={{ fontWeight: 300 }} ><>&times;</></span> { autoPart.quantity } <span style={{ fontWeight: 300 }} >@</span> K{ autoPart.quotedPrice } each </Text>
                        </Box>
                        <Button
                            backgroundColor={"transparent"}
                            _hover={{
                                backgroundColor: "transparent"
                            }}
                            _focus={{
                                backgroundColor: "transparent"
                            }}
                            as={Text} leftIcon={<TimeIcon />}
                        color={useColorModeValue("gray.600","gray.400")}
                        my={1}> quoted: { moment(quote.created).fromNow() } </Button>
                        <ButtonSoftPrimary
                            width={"full"}
                            onClick={
                                e => {
                                    setDirectLink(paths.quote)
                                }
                            }
                        > Open Quote </ButtonSoftPrimary>

                        <Box>
                        
                        </Box>
                </Box>
            </ScaleFade>
        </Box>
    )
});

*/
