import {
  Stack,
  VStack,
  HStack,
  Image,
  Text,
  Spacer,
  useColorModeValue,
  useToast,
  Tag
} from "@chakra-ui/react";
import Carousel from "../carousel";
import ImageContainer from "../carousel/image-container";
import Link from "../buttons/link";
import { useLocation, useNavigate } from "react-router-dom";
import { forwardRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isAuthenticatedAtom, requestAtom } from "../../recoil/atoms";
import { ButtonPrimary } from "../buttons";
import { getPinpointButtonClickEventProp } from "../../utils/functions";

export default forwardRef(function({ request }, ref) {
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const setRequest = useSetRecoilState(requestAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { pathname } = useLocation();

  const srcCondition = !(request.autoPart.autoPart.src === null || request.autoPart.autoPart.src === undefined)

  return (
    <Stack
      direction={{
        base: "row",
        md: "column"
      }}
      spacing={3}
      p={3}
      px={srcCondition ? 3:8}
      borderRadius={"4px"}
      boxShadow={"xs"}
      bg={useColorModeValue("white","gray.700")}
      ref={ref}
      justifyContent={"space-around"}
      alignItems={"center"}
    >
      {
        srcCondition && (
      <Carousel
        width={{
          base: "72px",
          md: "full"
        }}
        minW={{
          base: "72px",
          md: "full"
        }}
        height={{
          base: "60px",
          md: "150px"
        }}
        minH={{
          base: "60px",
          height: "150px"
        }}
        borderRadius={"4px"}
      >
        {
          srcCondition && request && request.autoPart && request.autoPart.autoPart && request.autoPart.autoPart.src && request.autoPart.autoPart.src.length > 0 && request.autoPart.autoPart.src.map(autopart => {
            return (
              <ImageContainer
                key={autopart}
              >
                <Image
                  src={autopart}
                  width={"full"}
                  height={"full"}
                />
              </ImageContainer>
            )
          })
        }
      </Carousel>
)}

      <HStack
        width={"full"}
      >
        <VStack
          width={"full"}
          spacing={0}
          align={"start"}
        >
          <Text
            fontWeight={"semibold"}
            fontSize={"xs"}
            textTransform={"capitalize"}
          >
            { request.autoPart.quantity }
            <Text
              as={"span"}
              fontWeight={"light"}
            > &times; </Text>
            { request.autoPart.autoPart.name }
          </Text>
          <Text
            fontSize={"10px"}
            color={useColorModeValue("gray.500","gray.300")}
          >
            { request.make } - { request.model } - { request.variant }
          </Text>
          <Link
            display={{
              base: "block",
              md: "none"
            }}
            href={location.pathname+"#request-"+request.request.id}
            {...getPinpointButtonClickEventProp({
              command: 'link to submit quotation',
              currentPage: pathname,
            })}
            onClick={e => {
              e.preventDefault();
              if(!isAuthenticated) return toast({
                status: "info",
                title: "Sign Up",
                description: "You need to have an account for you to submit a quotation.",
                duration: 2000,
                isClosable: true,
                position: "bottom"
              });
              setRequest(request);
              navigate(location.pathname+"#request-"+request.request.id)
            }}
            fontSize={"xs"}
          > submit quotation </Link>
        </VStack>
        <Spacer />
        <VStack
          height={"full"}
        >
          <Tag
            size={"xs"}
            fontSize={"10px"}
            colorScheme={request.request.status === "IN_PROGRESS" || request.request.status === "OPEN" ? "blue" : request.request.status === "CLOSED" ? "green" : "gray"}
            textTransform={"lowercase"}
            borderRadius={"4px"}
            px={"6px"}
            py={"4px"}
          >
            { request.request.status === "IN_PROGRESS" ? "open" : request.request.status === "OPEN" ? "new" : "closed" }
          </Tag>
        </VStack>
      </HStack>
      <ButtonPrimary
        size={"sm"}
        width={"full"}
        bg={"brand.50"}
        color={"brand.600"}
        display={{
          base: "none",
          md: "block"
        }}
        {...getPinpointButtonClickEventProp({
          command: 'submit quotation',
          currentPage: pathname,
        })}
        onClick={e => {
          e.preventDefault();
          if(!isAuthenticated) return toast({
            status: "info",
            title: "Sign Up",
            description: "You need to have an account for you to submit a quotation.",
            duration: 2000,
            isClosable: true,
            position: "bottom"
          });
          setRequest(request);
          navigate(location.pathname+"#request-"+request.request.id)
        }}
      > Submit Quotation </ButtonPrimary>
    </Stack>
  )
})