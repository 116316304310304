import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createQuote } from '../../../api/mutations';
import { getRequest } from '../../../api/queries';
import {
  cognitoPartnerAtom,
  isAuthenticatedAtom,
  quotedAutoPartIsNew,
  quotedPriceAtom,
  quoteExpiresAtom,
  quoteExpiryDateAtom,
  quotePhotosAtom,
  requestAtom,
  requestDrawerIsOpenAtom,
  serviceFeeAtom,
  serviceFeeInclusiveAtom,
} from '../../../recoil/atoms';
import { paths } from '../../../utils/constants';
import { ButtonPrimary } from '../../buttons';
import Content from './content';
import Loading from './loading';

export default function RequestDrawer() {
  /* states */
  const user = useRecoilValue(cognitoPartnerAtom);
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const [request, setRequest] = useRecoilState(requestAtom);
  const [price, setPrice] = useRecoilState(quotedPriceAtom);
  const [isNew, setIsNew] = useRecoilState(quotedAutoPartIsNew);
  const [serviceFeeInclusive, setServiceFeeInclusive] = useRecoilState(
    serviceFeeInclusiveAtom
  );
  const [photos, setPhotos] = useRecoilState(quotePhotosAtom);
  const [serviceFee] = useRecoilState(serviceFeeAtom);
  const [expiryDate, setExpiryDate] = useRecoilState(quoteExpiryDateAtom);
  const [expires, setExpires] = useRecoilState(quoteExpiresAtom);
  const [isOpen, setIsOpen] = useRecoilState(requestDrawerIsOpenAtom);

  /* secondary hooks & refs */
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const createQuoteButtonRef = useRef();

  /* fetch request logic */
  const [go, setGo] = useState(false); // triggers getRequestQuery and holds the request id to be fetched.
  const getRequestQuery = useQuery(['request', { id: go }], getRequest, {
    enabled: !!go,
    refetchOnWindowFocus: false,
    retryDelay: 0,
    onSuccess: data => setRequest(data),
  });
  // trigger getRequestQuery if the requestAtom is not set or the request.request.id is not equal the id we get from the hash in the uri.
  useEffect(() => {
    if (!isOpen) return;
    let requestId = location.hash.split('-')[1];
    requestId = requestId && requestId.replace('#', '');
    if (!request || (requestId && request.request.id !== requestId))
      setGo(requestId);
  }, [location.hash, isOpen]);

  // create quote logic
  const createQuoteMutation = useMutation(createQuote, {
    onSuccess: () => {
      toast({
        status: 'info',
        title: 'You quotation was submitted successfully',
        description: 'We will let you know when the customer makes an order.',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      setPhotos([]);
      setPrice(0);
      setIsNew(true);
      setServiceFeeInclusive(true);
      setExpires(false);
      setExpiryDate(
        moment(new Date().valueOf() + 604800000).format('YYYY-MM-DD')
      );
      setIsOpen(false);
      navigate(paths.quotes);
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Something went wrong',
        description: 'Could not submit your quotation, please try again.',
        duration: 2000,
        position: 'bottom',
        isClosable: true,
      });
    },
  });

  function createQuoteInitiator() {
    if (!isAuthenticated) {
      return toast({
        status: 'info',
        title: 'Sign in',
        description: 'You need to be signed in for you to submit a quote.',
        position: 'bottom',
        isClosable: true,
        duration: 3000,
      });
    } else if (price < 1) {
      return toast({
        status: 'info',
        title: 'Enter a valid price',
        description: `The price cannot be ZERO`,
        position: 'bottom',
        isClosable: true,
        duration: 3000,
      });
    } /* else if (!photos.length) {
      return toast({
        status: 'info',
        title: 'Supply some photos of the autopart',
        description:
          'Photos act as proof of existence and capture the users attention.',
        position: 'bottom',
        isClosable: true,
        duration: 2500,
      });
    } */ else
      createQuoteMutation.mutate({
        user,
        request,
        photos,
        price,
        isNew,
        serviceFeeInclusive,
        serviceFee,
        expires,
        expiryDate,
      });
  }

  // functions and variables.

  const close = () => {
    setRequest(null);
    setIsOpen(false);
    setPrice(0);
    navigate(location.pathname);
  };

  const placement = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const size = useBreakpointValue({
    base: '',
    md: 'sm',
  });

  return (
    <Drawer
      size={size}
      placement={placement}
      isOpen={isOpen}
      onClose={close}
      initialFocusRef={createQuoteButtonRef}
    >
      <DrawerOverlay />

      <DrawerContent
        borderTopRadius={{
          base: '20px',
          md: '0',
        }}
        p={0}
      >
        <DrawerCloseButton />
        <DrawerBody as={VStack} spacing={4} py={6} width={'full'} px={4} mt={3}>
          {getRequestQuery.isLoading || !request ? (
            <Loading />
          ) : (
            <Content request={request} />
          )}
        </DrawerBody>
        <DrawerFooter width={'full'} padding={4}>
          <ButtonPrimary
            width={'full'}
            ref={createQuoteButtonRef}
            disabled={
              getRequestQuery.isLoading ||
              getRequestQuery.isError ||
              getRequestQuery.data === null
            }
            isLoading={createQuoteMutation.isLoading}
            onClick={createQuoteInitiator}
          >
            {' '}
            Submit Quotation{' '}
          </ButtonPrimary>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
