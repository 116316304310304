import {
  Avatar,
  Divider,
  HStack,
  Spacer,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getCustomer } from "../../../api/queries";
import { paths } from "../../../utils/constants";
import { formatPrice, getPinpointButtonClickEventProp } from "../../../utils/functions";
import Link from "../../buttons/link";
import Skeletons from "../../loaders/skeletons";
import { MomentsAgo } from "../../miscellaneous";
import OrderQuote from "./quote";

export default forwardRef(({ order }, ref) => {
  const {pathname} = useLocation();
  const total = useMemo(() => {
    return order.quotes.reduce((total, quote) => {
      return total + (quote.autoPart.quotedPrice * quote.request.autoPart.quantity);
    }, 0);
  }, [order]);

  const getCustomerQuery = useQuery(["customer", { id: order.order.customerId }], getCustomer, {
    enabled: Boolean(order && order.order.customerId)
  });

  return (
    <VStack
      ref={ref}
      width={"full"}
      spacing={4}
      p={5}
      borderRadius={"4px"}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow={"xs"}
    >
      {
        getCustomerQuery.isLoading && (
          <HStack
            width={"full"}
            spacing={4}
          >
            <Skeletons count={1} width={"44px"} minW={"44px"} height={"44px"} rounded={"full"} />
            <VStack
              spacing={1}
            >
              <Skeletons count={1} width={"full"} height={"20px"} borderRadius={"6px"} />
            </VStack>
          </HStack>
        )
      }

      {getCustomerQuery.data && (
        <HStack
          width={"full"}
        >
          <Avatar
            size={"sm"}
            src={getCustomerQuery.data.imageS3Name}
          />
          <VStack
            spacing={0}
            align={"start"}
          >
            <Text
              fontSize={"xs"}
              fontWeight={"semibold"}
            > { getCustomerQuery.data.firstName} { getCustomerQuery.data.familyName} </Text>
            <Text
              fontSize={"xs"}
              color={order.order.status === "OPEN" ? "gray" : order.status === "IN_PROGRESS" ? "green" : "gray"}
            > {order.order.status === "OPEN" ? "payment is pending" : order.status === "IN_PROGRESS" ? "delivering" : "closed"} </Text>
          </VStack>
        </HStack>
      )}

      <Divider />
      <VStack
        spacing={1}
      >
        {
          order.quotes && order.quotes.map(quote => {
            return (
              <OrderQuote
                key={quote.quote.id}
                quote={quote}
              />
            )
          })
        }
      </VStack>
      <Divider />
      <Text
        width={"full"}
        fontSize={"sm"}
        fontWeight={"bold"}
      > Total: {formatPrice(total)} </Text>
      <HStack
        width={"full"}
      >
        <MomentsAgo date={order.order.created} />
        <Spacer />
        <Link
          href={paths.order + "/" + order.order.id}
          fontSize={'sm'}
          {...getPinpointButtonClickEventProp({
            command: 'link to go to more details of an order',
            currentPage: pathname,
          })}
        > more details </Link>
      </HStack>
    </VStack>
  )
});