import { Button, HStack, Input, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { AiOutlineSend } from "react-icons/ai";
import { useMutation } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { sendMessage } from "../../../../api/mutations";
import { cognitoPartnerAtom, lastMessageAtom, postRenderMessagesAtom, selectedConversationAtom } from "../../../../recoil/atoms";
import { unStringfy } from "../../../../utils/functions";



export default function SendMessageControl() {

  const user = useRecoilValue(cognitoPartnerAtom);
  const conversation = useRecoilValue(selectedConversationAtom);
  const setPostRenderMessages = useSetRecoilState(postRenderMessagesAtom);
  const lastMessage = useRecoilValue(lastMessageAtom);

  const toast = useToast();
  const { handleSubmit, register, reset, formState } = useForm();

  const sendMessageMutation = useMutation(sendMessage);

  async function sendOffer({ offeredAmount }) {
    if (offeredAmount == 0) return;
    try {
      const quote = unStringfy(lastMessage.message.message).quote;
      if(!quote) throw new Error("malformed quote");
      const message = {
        type: "offer",
        offeredAmount,
        quote,
      };
      const data = await sendMessageMutation.mutateAsync({
        user, conversation,
        body: JSON.stringify(message)
      });
      reset()
    } catch (error) {
      console.log(error)
      toast({
        status: "error",
        title: "Something went wrong",
        description: "Please try again",
        duration: 2000,
        position: "bottom"
      });
    }
  }

  return (
    <HStack
      as={"form"}
      spacing={4}
      p={3}
      width={"full"}
      borderTopWidth={"1px"}
      borderTopColor={useColorModeValue("gray.50", "gray.600")}
      align={"center"}
      justify={"center"}
      onSubmit={handleSubmit(sendOffer)}
    >
      <Text
        fontWeight={"bold"}
        fontSize={"lg"}
      > K </Text>
      <Input
        type={"number"}
        variant={"unstyled"}
        placeholder={"Enter your price proposal here"}
        fontSize={"sm"}
        width={"full"}
        color={useColorModeValue("gray.600", "gray.300")}
        _focus={{
          color: useColorModeValue("gray.900", "gray.100")
        }}
        {
          ...register("offeredAmount")
        }
      />
      <Button
        type={"submit"}
        minW={"32px"}
        width={"32px"}
        height={"32px"}
        p={0}
        rounded={"full"}
        colorScheme={"brand-blue"}
        color={"white"}
        isLoading={sendMessageMutation.isLoading}
        disabled={formState.isDirty ? false : true}
      > <AiOutlineSend size={"14px"} /> </Button>

    </HStack>

  )
}