import {
  Heading,
  HStack, SimpleGrid, Spacer, Text, useColorModeValue, VStack
} from "@chakra-ui/react";

import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { searchQuotes } from '../api/queries';
import FilterControl from '../components/buttons/filter';
import Quote from '../components/cards/Quote';
import ErrorAlert from '../components/error/alert';
import Main from '../components/layouts/Main';
import Skeletons from '../components/loaders/skeletons';
import { cognitoPartnerAtom, quoteUpdatedAtom } from '../recoil/atoms';
import { getPinpointButtonClickEventProp } from "../utils/functions";

export default function Quotes() {
  const user = useRecoilValue(cognitoPartnerAtom);
  const [quoteUpdated, setQuoteUpdated] = useRecoilState(quoteUpdatedAtom);

  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const limit = 100;
  const noOrderBG = useColorModeValue('gray.100', 'gray.700');
  const noOrdersColor = useColorModeValue('gray.600', 'gray.300');
  const { pathname } = useLocation();

  const { isLoading, isError, data, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['quotes', { status, dateRange, limit, user, quoteUpdated }],
      searchQuotes,
      {
        cacheTime: Infinity,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        getNextPageParam: previousPage => previousPage.nextToken,
        onSuccess: () => {
          setQuoteUpdated(false)
        }
      }
    );

  const { ref, isInView } = useInView();

  //console.log(data);

  useEffect(() => {
    hasNextPage && isInView && fetchNextPage();
  }, [hasNextPage, isInView, fetchNextPage]);

  return (
    <Main>
      <HStack width={'full'}>
        <Heading
          fontSize={'xl'}
          fontWeight={'bold'}
          color={useColorModeValue('gray.900', 'gray.50')}
        >
          {' '}
          My Quotations{' '}
        </Heading>
        <Spacer />
        <FilterControl
          apply={filters => {
            setStatus(filters.status);
            setDateRange(filters.dateRange);
          }}
          {...getPinpointButtonClickEventProp({
            command: 'open filters dropdown',
            currentPage: pathname,
          })}
        />
      </HStack>

      <SimpleGrid
        columns={{
          base: 1,
          md: 3,
          lg: 4,
          xl: 5,
        }}
        p={'1px'}
        spacing={4}
        width={'full'}
      >
        {isLoading && (
          <Skeletons count={limit} height={'250px'} borderRadius={'4px'} />
        )}
        {data &&
          data.pages &&
          data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {page &&
                  page.quotes &&
                  page.quotes.map((quote, quoteIndex) => {
                    return (
                      <Quote
                        quote={quote}
                        key={quote.quote.id}
                        ref={
                          pageIndex === data.pages.length - 1 &&
                          quoteIndex === page.quotes.length - 1
                            ? ref
                            : null
                        }
                      />
                    );
                  })}
              </Fragment>
            );
          })}
      </SimpleGrid>
      {!isLoading &&
        data &&
        (!data.pages.length || data.pages[0].quotes.length < 1) && (
          <VStack
            spacing={2}
            p={4}
            py={12}
            borderRadius={'8px'}
            bg={noOrderBG}
            color={noOrdersColor}
            width={'full'}
          >
            <Text>
              {' '}
              {status === 'all'
                ? 'You have not submitted any quotes yet.'
                : 'No quotes were found.'}{' '}
            </Text>
          </VStack>
        )}

      {isLoading && (
        <Skeletons height={'132px'} borderRadius={'4px'} count={10} />
      )}

      {isError && <ErrorAlert title="Couldn't get your Quotes" description="Refresh your page or Contact support" again={refetch} />}
    </Main>
  );
}

/*
// state that quotes will be written to
  const [quotes, setQuotes] = useRecoilState(quotesAtom);
  // token triggers fetchQuotes function in the useSearchQuotes hook
  // nextToken holds the next value of token
  // load more quotes by calling setToken(nextToken) in response to a load more click or lazy load observer
  const nextToken = useRecoilValue(quotesNextTokenAtom);
  const [token, setToken] = useState(null);
  const [filter, setFilter] = useRecoilState(quotesFilterAtom);
  const [dateRange, setDateRange] = useRecoilState(quotesDateRangeAtom);
  const didRender = useRef(false);
  const limit = 3;

  const {
    isLoading,
    error: { hasError },
    hasMore,
  } = useSearchQuotes({
    quotesAtom: quotesAtom,
    nextTokenAtom: quotesNextTokenAtom,
    hasMoreAtom: quotesHasMoreAtom,
    token, limit, dateRange, filter,
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });

  const toast = useToast();

  const changeDateRange = (range) => {
    let end = new Date(range.end);
    end.setHours(23)
    end.setMinutes(59);
    end.setSeconds(59);
    console.log(end);
    const newRange = {
      start: range.start ? new Date(range.start).toJSON() : range.start,
      end: range.end ? end.toJSON() : range.end
    };
    setDateRange(newRange);
  }

  const observer = useRef();
  const lastRequestElementRef = useCallback(node => {
    if(isLoading) return;
    if(observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore) {
        console.log("inter");
        setToken({ value: nextToken });
      }
    });
    if(node) observer.current.observe(node);
  },);

  useEffect(() => {
    if(didRender.current) {
      setToken({ value: null });
      setQuotes([]);
    } else didRender.current = true;
  }, [dateRange, filter]);

  useEffect(() => {
    if(quotes.length < 1 && !nextToken) setToken({ value: null });
  }, []);

  return (
    <Main>
      <Box
        width={"full"}
        maxW={"xl"}
      >
        <Heading
          my={4}
          textAlign={"center"}
        > Your Quotes </Heading>
        <HStack
          px={4}
          width={"full"}
        >
          <InputGroup
            bg={useColorModeValue("white", "gray.700")}
            _focus={{
              bg: useColorModeValue("white", "gray.700")
            }}
            _hover={{
              bg: useColorModeValue("white", "gray.700")
            }}
            borderRadius={"md"}
          >
            <InputLeftAddon
              bg={useColorModeValue("white", "gray.700")}
              _focus={{
                bg: useColorModeValue("white", "gray.700")
              }}
              _hover={{
                bg: useColorModeValue("white", "gray.700")
              }}
              border={"none"}
              borderRadius={"md"}
            >
              <Icon as={FilterIcon} />
            </InputLeftAddon>
            <Select
              value={filter}
              onChange={
                e => setFilter(e.target.value)
              }
              bg={useColorModeValue("white", "gray.700")}
              _focus={{
                bg: useColorModeValue("white", "gray.700")
              }}
              _hover={{
                bg: useColorModeValue("white", "gray.700")
              }}
              placeholder={"Filter"}
              variant={"filled"}
            >
              <Spacer />
              <option value={"all"}> All </option>
              <option value={"open"}> Open </option>
              <option value={"closed"}> Closed </option>
              <option value={"in_progress"}> In Progress </option>
            </Select>
          </InputGroup>
          
          <DateRange
            submit={
              changeDateRange
            }
            triggerProps={{
              bg: useColorModeValue("white", "gray.700"),
              _hover: {
                bg: useColorModeValue("white", "gray.700")
              },
              _focus: {
                bg: useColorModeValue("white", "gray.700")
              }
            }}
          />
          </HStack>
          
          <VStack
            className="no-scroll"
            maxW={"xl"}
            width={"full"}
            spacing={5}
            py={{
              base: 4
            }}
          >
            {
              quotes.map((quote,index) => (
                  <Quote
                    ref={index === quotes.legnth - 1 ? lastRequestElementRef : undefined}
                    key={quote.quote.id}
                    { ...quote }
                  />
              ))
            }
            {
              isLoading && <CardSkeletonLoader loaded={!isLoading} children={""} />
            }

            {
              hasError && <p> Something went wrong </p>
            }

            {
              !isLoading && !hasError && quotes.length < 1 && <p> Zero quotes found, broaden your date range </p>
            }

            
          {
          // <ScrollFade />
          }
          </VStack>
        </Box>
    </Main>

  )




*/

/*


<Center p={5}>
      
      </Center>

*/
