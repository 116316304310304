/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      id
      created
      modified
      entityType
      message
      conversationId
      senderId
      senderType
      imageS3Name
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($conversationId: ID!) {
    onCreateOrder(conversationId: $conversationId) {
      ... on OrderResponse {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const onCustomerCreateRequest = /* GraphQL */ `
  subscription OnCustomerCreateRequest {
    onCustomerCreateRequest {
      request {
        id
        created
        modified
        entityType
        make
        model
        year
        fuel
        gearbox
        variant
        VIN
        engineNumber
        status
        contactDetails
        otherDetails
        creatorId
        creatorType
        quoteIds
        vehicleId
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quantity
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      vehicle {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
    }
  }
`;
