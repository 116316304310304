import { HStack, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Main from '../../components/layouts/Main';
import Conversation from './conversation';
import Conversations from './conversations';

export default function Negotiations() {
  const [searchParams] = useSearchParams();

  const conversationIsSelected = useMemo(
    () => searchParams.has('conversation'),
    [searchParams]
  );

  useEffect(() => {
    searchParams.get('conversation');
  }, [searchParams]);

  return (
    <Main>
      <HStack width={'full'}>
        <Text
          fontSize={'xl'}
          fontWeight={'bold'}
          color={useColorModeValue('gray.900', 'gray.50')}
        >
          {' '}
          Negotiations{' '}
        </Text>
        <Text
          fontSize={'xs'}
          fontWeight={'black'}
          color={useColorModeValue('gray.600', 'gray.300')}
          textDecor={'underline'}
        >
          {' '}
        </Text>
      </HStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        width={'full'}
        spacing={{ base: conversationIsSelected ? 0 : 6, md: 10 }}
        p={0}
      >
        <Conversations />
        <Conversation />
      </Stack>
    </Main>
  );
}
