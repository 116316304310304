/* eslint-disable react-hooks/exhaustive-deps */
import { VStack } from '@chakra-ui/react';
import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getNegotiations } from '../../../api/queries';
import ErrorAlert from '../../../components/error/alert';
import Skeletons from '../../../components/loaders/skeletons';
import { cognitoPartnerAtom } from '../../../recoil/atoms';
import Negotiation from './negotiation';

export default function Conversations() {
  const user = useRecoilValue(cognitoPartnerAtom);
  const [searchParams] = useSearchParams();

  const limit = 20;

  const { isLoading, isError, data, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery(['negotiations', { user, limit }], getNegotiations, {
      getNextPageParam: previousPage => previousPage.nextToken,
    });

  const { ref, inView } = useInView();

  useEffect(() => {
    inView && hasNextPage && fetchNextPage();
  }, [inView]);

  const conversationIsSelected = searchParams.has('conversation');

  return (
    <VStack
      display={{ base: conversationIsSelected ? 'none' : 'flex', md: 'flex' }}
      spacing={4}
      width={{ base: 'full', md: '35%' }}
      minH={'500px'}
    >
      {data &&
        data.pages &&
        data.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page &&
              page.conversations &&
              page.conversations.map(({ conversation }, conversationIndex) => {
                return (
                  <Negotiation
                    key={conversation.id}
                    conversation={conversation}
                    ref={
                      pageIndex === data.pages.length - 1 &&
                      conversationIndex === page.conversations.length - 1
                        ? ref
                        : null
                    }
                  />
                );
              })}
          </Fragment>
        ))}

      {isLoading && (
        <Skeletons count={limit} height={'80px'} borderRadius={'lg'} />
      )}
      {isError && <ErrorAlert again={refetch} />}
    </VStack>
  );
}
