import { CloseIcon, Icon } from "@chakra-ui/icons";
import {
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    ModalBody,
    Box,
    useBreakpointValue,
    Tab,
    TabList,
    TabPanel,
    Tabs,
    TabPanels,
    Text,
    Button,
    HStack,
    ModalFooter
} from "@chakra-ui/react";
import { useState } from "react";
import CloseButton from "../../buttons/close-button";
import PhotoUpload from "../../photos/upload";
import PhotoList from "../../photos/list";




export default function PhotoSelect({
  isOpen,
  onOpen,
  onClose,
  heading = "Images",
  max = 10,
  onSelect = () => {}
}) {

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={
          onClose
        }
        size={useBreakpointValue({
          base: "full",
          md: "2xl",
          lg: "3xl"
        })}
        scrollBehavior={"inside"}
        isCentered
        blockScrollOnMount
        closeOnEsc
        motionPreset={
          useBreakpointValue({
            base: "slideInBottom",
            md:  "scale"
          })
        }
      >
        <ModalOverlay
          bg='blackAlpha.500'
          backdropFilter='auto'
         
          backdropBlur='2px'
        />
        <ModalContent
          height={{
            base: "90vh",
            md: "auto"
          }}
          position={{
            base: "10vh"
          }}
          p={{
            base: 0,
            md: 4
          }}
        >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
        >
          <Text
            width={"full"}
          > { heading } </Text>
          <Button as={ModalCloseButton}>
            <Icon as={CloseIcon} />
          </Button>
          
        </ModalHeader>      
        
        <ModalBody>

        <Tabs
          isLazy
          lazyBehavior="keepMounted"
          variant={"soft-rounded"}
          onChange={
            index => setTabIndex(index)
          }
        >
          <TabList
            spacing={2}
            as={HStack}
          >
            <Tab
              as={Button}
              size={"xs"}
              fontSize={"xs"}
              _focus={{
                outline: "none"
              }}
            > Upload Image </Tab>
            <Tab
              as={Button}
              size={"xs"}
              fontSize={"xs"}
               _focus={{
                outline: "none"
              }}
            > Previous Uploads </Tab>
          </TabList>
          <TabPanels maxH={"100%"}>
            <TabPanel maxH={"100%"}>
              <PhotoUpload
                callback={
                  (data) => {
                    onSelect(data)
                    onClose();
                  }
                }
              max={1}
              />
            </TabPanel>

            <TabPanel maxH={"100%"}>
              <PhotoList
                onSelect={
                  (data) => {
                    onSelect(data)
                    onClose();
                  }
                }
                max={max}
              />
            </TabPanel>
            
            </TabPanels>
          </Tabs>

        </ModalBody>
        </ModalContent>
    </Modal>
  </Box>
  )
}