import { Flex, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import NavigationContent from "./content";



export default function Navigation() {
  return (
    <Flex
      width={{
        base: "100vw",
        md: "auto"
      }}
      position={{
        base: "fixed",
        md: "relative"
      }}
      bottom={0}
      right={0}
      bg={{
        base: useColorModeValue("white","gray.900"),
        md: "none"
      }}
      px={{
        base: 4,
        md: 0
      }}
      py={{
        base: 3,
        md: 0
      }}
      boxShadow={useBreakpointValue({ value: "xs", md: "" })}
    >
      <NavigationContent />
    </Flex>
  )
}