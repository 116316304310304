import { API } from "aws-amplify";
import { useEffect, useState } from "react";

import { useToast } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { onCustomerCreateRequest } from "../graphql/subscriptions";
import { isAuthenticatedAtom } from "../recoil/atoms";
import { authModes } from "../utils/constants";

export default function useRequestsSubscription() {

    const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
    const [request, setRequest] = useState(null);

    const toast = useToast();

    const subscribe = () => {
      const subscription = API.graphql({
        query: onCustomerCreateRequest,
        variables: {
  
        },
        authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
      }).subscribe({
        next: ({ provider, value }) => {
          let request = value.data.onCustomerCreateRequest;
          request.isNew = true;
          setRequest(request);
        },
          error: error => {
            console.log(error);
          }
        });
      return () => {
          API.cancel(subscription);
      }
    }
    useEffect(subscribe, []);
    return { request };
}
