import {
  Avatar,
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getCustomer } from '../../api/queries';
import Skeletons from '../../components/loaders/skeletons';
import { formatPrice } from '../../utils/functions';
import OrderDetailsQuote from './quote';

export default function OrderDetailsContent(props) {
  const { order, quotes } = props.order;
  const getCustomerQuery = useQuery(
    ['customer', { id: order.customerId }],
    getCustomer,
    {
      enabled: Boolean(order && order.customerId),
    }
  );

  const totalReceivable = useMemo(() => {
    return quotes.reduce((total, quote) => {
      return (
        total + quote.autoPart.quotedPrice * quote.request.autoPart.quantity
      );
    }, 0);
  }, [quotes]);

  return (
    <>
      <HStack
        spacing={4}
        width={'full'}
        overflowX={'scroll'}
        className={'no-scroll'}
        p={'1px'}
        align={'start'}
        justify={'start'}
      >
        {quotes.map(quote => {
          return <OrderDetailsQuote key={quote.quote.id} quote={quote} />;
        })}
      </HStack>
      <Stack
        direction={{
          base: 'column',
          sm: 'row',
        }}
        width={'full'}
        spacing={{
          base: 4,
          md: 20,
        }}
      >
        <VStack
          width={'full'}
          px={3}
          py={6}
          bg={useColorModeValue('white', 'gray.700')}
        >
          {getCustomerQuery.isLoading && (
            <HStack width={'full'} height={'50px'}>
              <Skeletons
                count={1}
                width={'44px'}
                minW={'44px'}
                height={'44px'}
                rounded={'full'}
              />
              <VStack width={'full'} height={'full'}>
                <Skeletons count={2} width={'full'} height={'20px'} />
              </VStack>
            </HStack>
          )}

          {getCustomerQuery.data && (
            <HStack spacing={4} width={'full'}>
              <Avatar size={'md'} src={getCustomerQuery.data.imageS3Name} />
              <VStack spacing={'0'} align={0}>
                <Text fontSize={'sm'} fontWeight={'medium'}>
                  {' '}
                  {getCustomerQuery.data.firstName}{' '}
                  {getCustomerQuery.data.lastName}{' '}
                </Text>
                <Text
                  spacing={4}
                  fontSize={'10'}
                  fontWeight={'light'}
                  color={'gray.500'}
                >
                  joined {moment(getCustomerQuery.data.created).fromNow()}
                </Text>
              </VStack>
            </HStack>
          )}

          <HStack width={'full'}>
            <Text fontSize={'sm'}> Status: </Text>
            <Spacer />
            <Text fontWeight={'bold'} fontSize={'sm'}>
              {' '}
              {order.status === 'OPEN' ? 'awaiting payment' : order.status}{' '}
            </Text>
          </HStack>
          {order.status === 'OPEN' && (
            <>
              <Text
                color={'gray.400'}
                fontSize={'sm'}
                fontWeight={'normal'}
                textAlign={'center'}
                p={5}
              >
                {' '}
                Payment for this order is still pending{' '}
              </Text>
            </>
          )}
        </VStack>

        <VStack
          width={'full'}
          p={5}
          spacing={4}
          align={'start'}
          bg={useColorModeValue('white', 'gray.700')}
        >
          <Text fontSize={'lg'} fontWeight={'bold'}>
            {' '}
            Order Summary{' '}
          </Text>

          <HStack width={'full'}>
            <Text> Items: </Text>
            <Spacer />
            <Text> {quotes.length} </Text>
          </HStack>

          <HStack width={'full'}>
            <Text fontWeight={'medium'}> Cash Total: </Text>
            <Spacer />
            <Text fontWeight={'black'}> {formatPrice(totalReceivable)} </Text>
          </HStack>
        </VStack>
      </Stack>
    </>
  );
}
