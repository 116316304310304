import { HStack, Image, Spacer, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Link from "../../components/buttons/link";
import Carousel from "../../components/carousel";
import ImageContainer from "../../components/carousel/image-container";
import { quoteAtom } from "../../recoil/atoms";
import { formatPrice } from "../../utils/functions";


export default function OrderDetailsQuote(props) {

  const { request, quote, autoPart, photos } = props.quote;

  const setQuote = useSetRecoilState(quoteAtom);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <VStack
      align={"start"}
      width={"200px"}
      minW={"200px"}
      spacing={2}
      p={3}
      borderRadius={"8px"}
      bg={useColorModeValue("white", "gray.700")}
      boxShadow={"sm"}
    >
      <Carousel
        width={"full"}
        height={"140px"}
        borderRadius={"4px"}
      >
        {photos.map(photo => {
          return (
            <ImageContainer key={photo.imageS3Name}>
              <Image
                src={photo.imageS3Name}
                objectFit={"cover"}
                width={"full"}
                height={"full"}
              />
            </ImageContainer>
          )
        })}
      </Carousel>

      <VStack
        spacing={1}
        align={"start"}
      >
        <Text
          fontSize={"xs"}
          fontWeight={"medium"}
        >
          {request.autoPart.quantity}
          <Text
            as={"span"}
            fontWeight={"light"}
          > &times; </Text>
          {autoPart.autoPart.name}
        </Text>
        <Text
          fontSize={"10px"}
        > {request.request.make} - {request.request.model} - {request.request.variant} </Text>
      </VStack>

      <HStack
        width={"full"}
      >
         <Text
          fontSize={"xs"}
          fontWeight={"extrabold"}
        > {formatPrice(request.autoPart.quantity * autoPart.quotedPrice)} </Text>
        <Spacer />
        <Link
          href={location.pathname+"#quote-"+quote.id}
          onClick={
            e => {
              e.preventDefault();
              setQuote(props.quote);
              navigate(location.pathname+"#quote-"+quote.id);
            }
          }
          fontSize={"xs"}
        > more details </Link>
      </HStack>


    </VStack>
  )
}