import {
  Stack,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom"
import Link from "../buttons/link";


export default function NavigationItem({ route }) {

  const location = useLocation();

  if(route.onclick) {
    route.rest = { 
      onClick: route.onclick
    }
  }

  return (
    <Link
      href={route.path}
      as={Stack}
      alignItems={"center"}
      direction={{
        base: "column",
        md: "row"
      }}
      spacing={{
        base: 2,
        md: 3
      }}
      px={{
        base: 0,
        md: 4
      }}
      py={{
        base: 0,
        md: 2
      }}
      _hover={{
        bg: {
          base: "none",
          md: "gray.900"
        },
        color: "brand.500",
        borderRadius: "4px",
        fontWeight: "bold"
      }}
      color={{
        base: useColorModeValue("gray.900","gray.50"),
       
      }}
      textDecoration={"none"}
      { ...route.rest }
    >
      { route.more }
      { route.icon }
      <Text
        fontSize={{
          base: "11px",
          md: "14px"
        }}
        fontWeight={"medium"}
      >
        { route.text }
      </Text>

    </Link>
  )
}