import {
  Avatar,
  HStack,
  ScaleFade,
  Text,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";
import Link from "../../../../../components/buttons/link";
import { selectedConversationAtom } from "../../../../../recoil/atoms";
import { paths } from "../../../../../utils/constants";
import { formatPrice, getCustomerFromConversationMembers } from "../../../../../utils/functions";

export default forwardRef(function ({ message, ...rest }, ref) {

  const conversation = useRecoilValue(selectedConversationAtom);
  const customer = getCustomerFromConversationMembers(conversation.conversation.members);
  const { data } = message;
  const quantity = data && data.quote && data.quote.request && data.quote.request.autoPart.quantity;

  return (
    <VStack
      width={"full"}
      align={"center"}
      justify={"center"}
      spacing={2}
      as={ScaleFade}
      in={true}
      px={6}
      py={2}
      maxW={"500px"}
      alignSelf={"center"}
      position={'sticky'}
      top={'0'}
      left={'0'}
      bg={'gray.200'}
      rounded={'md'}
      zIndex={'1'}
      ref={ref}
      {...rest}
    >
      <Text
        color={useColorModeValue("gray.800", "gray.300")}
        fontSize={{base:'xs', sm: 'xs', md:'xs'}}
      >
        The current negotiation is based on this spare part {" "}
        <Link
            href={paths.quote}
            as={"span"}
            color={"inherit"}
            fontWeight={"semibold"}
          > {data && data.quote && data.quote.autoPart && data.quote.autoPart.autoPart.name} {quantity > 1 && <>&times;</>} {quantity > 1 && quantity} </Link> .
      </Text>
    </VStack >
  )
});