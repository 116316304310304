import { Heading, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getOrder } from "../../api/queries";
import Main from "../../components/layouts/Main";
import { orderAtom } from "../../recoil/atoms";
import OrderDetailsContent from "./content";
import Loading from "./loading";


export default function Order() {
  
  const [order, setOrder] = useRecoilState(orderAtom);
  const [orderId, setOrderId] = useState(null);

  /* primary hooks and refs */
  const params = useParams();

  const getOrderQuery = useQuery(["order", { orderId }], getOrder , {
    enabled: !!orderId,
    refetchInterval: 0,
    cacheTime: 0,
    onSuccess: (data) => setOrder(data)
  });
  // check for order and trigger getOrderQuery if necesary
  useEffect(() => {
    if(order && order.order && order.order.id === params.id) return;
    setOrderId(params.id);
  }, [order, setOrderId,params.id]);

  return (
    <Main>
      <HStack
        spacing={3}
      >
        <Heading
          fontSize={"xl"}
          fontWeight={"bold"}
          color={useColorModeValue("gray.900","gray.50")}
        > Order Details </Heading>
        <Spacer />
      </HStack>
      { getOrderQuery.isLoading ? <Loading /> : order && <OrderDetailsContent order={order} /> }
    </Main>
  )
}