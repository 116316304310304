import { Text, VStack } from "@chakra-ui/react";
import { Component } from "react"

export default class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if(this.state.hasError) {
      return this.props.fallback || (
        <VStack
          align={"center"}
          spacing={4}
          width={"100%"}
          height={"100%"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={"medium"}
          > Something went wrong </Text>
        </VStack>
      )
    } else return this.props.children;
  }

}