import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import { useRecoilState } from 'recoil';
import {
  quotedAutoPartIsNew,
  quotedPriceAtom,
  quoteExpiresAtom,
  quoteExpiryDateAtom,
  quotePhotosAtom,
} from '../../../recoil/atoms';
import { formatPrice, textFromCamelCase } from '../../../utils/functions';
import Toggle from '../../buttons/toggle';
import Carousel from '../../carousel';
import ImageContainer from '../../carousel/image-container';
import PhotoSelect from '../../modals/select-and-upload-image';

export default function Content({ request }) {
  const [price, setPrice] = useRecoilState(quotedPriceAtom);
  const [isNew, setIsNew] = useRecoilState(quotedAutoPartIsNew);
  const [photos, setPhotos] = useRecoilState(quotePhotosAtom);
  const [expiryDate, setExpiryDate] = useRecoilState(quoteExpiryDateAtom);
  const [expires, setExpires] = useRecoilState(quoteExpiresAtom);

  const photosDisclosure = useDisclosure();

  const forDisplay = () => {
    const { OEM, description } = request.autoPart.autoPart;

    const { VIN, engineNumber, fuel, gearbox, make, model, year, variant } =
      request.vehicle.vehicle;

    const display = {
      make,
      model,
      year,
      description,
      partNumber: OEM,
      specification: variant,
      fuel,
      gearbox,
      chassis: VIN,
      engine: engineNumber,
    };

    return (
      <>
        {Object.entries(display).map(([key, value]) =>
          value ? (
            <Flex w={'full'} px={'2'}>
              <Text fontSize={'sm'} color={'gray.700'}>
                {textFromCamelCase(key)}
              </Text>
              <Spacer />
              <b> {value} </b>
            </Flex>
          ) : null
        )}
      </>
    );
  };
  
  const srcCondition = !(request.autoPart.autoPart.src === null || request.autoPart.autoPart.src === undefined)

  return (
    <>
      <HStack width={'full'} alignItems={'start'}>
      {
        srcCondition && (

        
      <Carousel
        width={{
          base: "72px",
          md: "full"
        }}
        minW={{
          base: "72px",
          md: "full"
        }}
        height={{
          base: "60px",
          md: "150px"
        }}
        minH={{
          base: "60px",
          height: "150px"
        }}
        borderRadius={"4px"}
      >
        {
          srcCondition && request && request.autoPart && request.autoPart.autoPart && request.autoPart.autoPart.src && request.autoPart.autoPart.src.map(autopart => {
            return (
              <ImageContainer
                key={autopart}
              >
                <Image
                  src={autopart}
                  width={"full"}
                  height={"full"}
                />
              </ImageContainer>
            )
          })
        }
      </Carousel>
)}
        <VStack
          width={'full'}
          justifyContent={'flex-start'}
          align={'start'}
          spacing={'1px'}
        >
          <Text
            fontSize={'md'}
            textTransform={'capitalize'}
            fontWeight={'bold'}
            color="green.500"
          >
            {request.autoPart.quantity}{' '}
            <Text as={'span'} fontWeight={'bold'} color="green.500">
              {' '}
              &times;{' '}
            </Text>{' '}
            {request.autoPart.autoPart.name}{' '}
          </Text>
        </VStack>
      </HStack>

      <VStack spacing={2} width={'full'}>
        {forDisplay()}
        <Divider />
        {request.autoPart.quantity > 0 && price && (
          <HStack width={'full'} justify={'space-between'}>
            <Box>
              <Text fontWeight={'medium'} fontSize={'md'}>
                {' '}
                Total:{' '}
              </Text>
            </Box>
            <Text fontSize={'lg'}>
              {request.autoPart.quantity} &times; {formatPrice(price)} ={' '}
              <b>{formatPrice(price * request.autoPart.quantity)}</b>
            </Text>
          </HStack>
        )}

        <Flex w={'full'} px={'2'}>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            {' '}
            Price Per Part:{' '}
          </Text>
          <Spacer />
          <NumberInput
            value={price}
            onChange={price => {
              // replace the zeros at the begining of the string so that parseInt does not return NaN and cause erros. return 0 just in case parseInt return 0;
              price = parseInt(price.replace(/^0/g, '')) || 0;
              setPrice(price);
            }}
            maxW={'120px'}
            size={'sm'}
            min={0}
          >
            <NumberInputField />
          </NumberInput>
        </Flex>

        <HStack width={'full'}>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            {' '}
            Condition:{' '}
          </Text>
          <Spacer />
          <HStack spacing={0} borderRadius={'8px'}>
            <Toggle
              value={isNew}
              toggleValue={true}
              setValue={setIsNew}
              label={'New'}
            />
            <Toggle
              value={isNew}
              toggleValue={false}
              setValue={setIsNew}
              label={'Used'}
            />
          </HStack>
        </HStack>
        <HStack width={'full'}>
          <Checkbox
            isChecked={expires}
            onChange={() => setExpires(value => !value)}
            size={'lg'}
          />
          <Text
            fontSize={'sm'}
            fontWeight={'medium'}
            opacity={expires ? 1 : 0.7}
          >
            {' '}
            Quote Expiry Date:{' '}
          </Text>
          <Spacer />
          <Input
            type={'date'}
            width={'140px'}
            size={'sm'}
            disabled={!expires}
            value={expiryDate}
            onChange={e => setExpiryDate(e.target.value)}
          />
        </HStack>

        <VStack width={'full'} spacing={2} alignItems={'start'}>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            {' '}
            Upload AutoPart Photos (optional):{' '}
          </Text>

          <SimpleGrid
            p={3}
            spacing={4}
            borderRadius={'8px'}
            borderWidth={'1px'}
            columns={3}
            width={'full'}
          >
            <VStack
              as={Button}
              spacing={3}
              width={'full'}
              height={'80px'}
              borderRadius={'8px'}
              alignItems={'center'}
              justifyContent={'center'}
              onClick={photosDisclosure.onOpen}
            >
              <IoMdAddCircle size={'20px'} />
              <Text fontSize={'10px'}> Add Images </Text>
            </VStack>

            {photos.map(photo => {
              return (
                <Box
                  key={photo.imageS3Name}
                  backgroundImage={photo.imageS3Name}
                  width={'full'}
                  height={'80px'}
                  borderRadius={'4px'}
                  p={1}
                >
                  <Flex width={'full'}>
                    <Spacer />
                    <Button
                      size={'xs'}
                      rounded={'full'}
                      fontSize={'lg'}
                      fontWeight={'black'}
                      colorScheme={'red'}
                      onClick={() => {
                        setPhotos(selectedPhotos =>
                          selectedPhotos.filter(
                            selectedPhoto => selectedPhoto.id !== photo.id
                          )
                        );
                      }}
                    >
                      {' '}
                      &times;{' '}
                    </Button>
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>

          <PhotoSelect
            onOpen={photosDisclosure.onOpen}
            isOpen={photosDisclosure.isOpen}
            onClose={photosDisclosure.onClose}
            onSelect={photos => {
              if (photos) {
                setPhotos(oldPhotos => {
                  return [...oldPhotos, ...photos];
                });
              }
            }}
            heading={'Add Photos To Quotaion'}
            max={Infinity}
          />
        </VStack>
      </VStack>
    </>
  );
}
