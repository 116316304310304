import { Flex, useColorModeValue, useToast } from '@chakra-ui/react';
// import Footer from './components/footer/Footer';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getPartner, getServiceFee } from './api/queries';
import CarouselModal from './components/carousel/modal';
import QuoteDrawer from './components/drawers/quote';
import RequestDrawer from './components/drawers/request';
import ErrorBoundary from './components/error/boundary';
import Header from './components/header/Header';
import {
  cognitoPartnerAtom,
  isAuthenticatedAtom,
  quoteDrawerIsOpenAtom,
  requestDrawerIsOpenAtom,
  triedQuoteAtom,
  serviceFeeAtom
} from './recoil/atoms';
import Routes from './Routes';
import { storeCustomAuthSession } from './utils/functions';
import PWAPrompt from 'react-ios-pwa-prompt';
import { paths } from './utils/constants';

onbeforeinstallprompt = event => {
  console.log(event);
};

function App() {
  const [partner, setCognitoPartner] = useRecoilState(cognitoPartnerAtom);
  const [isAuthenticated, setAuthentication] = useRecoilState(isAuthenticatedAtom);
  const [triedQuote,setTriedQuote] = useRecoilState(triedQuoteAtom);
  const setServiceFee = useSetRecoilState(serviceFeeAtom)
  const [go, setGo] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const location = useLocation();
  


  // drawer states
  const [requestDrawerIsOpen, setRequestDrawerIsOpen] = useRecoilState(
    requestDrawerIsOpenAtom
  );
  const [quoteDrawerIsOpen, setQuoteDrawerIsOpen] = useRecoilState(
    quoteDrawerIsOpenAtom
  );


  //ANCHOR - get service fee
  const getServiceFeeQuery = useQuery(
    ['servicefee', { isAuthenticated: isAuthenticated }],
    getServiceFee,
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if(isAuthenticated && getServiceFeeQuery.data){
      setServiceFee(getServiceFeeQuery.data)
    }
  }, [isAuthenticated, getServiceFeeQuery.data])

 
  useEffect(() => {
    const starter = location.hash.split('-')[0];
    
    if (starter === '#request') {
      if(Boolean(partner)){
        setRequestDrawerIsOpen(true);
      } else{
        setTriedQuote(`/${location.hash}`)
        setRequestDrawerIsOpen(false);
        navigate(paths.login)
        toast({
          status: 'info',
          title: 'Login ',
          description: 'You need to login for you to submit a quote.',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        })
      }
    } else if (starter !== '#request' && requestDrawerIsOpen) {
      setRequestDrawerIsOpen(false);
    } else if (starter === '#quote') {
      setQuoteDrawerIsOpen(true);
    } else if (starter !== '#quote' && quoteDrawerIsOpen) {
      setQuoteDrawerIsOpen(false);
    }
  }, [location.hash]);

  // const toast = useToast();

  const onLoadMutation = useMutation(async () => {
    await Auth.currentSession();
    return Auth.currentAuthenticatedUser();
  });

  
  useEffect(() => {
    if(isAuthenticated && Boolean(triedQuote)){
      navigate(triedQuote)
    }
  }, [isAuthenticated])
  

  useEffect(() => onLoadMutation.mutate({}), []);
  useEffect(() => {
    if (onLoadMutation.isSuccess) {
      const partner = onLoadMutation.data;
      if (Boolean(partner)) {
        storeCustomAuthSession(partner);
        setGo(partner.attributes);
      }
    } else if (onLoadMutation.isError) {
      console.log('Something went wrong');
    }
  }, [onLoadMutation.isSuccess]);

  const getPartnerDetailsQuery = useQuery(
    ['partner', { partner }],
    getPartner,
    {
      enabled: !!go,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  );
  

  useEffect(() => {
    if (getPartnerDetailsQuery.isSuccess) {
      const partner = getPartnerDetailsQuery.data;

      if (partner) {
        setCognitoPartner(partner);
        setAuthentication(true);
      }
    } else if (getPartnerDetailsQuery.isError) {
      console.log('Something went wrong');
    }
  }, [getPartnerDetailsQuery.isSuccess, getPartnerDetailsQuery.isError]);

  return (
    <ErrorBoundary>
      <Flex
        direction={'column'}
        width={'100vw'}
        maxW={'100vw'}
        minH={'100vh'}
        bg={useColorModeValue('light-background', 'dark-background')}
      >
        {isAuthenticated && <Header />}
        <Routes />
        <RequestDrawer />
        <QuoteDrawer />
        <CarouselModal />
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
        />
      </Flex>
    </ErrorBoundary>
  );
}

export default App;
