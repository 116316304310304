import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Image,
  Input,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Text,
  Flex,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  quotedAutoPartIsNew,
  quotedPriceAtom,
  quoteExpiresAtom,
  quoteExpiryDateAtom,
  quotePhotosAtom,
} from '../../../recoil/atoms';
import { formatPrice, getPinpointButtonClickEventProp, textFromCamelCase } from '../../../utils/functions';
import Toggle from '../../buttons/toggle';
import PhotoSelect from '../../modals/select-and-upload-image';

export default function Content({ quote }) {
  const [price, setPrice] = useRecoilState(quotedPriceAtom);
  const [isNew, setIsNew] = useRecoilState(quotedAutoPartIsNew);
  const [photos, setPhotos] = useRecoilState(quotePhotosAtom);
  const [expiryDate, setExpiryDate] = useRecoilState(quoteExpiryDateAtom);
  const [expires, setExpires] = useRecoilState(quoteExpiresAtom);
  const location = useLocation();

  useEffect(() => {
    setPrice(parseFloat(quote.autoPart.quotedPrice).toFixed(2));
    setPhotos(quote.photos);
    setIsNew(quote.quote.isNew);
    const hasExpiryDate = !!quote.quote.expiryDate;
    setExpires(hasExpiryDate);
    if (hasExpiryDate)
      setExpiryDate(
        moment(quote.quote.expiryDate.valueOf()).format('YYYY-MM-DD')
      );
  }, []);

  const photosDisclosure = useDisclosure();

  const forDisplay = () => {
    const { OEM, description } = quote.request.autoPart.autoPart;

    const { VIN, engineNumber, fuel, gearbox, make, model, year, variant } =
      quote.request.vehicle.vehicle;

    const display = {
      make,
      model,
      year,
      description,
      partNumber: OEM,
      specification: variant,
      fuel,
      gearbox,
      chassis: VIN,
      engine: engineNumber,
    };

    return (
      <>
        {Object.entries(display).map(([key, value]) =>
          value ? (
            <Flex w={'full'} px={'2'}>
              <Text fontSize={'sm'} color={'gray.700'}>
                {textFromCamelCase(key)}
              </Text>
              <Spacer />
              <b> {value} </b>
            </Flex>
          ) : null
        )}
      </>
    );
  };

  return (
    <>
      <VStack
        width={'full'}
        justifyContent={'flex-start'}
        align={'start'}
        spacing={'1px'}
      >
        <Text
          fontWeight={'bold'}
          color="green.500"
          fontSize={'md'}
          textTransform={'capitalize'}
        >
          {quote.request.autoPart.quantity}{' '}
          <Text fontWeight={'bold'} color="green.500" as={'span'}>
            {' '}
            &times;{' '}
          </Text>{' '}
          {quote.autoPart.autoPart.name}{' '}
        </Text>
        {forDisplay()}
      </VStack>
      <Divider />

      <VStack spacing={4} width={'full'}>
        <HStack width={'full'}>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            {' '}
            Price Per Part:{' '}
          </Text>
          <Spacer />
          <NumberInput
            value={price}
            onChange={price => {
              // replace the zeros at the begining of the string so that parseInt does not return NaN and cause erros. return 0 just in case parseInt return 0;
              price = parseInt(price.replace(/^0/g, '')) || 0;
              setPrice(price);
            }}
            disabled={quote.quote.status === 'IN_PROGRESS'}
            maxW={'120px'}
            size={'sm'}
          >
            <NumberInputField
              _disabled={{
                opacity: 1,
                fontWeight: 'medium',
              }}
            />
          </NumberInput>
        </HStack>
      </VStack>

      {!!price && quote.request.autoPart.quantity > 1 && (
        <HStack width={'full'} justify={'space-between'}>
          <Box>
            <Text fontWeight={'medium'} fontSize={'md'}>
              {' '}
              Total:{' '}
            </Text>
          </Box>
          <Text fontSize={'lg'}>
            {quote.request.autoPart.quantity} &times; {formatPrice(price)} ={' '}
            <b>{formatPrice(price * quote.request.autoPart.quantity)}</b>
          </Text>
        </HStack>
      )}

      <Divider />

      <HStack width={'full'}>
        <Text fontSize={'sm'} fontWeight={'medium'}>
          {' '}
          Condition:{' '}
        </Text>
        <Spacer />
        <HStack spacing={0} borderRadius={'8px'}>
          <Toggle
            value={isNew}
            toggleValue={true}
            setValue={setIsNew}
            label={
              quote.quote.status === 'OPEN' ? 'New' : isNew ? 'New' : 'Used'
            }
          />
          {quote.quote.status === 'OPEN' && (
            <Toggle
              value={isNew}
              toggleValue={false}
              setValue={setIsNew}
              label={'Used'}
            />
          )}
        </HStack>
      </HStack>

      <VStack width={'full'} spacing={2} alignItems={'start'}>
        <Text fontSize={'sm'} fontWeight={'medium'}>
          {' '}
          Images Of The AutoPart:*{' '}
        </Text>

        <SimpleGrid
          p={3}
          spacing={4}
          borderRadius={'8px'}
          borderWidth={'1px'}
          columns={3}
          width={'full'}
        >
          <VStack
            as={Button}
            spacing={3}
            width={'full'}
            height={'80px'}
            borderRadius={'8px'}
            alignItems={'center'}
            justifyContent={'center'}
            {...getPinpointButtonClickEventProp({
              command: 'add image before submitting a quote',
              currentPage: location.pathname,
            })}
            onClick={photosDisclosure.onOpen}
          >
            <IoMdAddCircle size={'20px'} />
            <Text fontSize={'10px'}> Add Images </Text>
          </VStack>

          {photos && photos.length > 0 && photos.map(photo => {
            return (
              <Image
                src={photo.imageS3Name}
                key={photo.imageS3Name}
                width={'full'}
                height={'80px'}
                objectFit={'cover'}
                borderRadius={'4px'}
              />
            );
          })}
        </SimpleGrid>

        <HStack width={'full'}> 
          {quote.quote.status === 'OPEN' && (
            <Checkbox
              isChecked={expires}
              onChange={() => setExpires(value => !value)}
              size={'lg'}
            />
          )}

          <Text
            fontSize={'sm'}
            fontWeight={'medium'}
            opacity={expires ? 1 : 0.7}
          >
            {' '}
            Quotation Expiry Date:{' '}
          </Text>
          <Spacer />
          <Input
            type={'date'}
            width={'140px'}
            size={'sm'}
            disabled={!expires}
            value={expiryDate}
            onChange={e => setExpiryDate(e.target.value)}
          />
        </HStack>

        <PhotoSelect
          onOpen={photosDisclosure.onOpen}
          isOpen={photosDisclosure.isOpen}
          onClose={photosDisclosure.onClose}
          onSelect={photos => {
            if (photos) {
              setPhotos(oldPhotos => {
                return [...oldPhotos, ...photos];
              });
            }
          }}
          heading={'Add Photos To Quotaion'}
          max={Infinity}
        />
      </VStack>
    </>
  );
}
