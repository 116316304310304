
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
// import { ReactQueryDevtools } from "react-query/devtools"
import theme from './chakra-theme';
import * as ReactDOM from 'react-dom/client';
import Amplify, { Analytics } from 'aws-amplify';
import Config from './config/backend-config';
import './index.css';
import 'car-makes-icons/dist/style.css';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { QueryClientProvider } from 'react-query';
import queryClient from './api/query-client';
import * as Sentry from '@sentry/react'
import {BrowserTracing} from  '@sentry/tracing';
import { selectorPrefix } from './utils/constants';


Sentry.init({
  dsn: Config.sentry.DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.4,
});

try {
Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: Config.s3.BUCKET,
      region: Config.s3.REGION,
    },
  },
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: Config.pinpoint.APP_ID,
    // Amazon service region
    region: Config.cognito.REGION,
    mandatorySignIn: false,
  },
  ...Config.appsync,
});
} catch(error) {
  console.log(error);
}

try {
  //NOTE: adding the analytics
  Analytics.autoTrack('session', {
    enable: true,
    attributes: {
      session_start: new Date(Date.now()).toLocaleDateString(),
    },
    provider: 'AWSPinpoint',
  });
  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    attributes: {
      pageView_start: new Date(Date.now()).toLocaleDateString(),
    },
    type: 'SPA',
    provider: 'AWSPinpoint',
    getUrl: () => {
      return window.location.origin + window.location.pathname;
    },
  });

  Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    selectorPrefix,
    provider: 'AWSPinpoint',
  });
} catch (error) {
  console.log(error);
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      { /* <ReactQueryDevtools /> */ }
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  </RecoilRoot>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





/*
import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { initSentry } from './utils/errorLib';
import Config from './config';
import './index.css';
import 'car-makes-icons/dist/style.css';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import "./main.css"

initSentry();

try {

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: Config.cognito.REGION,
    userPoolId: Config.cognito.USER_POOL_ID,
    identityPoolId: Config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: Config.s3.BUCKET,
      region: Config.s3.REGION,
    },
  },
  ...Config.appsync,
});

} catch(error) {
  console.log(error)
}

ReactDOM.render(
  <StrictMode>
    <RecoilRoot>
      <Router>
        <ColorModeScript />
        <App />
      </Router>
    </RecoilRoot>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

*/