import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  VStack,
  HStack,
  Input
} from "@chakra-ui/react";
import { useState } from "react";
import { GoSettings } from "react-icons/go";
import { ButtonPrimary } from ".";
import { formatDate } from "../../utils/functions";

export default function FilterControl({ apply }) {

  const [dateRange, setDateRange] = useState({
    start: formatDate(),
    end: formatDate(),
  });

  const [status, setStatus] = useState("OPEN");

  function register() {}

  return (
    <Menu>
      <MenuButton
        as={ButtonPrimary}
        size={"sm"}
        borderRadius={"8px"}
      >
        <Icon
          h={"18px"}
          w={"18px"}
          as={GoSettings}
        />
      </MenuButton>
      <MenuList
        as={VStack}
        justifyContent={"flex-start"}
        alignItems={"start"}
        spacing={3}
        p={4}
        maxW={"300px"}
      >
        <Text
        > FIlter By Status: </Text>
        <Select
          size={"sm"}
          onChange={
            (e) => {
              apply({
                status: e.target.value
              })
            }
          }
        >
          <option value={"OPEN"}> Open </option>
          <option value={"CLOSED"}> Closed </option>
          <option value={"IN_PROGRESS"}> In Progress </option>
        </Select>
        <Text
        > Filter By Date Range: </Text>
        <HStack spacing={2} mb={5}>
          <Input
              type={"date"}
              size={"sm"}
              fontSize={"10px"}
              value={dateRange.start}
              onChange={
                e => {
                  let r = Object.assign({}, dateRange);
                  let start = e.target.value;
                  let startValue = new Date(start).valueOf();
                  let endValue = new Date(dateRange.end).valueOf();
                  // return if start date is greater than end date
                  if(startValue > endValue) return
                  setDateRange({
                      start: start,
                      end: dateRange.end
                  })
                }
               }
          />
          <Text
            fontSize={"xs"}
          > to </Text>
          <Input
              type={"date"}
              size={"sm"}
              fontSize={"10px"}
              value={dateRange.end}
              onChange= {
                e => {
                  let r = Object.assign({}, dateRange);
                  let end = e.target.value;
                  let endValue = new Date(end).valueOf();
                  let startValue = new Date(dateRange.start).valueOf();
                  // return if end date is lesser than start date
                  if(endValue < startValue) return;
                  
                  setDateRange({
                      start: dateRange.start,
                      end: end
                  })
                }
              }
            />
          </HStack>
          <ButtonPrimary
            size={"sm"}
            width={"full"}
            onClick={
              () => {
                apply({
                  status, dateRange
                })
              }
            }
          > apply filters </ButtonPrimary>
      </MenuList>
    </Menu>
  )
}