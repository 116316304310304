import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import {
  updatePartnerAttributes,
  updatePartner
} from '../../api/mutations';
import CountrySelect from '../../components/inputs/country-select';
import { getPinpointButtonClickEventProp } from '../../utils/functions';

export default function PersonalInfo({ user, setUser }) {
  const [country, setCountry] = useState(null);
  const toast = useToast();
  const {pathname} = useLocation();

  const { handleSubmit, register } = useForm();

  const updateUser = useMutation(updatePartner);
  const updateCustomAttributes = useMutation(updatePartnerAttributes);

  const updatePrimaryInfo = async formData => {

   const phoneNumberWithPrependedCountryCode = country.dial_code + formData.phoneNumber; 

   formData.phoneNumber = isValidPhoneNumber(phoneNumberWithPrependedCountryCode) ? phoneNumberWithPrependedCountryCode : isValidPhoneNumber(formData.phoneNumber) ? formData.phoneNumber : null;
    console.log(formData.phoneNumber)
    const Input = Object.assign({}, formData, {id: user.id})
    //ANCHOR - console.log("XXXX",Input)
    updateUser.mutateAsync(Input).then(res => console.log("new input",res)).catch(err => console.error("ERROR",err))
    
  };
  // update custom cognito user attributes after update user details.
  useEffect(() => {
    if (updateUser.isSuccess) {
      const formData = updateUser.data;
      let attributes = {};
      if (formData.email) attributes['email'] = formData.email;
      if (formData.name) attributes['custom:businessName'] = formData.name;
      console.log(attributes)
      updateCustomAttributes.mutateAsync(attributes).then(res => console.log(res)).catch(err => console.error(err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUser.isSuccess]);

  useEffect(() => {
    if (updatePartnerAttributes.isSuccess) {
      toast({
        status: 'info',
        title: 'Update was successful',
        duration: 3000,
        position: 'bottom',
      });
    } else if (updateCustomAttributes.isError) {
      toast({
        status: 'error',
        title: 'Update was unsuccesful.',
        duration: 3000,
        position: 'bottom',
      });
    }
  }, [
    updatePartnerAttributes.isSuccess,
    updatePartnerAttributes.isError,
  ]);

  return (
    <Box as={'form'} onSubmit={handleSubmit(updatePrimaryInfo)}>
      <VStack spacing={3} width={'full'} as={Flex} align={'flex-start'}>
        <Stack spacing={2} width={'full'}>
          <Text fontSize={"sm"} py={0} as={FormLabel} width={'fit-content'} htmlFor="contact">
            {' '}
            Contact:{' '}
          </Text>
          <InputGroup
          backgroundColor={useColorModeValue('gray.100', 'gray.700')}
          >
          <CountrySelect
            mini={true}
            onChangeCallback={
              country => setCountry(country)
            }
          />
            <Input
              w={"full"}
              maxLength={9}
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              id="contact"
              type={'tel'}
              defaultValue={user.mainContactPerson === "" ? null : user.mainContactPerson}
              placeholder={"(9/7/6) 0000 00"}
              {...register('mainContactPerson', {})}
            />
          </InputGroup>
        </Stack>
        <Stack spacing={2} width={'full'}>
          <Text fontSize={"sm"} py={0} as={FormLabel} width={'fit-content'} htmlFor="phoneNumber">
            {' '}
            Business Phone Number:{' '}
          </Text>
          <InputGroup
          backgroundColor={useColorModeValue('gray.100', 'gray.700')}
          >
          <CountrySelect
            mini={true}
            onChangeCallback={
              country => setCountry(country)
            }
          />
            <Input
            width={"full"}
            maxLength={9}
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              id="phoneNumber"
              type={'tel'}
              required={true}
              defaultValue={user.phoneNumber}
              disabled={user.phoneNumber === undefined}
              placeholder="Enter Phone Number"
              {...register('phoneNumber', {})}
            />
          </InputGroup>
        </Stack>
        <Stack spacing={2} width={'full'}>
          <Text fontSize={"sm"} py={0} as={FormLabel} width={'full'} htmlFor="firstName">
            {' '}
           Business Name:{' '}
          </Text>
          <HStack width={'full'}>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              id="businessName"
              type={'text'}
              defaultValue={user.name || ''}
              disabled={user.name === undefined}
              required={true}
              placeholder="Business Name"
              {...register('name', {
                //
              })}
            />
          </HStack>
        </Stack>
        <Stack spacing={3} width={'full'}>
          <Text fontSize={"sm"} as={FormLabel} width={'full'} htmlFor="email">
            {' '}
            Email Address:{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              type={'text'}
              id="email"
              defaultValue={user.email}
              disabled={user.email === undefined}
              placeholder="Enter Email Address"
              {...register('email', {
                //
              })}
            />
          </InputGroup>
        </Stack>
        <Stack spacing={3} width={'full'}>
          <Text fontSize={"sm"} as={FormLabel} width={'full'} htmlFor="bankAccount">
            {' '}
            Bank Account:{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              type={'text'}
              id="bankAccount"
              defaultValue={user.bankAccount}
              disabled={user.bankAccount === undefined}
              placeholder="Bank Account"
              {...register('bankAccount', {
                // required: true
              })}
            />
          </InputGroup>
        </Stack>
        <Stack spacing={3} width={'full'}>
          <Text fontSize={"sm"} as={FormLabel} width={'full'} htmlFor="address">
            {' '}
            Address:{' '}
          </Text>
          <InputGroup>
            <InputRightElement></InputRightElement>
            <Input
              backgroundColor={useColorModeValue('gray.100', 'gray.700')}
              _placeholder={{
                color: useColorModeValue('gray.400', 'gray.300'),
              }}
              type={'text'}
              id="address"
              defaultValue={user.address}
              disabled={user.address === undefined}
              placeholder="Address"
              {...register('address', {
                // required: true
              })}
            />
          </InputGroup>
        </Stack>
      </VStack>
      <Button
        type="submit"
        my={6}
        color={'white'}
        width={'full'}
        colorScheme={'green'}
        isLoading={updateUser.isLoading || updateCustomAttributes.isLoading}
        {...getPinpointButtonClickEventProp({
          command: 'save personal details after editing',
          currentPage: pathname,
        })}
      >
        Save Changes
      </Button>
    </Box>
  );
}
