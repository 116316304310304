import { forwardRef } from "react";
import {
  HStack, Image, ScaleFade, Text, useColorModeValue, useToast, VStack
} from "@chakra-ui/react";
import Carousel from "../carousel";
import ImageContainer from "../carousel/image-container";
import Link from "../buttons/link";
import { paths } from "../../utils/constants";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isAuthenticatedAtom, requestAtom } from "../../recoil/atoms";
import { useLocation, useNavigate } from "react-router-dom";


export default forwardRef(function({ request, ...rest }, ref) {

  const isAuthenticated = useRecoilValue(isAuthenticatedAtom)
  const setRequest = useSetRecoilState(requestAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  return (
    <HStack
      spacing={3}
      p={4}
      alignItems={"start"}
      width={"full"}
      borderRadius={"8px"}
      bg={useColorModeValue('white','gray.700')}
      className={"bxs-uh"}
      as={ScaleFade}
      in={true}
      ref={ref}
      { ...rest }
    >
      <Carousel
        width={"64px"}
        minW={"64px"}
        height={"52px"}
        borderRadius={"4px"}
      >
         {
          request && request.autoPart && request.autoPart.autoPart && request.autoPart.autoPart.src && request.autoPart.autoPart.src.length > 0 && request.autoPart.autoPart.src.map(imageURI=> {
            return (
              <ImageContainer
                key={imageURI}
              >
                <Image
                  src={imageURI}
                  width={"full"}
                  height={"full"}
                  borderRadius={"4px"}
                />
              </ImageContainer>
            )
          })
        }
      </Carousel>
      <VStack
        spacing={0}
        width={"full"}
        alignItems={"start"}
      >
        <Text
          fontWeight={"semibold"}
          color={useColorModeValue("gray.900","gray.100")}
          fontSize={"xs"}
          textTransform={"capitalize"}
        >
        { request.autoPart.quantity }{" "}
        <Text
          as={"span"}
          fontWeight={"light"}
        > &times; </Text>{" "}
        { request.autoPart.autoPart.name }
        </Text>
        <Text
          fontSize={"xs"}
          fontWeight={"normal"}
          color={useColorModeValue("gray.700","gray.200")}
        > { request.request.make } - { request.request.model }  </Text>
        <Link
          href={location.pathname+"#request-"+request.request.id}
          onClick={e => {
            e.preventDefault();
            if(!isAuthenticated) return toast({
              status: "info",
              title: "Sign Up",
              description: "You need to have an account for you to submit a quotation.",
              duration: 2000,
              isClosable: true,
              position: "bottom"
            });
            setRequest(request);
            navigate(location.pathname+"#request-"+request.request.id)
          }}
          fontSize={"xs"}
        > submit quotation </Link>
      </VStack>
     
    </HStack>
  )
})