import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  MdAccountBox,
  MdPayment,
} from 'react-icons/md';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updatePartner } from '../../api/mutations';
import Main from '../../components/layouts/Main';
import PhotoSelect from '../../components/modals/select-and-upload-image';
import { cognitoPartnerAtom} from '../../recoil/atoms';
import { getPinpointButtonClickEventProp } from '../../utils/functions';
import PaymentsInfo from './payments';
import PersonalInfo from './personal';

export default function Settings() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {pathname} = useLocation();
  const toast = useToast();

  const [user, setUser] = useRecoilState(cognitoPartnerAtom);

  const updateCognitoUser = useMutation(updatePartner);

  const updateProfilePicture = async photo => {
    photo = Array.isArray(photo) ? photo[0] : photo;
    if (photo.error) {
      return toast({
        status: 'error',
        title: 'Photo upload was unsuccessful',
        duration: 4000,
        isClosable: true,
      });
    }
    if (photo) {
      updateCognitoUser.mutate({
        imageS3Name: photo.imageS3Name
      });
    }
    
  };

  useEffect(() => {
    if (updateCognitoUser.isSuccess) {
      setUser(updateCognitoUser.data);
    } else if (updateCognitoUser.isError) {
      return toast({
        status: 'error',
        title: 'Update was unsuccessful',
        description: 'Could not update your user profile',
        duration: 3000,
        position: 'bottom',
      });
    }
  }, [updateCognitoUser.isSuccess, updateCognitoUser.isError]);

  return (
    <Main>
      <Box maxW={'3xl'} width={'full'}>
        <Avatar
          size={'2xl'}
          borderColor={'orange.100'}
          rounded={'full'}
          src={user.imageS3Name}
        >
          <AvatarBadge
            as={Button}
            onClick={onOpen}
            {...getPinpointButtonClickEventProp({
              command: 'update personal profile picture',
              currentPage: pathname,
            })}
            py={1}
            px={3}
            height={'auto'}
            fontSize={'xs'}
            bg={'green.100'}
            color={'green.400'}
            _hover={{
              backgroundColor: 'gray.100',
            }}
            _focus={{
              outline: 0,
              border: 'none',
              backgroundColor: 'green.100',
            }}
          >
            {' '}
            update{' '}
          </AvatarBadge>
        </Avatar>

        <PhotoSelect
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
          onSelect={updateProfilePicture}
          heading={'Update Profile Picture'}
          max={1}
        />
        <Tabs
          variant={'soft-rounded'}
          my={5}
          p={4}
          bg={useColorModeValue('white', 'gray.700')}
          borderWidth={'1px'}
          borderRadius={'xl'}
        >
          <TabList
            as={Flex}
            flexWrap={'wrap'}
            className={'no-scroll'}
            borderBottomWidth={'1px'}
            mb={3}
          >
            <Tab
              size={'sm'}
              width={'fit-content'}
              as={Button}
              mr={3}
              mb={3}
              _focus={{
                border: '0',
                fontWeight: 'bold',
              }}
              rightIcon={<Icon as={MdAccountBox} />}
              {...getPinpointButtonClickEventProp({
                command: 'settings business tab',
                currentPage: pathname,
              })}
            >
              {' '}
              Business{' '}
            </Tab>
            <Tab
              size={'sm'}
              width={'fit-content'}
              as={Button}
              mr={3}
              mb={3}
              _focus={{
                border: 'none',
                fontWeight: 'bold',
              }}
              rightIcon={<Icon as={MdPayment} />}
              {...getPinpointButtonClickEventProp({
                command: 'settings payment tab',
                currentPage: pathname,
              })}
            >
              {' '}
              Payment {useBreakpointValue({ base: '', md: 'Methods' })}{' '}
            </Tab>
          </TabList>

          <TabPanels p={0} m={0}>
            <TabPanel p={0} m={0} >
              <PersonalInfo user={user} />
            </TabPanel>
            <TabPanel p={0} m={0}>
              <PaymentsInfo user={user} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Main>
  );
}
