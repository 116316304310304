import { Auth } from '@aws-amplify/auth';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import React, { useRef } from 'react';
import { useMutation } from 'react-query';
//import { FaHandshake } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { cognitoPartnerAtom, isAuthenticatedAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { clearCustomAuthSession } from '../../utils/functions';
import Link from '../buttons/link';
import Navigation from '../navigation';

const { REACT_APP_STAGE = '' } = process.env;

export default function Header() {
  const [user, setUser] = useRecoilState(cognitoPartnerAtom);

  const navigate = useNavigate();
  const toast = useToast();

  const [authenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedAtom);

  const accountMenuInitialFocusRef = useRef();

  const signOutMutation = useMutation(async () => await Auth.signOut(), {
    onSuccess: () => {
      setUser(null);
      setIsAuthenticated(false);
      clearCustomAuthSession();
      localStorage.clear();
      window.location.reload();
      navigate(paths.home);
    },
    onError: () =>
      toast({
        title: 'Error Occured!',
        description: 'Failed Sign Out',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      }),
  });

  const textColor = useColorModeValue('gray.900', 'gray.300');
  const linkColor = useColorModeValue('black', 'white');

  return (
    <HStack
      as={'header'}
      position={'sticky'}
      top={0}
      zIndex={1000}
      width={'100vw'}
      spacing={{
        base: 4,
        md: 6,
      }}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'sm'}
      py={4}
      px={{
        base: 4,
        md: 8,
      }}
    >
      <HStack spacing={4}>
        <Image
          src={'/512.png'}
          width={'32px'}
          minW={'32px'}
          height={'32px'}
          m={'auto'}
          rounded={'full'}
          cursor={'pointer'}
          onClick={() => navigate(paths.home)}
        />

        <Text
          fontSize={'xl'}
          letterSpacing={'0.2'}
          fontWeight={'bold'}
          color={REACT_APP_STAGE !== 'prod' ? 'red' : textColor}
        >
          Riaparts {`${REACT_APP_STAGE !== 'prod' ? 'DEV' : ''}`}
        </Text>
      </HStack>
      <Spacer />
      <Navigation />
      {authenticated && (
        <Menu>
          <MenuButton
            as={Avatar}
            src={user?.imageS3Name}
            size={'sm'}
            cursor={'pointer'}
          />
          <MenuList
            as={VStack}
            spacing={2}
            p={4}
            alignItems={'start'}
            width={'full'}
          >
            <Link
              href={paths.settings}
              color={{
                base: linkColor,
                md: 'white',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              {' '}
              Account Settings{' '}
            </Link>
            <Link
              href={paths.terms}
              color={{
                base: linkColor,
                md: 'black',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              {' '}
              <Text fontWeight={'medium'}>Terms & Conditions</Text>
            </Link>
            <Link
              href={paths.refund}
              color={{
                base: linkColor,
                md: 'black',
              }}
              fontWeight={'medium'}
              ref={accountMenuInitialFocusRef}
            >
              {' '}
              <Text fontWeight={'medium'}>Return & Refund Policy</Text>
            </Link>
            {/*
                  <ServoiceFeedback
                  config={servoiceConfig}
                  >
                  
                    <Text fontWeight={"medium"}> Feedback </Text>
                  </ServoiceFeedback>
                */}
            <Divider />
            <Button
              size={'sm'}
              width={'full'}
              colorScheme={'red'}
              onClick={signOutMutation.mutate}
              isLoading={signOutMutation.isLoading}
            >
              {' '}
              log out{' '}
            </Button>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
}
