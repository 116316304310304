import { HStack, Image, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from "@chakra-ui/react";
import { MdMoreVert } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { quoteAtom } from "../../../recoil/atoms";
import { getPinpointButtonClickEventProp } from "../../../utils/functions";
import Link from "../../buttons/link";
import Carousel from "../../carousel";
import ImageContainer from "../../carousel/image-container";


export default function OrderQuote({ quote }) {

  const setSelectedQuote = useSetRecoilState(quoteAtom);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <HStack
      width={"full"}
      spacing={3}
      justify={"start"}
      
    >
      <Carousel
        width={"54px"}
        minW={"54px"}
        height={"44px"}
        borderRadius={"4px"}
      >
        {
          quote.photos.map(photo => {
            return (
              <ImageContainer
                key={photo.imageS3Name}
              >
                <Image
                  src={photo.imageS3Name}
                  height={"full"}
                  width={"full"}
                  objectFit={"cover"}
                />
              </ImageContainer>
            )
          })
        }
      </Carousel>
      <VStack
        spacing={0}
        align={"start"}
      >
        <Text
          fontSize={"xs"}
          fontWeight={"semibold"}
        >
          {quote.request.autoPart.quantity}
          <Text
            as={"span"}
            fontWeight={"light"}
          > &times; </Text>
          {quote.autoPart.autoPart.name}
        </Text>
        <Text
          fontSize={"10px"}
        > {quote.request.request.make} - {quote.request.request.model} - {quote.request.request.variant} </Text>
      </VStack>
      <Menu>
        <MenuButton children={<MdMoreVert size={"12px"} />} />
        <MenuList fontSize={"xs"}>
          <MenuItem
            as={Link}
            href={location.pathname+"#quote-"+quote.quote.id}
            {...getPinpointButtonClickEventProp({
              command: "menu item link to view more quote details",
              currentPage: location.pathname,
            })}
            onClick={
              e => {
                e.preventDefault();
                setSelectedQuote(quote);
                navigate(location.pathname+"#quote-"+quote.quote.id);
              }
            }
          > View Quote Details </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}