import { API } from 'aws-amplify';
import searchClient from "../typesense/client";
import {
  getPartner as getPartnerQuery,
  getCustomer as getCustomerQuery,
  getVehicleCategoryByType,
  getAutoPartCategories as getAutoPartCategoriesQuery,
  getAutoPartSubCategoriesByCategory as getAutoPartSubCategoriesByCategoryQuery,
  searchRequests as searchRequestsQuery,
  searchOrders as searchOrdersQuery,
  getConversationsByCreator,

  getRequest as getRequestQuery,
  getOrder as getOrderQuery,
  getConversation as getConversationQuery,
  
  getVehicleCategoryTree,
  getQuote as getQuoteQuery,
  getPhotosByCreator,
  getMessagesByConversation,
  getMessage,
  getServiceFee as getServiceFeeQuery,
  searchQuotes as searchQuotesQuery
} from '../graphql/queries';
import { authModes } from '../utils/constants';

export async function getPartner({ queryKey }) {
  // console.clear();
  const { partner } = queryKey[1];
  const { data } = await API.graphql({
    query: getPartnerQuery,
    variables: {
      id: partner.sub || partner.id
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getPartner;
}

export async function getCustomer({ queryKey }) {
  const { id } = queryKey[1];
  const { data } = await API.graphql({
    query: getCustomerQuery,
    variables: {
      id
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getCustomer;
}

export async function getCarMakes({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getVehicleCategoryByType,
    variables: {
      input: {
        type: "MAKE",
      },
    },
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getVehicleCategoryByType.categories;
}

export async function getCarModels({ queryKey }) {
  // get isAuthenticated, carMakes and makeValue variables passed to this query function.
  const { isAuthenticated, carMakes, makeValue } = queryKey[1];
  let make;
  // get car make object from carMakes variable which is equal to the input makeValue the user enters
  carMakes.some(m => {
    if(m.name.toLowerCase() === makeValue.toLowerCase()) {
      make = m;
      return;
    }
  });
  if(!make) return [];
  const { data } = await API.graphql({
    query: getVehicleCategoryTree,
    variables: {
      input: {
        id: make.id,
        entityType: "MAKE",
        descendants: {
          depth: 1
        }
      },
    },
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getVehicleCategoryTree.descendants[0].categories;
}

export async function getCarModelVariants({ queryKey }) {
  // get isAuthenticated, carModels and modelValue variables passed to this query function.
  const { isAuthenticated, carModels, modelValue } = queryKey[1];
  let model;
  // get car model object from carModels variable which is equal to the input modelValue the user enters
  carModels.some(m => {
    if(m.name.toLowerCase() === modelValue.toLowerCase()) {
      model = m;
      return;
    }
  });
  if(!model) return []
  const { data } = await API.graphql({
    query: getVehicleCategoryTree,
    variables: {
      input: {
        id: model.id,
        entityType: "MODEL",
        descendants: {
          depth: 1
        }
      },
    },
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getVehicleCategoryTree.descendants[0].categories;
}


export async function searchAutoParts({ pageParam = 1, queryKey }) {
  // get filters and search key variables;
  let { filters, key, limit } = queryKey[1];
  // configure typesense search parameters;
  let parameters = {
    'q': key,
    'query_by': 'name,model,make',
    page: pageParam,
    per_page: limit,
  }
  // if some filters exist, add them to the typesense search parameters in their right format;
  if(filters) {
    // turn filters into typesense accepted text format for filters;
    let textFilters = ""; // typesense recognized format for filters;
    filters = Object.entries(filters);
    filters.forEach((filter, i) => {
      if(!filter[1]) return;
      textFilters += `${i !== 0 ? " &&": ""} ${filter[0]}:\`${filter[1]}\``;
    });
    // filter variable could be an empty object or the filters may be empty strings so check check if any where set; 
    if(textFilters) parameters.filter_by = textFilters;
    // perform search;
  }
  const results = await searchClient.collections("autoparts").documents().search(parameters, {});
  // destructure hits to return only the documents;
  results.hits = results.hits.map(({ document }) => document);
  return {
    ...results,
    next: pageParam+1
  }
  
}


export async function getAutoPartCategories({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getAutoPartCategoriesQuery,
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getAutoPartCategories.autoPartCategories;
}

export async function getAutoPartSubCategoriesByCategory({ queryKey }) {
  const { isAuthenticated, activeCategory } = queryKey[1];
  const { data } = await API.graphql({
    query: getAutoPartSubCategoriesByCategoryQuery,
    variables: {
      categoryId: activeCategory.id
    },
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getAutoPartSubCategoriesByCategory.autoPartSubCategories;
}

export async function searchRequests({ pageParam = null, queryKey }) {
    // get filters and search key variables;
    let { filters, key = '', limit = 10 } = queryKey[1];
    // configure typesense search parameters;
    let parameters = {
      'q': key,
      'query_by': 'partName,model,make',
      page: pageParam,
      per_page: limit,
    }
    // if some filters exist, add them to the typesense search parameters in their right format;
    if(filters) {
      // turn filters into typesense accepted text format for filters;
      let textFilters = ""; // typesense recognized format for filters;
      filters = Object.entries(filters);
      filters.forEach((filter, i) => {
        if(!filter[1]) return;
        textFilters += `${i !== 0 ? " &&": ""} ${filter[0]}:\`${filter[1]}\``;
      });
      // filter variable could be an empty object or the filters may be empty strings so check check if any where set; 
      if(textFilters) parameters.filter_by = textFilters;
      // perform search;
    }
    const results = await searchClient.collections("requests").documents().search(parameters, {});
    // destructure hits to return only the documents;
    results.hits = results.hits.map(({ document }) => document);
    return {
      ...results,
      next: pageParam+1
    }
}

export async function searchOrders({ pageParam = null, queryKey }) {
  const { dateRange, status, user, limit } = queryKey[1];
  let input = {};
  const limitAndToken = {
    limit,
    nextToken: pageParam
  };
  const creator = {
      creatorType: "PARTNER",
      id: user.sub || user.id
  };
  if(dateRange?.start && status !== "all") {
      Object.assign(input, {
          byStatusAndDateRangeAndCreator: {
              creator,
              status: status,
              dateRange,
              limitAndToken
          }
      });
  } else if(dateRange?.start) {
      Object.assign(input, {
          byCreatorAndDateRange: {
              creator,
              dateRange,
              limitAndToken
          }
      });
  } else if(status !== "all") {
      Object.assign(input, {
          byCreatorAndStatus: {
              creator,
              status: status.toUpperCase(),
              limitAndToken
          }
      });
  } else {
      Object.assign(input, {
          byCreator: {
              ...creator,
              limitAndToken
          }
      });
  }
  let { data } = await API.graphql({
      query: searchOrdersQuery,
      variables: {
        input
      },
      authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  data = Object.values(data.searchOrders).find(value => value !== null);
  console.clear();
  console.log(data);
  if(data) return data;
  else throw new Error("Error fetching order");
}

export async function getOrder({ queryKey }) {
  const { orderId } = queryKey[1];
  const { data } = await API.graphql({
    query: getOrderQuery,
    variables: { id: orderId },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getOrder;
}

export async function getPhotos({ pageParam = null, queryKey }) {
  const { limit, user } = queryKey[1];
  const { data } = await API.graphql({
    query: getPhotosByCreator,
    variables: {
      input: {
        creatorType: "PARTNER",
        id: user.sub || user.id,
        limitAndToken: {
          limit,
          nextToken: pageParam,
        }
      }
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getPhotosByCreator;
}

export async function getConversation({ queryKey }) {
  const { id } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationQuery,
    variables:{
      id
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getConversation;
}

export async function getNegotiations({ pageParam, queryKey }) {
  const { user, limit } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationsByCreator,
    variables: {
      id: user.sub || user.id,
      senderType: 'PARTNER',
      limit, nextToken: pageParam
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getConversationsByCreator;
}

export async function getNegotiationMessage({ queryKey }) {
  const { id } = queryKey[1];
  return (await API.graphql({
    query: getMessage,
    variables: { id },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  })).data.getMessage;
}

export async function getNegotiationMessages({ pageParam, queryKey }) {
  const { user, limit, conversation } = queryKey[1];
  const { data } = await API.graphql({
    query: getMessagesByConversation,
    variables: {
      id: conversation.conversation.id,
      limit, nextToken: pageParam
    }
  });
  // console.log(data);
  return data.getMessagesByConversation
}

export async function getConversationsByCustomer({ pageParam, queryKey}) {
  const { user } = queryKey[1];
  const { data } = await API.graphql({
    query: getConversationsByCreator,
    variables: {
      senderType: "PARTNER",
      id: user.sub || user.id
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.getConversationsByCreator;
}

export async function getRequests({ pageParam, queryKey }) {
  const { dateRange, status = "all", limit, isAuthenticated } = queryKey[1];
  let input = {};
  const limitAndToken = {
    limit,
    nextToken: pageParam
  };
  if(dateRange?.start && status !== "all") {
      Object.assign(input, {
          byStatusAndDateRange: {
              status: status,
              dateRange,
              limitAndToken
          }
      });
  } else if(dateRange?.start) {
      Object.assign(input, {
          byDateRange: {
              dateRange,
              limitAndToken
          }
      });
  } else if(status !== "all") {
      Object.assign(input, {
          byStatus: {
              status: status.toUpperCase(),
              limitAndToken
          }
      });
  } else {
      Object.assign(input, {
          all: {
              all: true,
              limitAndToken
          }
      });
  }
  let { data } = await API.graphql({
      query: searchRequestsQuery,
      variables: {
        input
      },
      authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  data = Object.values(data.searchRequests).find(value => value !== null);
  if(data) return data;
  else throw new Error("Error fetching requests");
}

export async function searchQuotes({ pageParam, queryKey }) {
  const { dateRange, user, status, limit, isAuthenticated } = queryKey[1];
  let input = {};
  const creator = {
    creatorType: 'PARTNER',
    id: user.sub || user.id,
  };
  const limitAndToken = {
    limit: 50,
    nextToken: pageParam,
  };
  if (status && dateRange && dateRange.start) {
    input.byStatusAndDateRangeAndCreator = {
      creator,
      status: status.toUpperCase(),
      dateRange,
      limitAndToken,
    };
  } else if(status) {
    input.byCreatorAndStatus = {
      creator,
      status: status.toUpperCase(),
      limitAndToken,
    };
  } else if (dateRange && dateRange.start) {
    input.byCreatorAndDateRange = {
      creator,
      dateRange,
      limitAndToken,
    };
  } else {
    input.byCreator = {
      ...Object.assign({}, creator, { limitAndToken })
    }
  }
  console.log(input);
  let { data } = await API.graphql({
      query: searchQuotesQuery,
      variables: {
        input
      },
      authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  console.log('data.searchQuotes::', data.searchQuotes)
  data = Object.values(data.searchQuotes).find(value => value !== null);
  if(data) return data;
  else throw new Error("Error fetching requests");
}

export async function getQuote({ queryKey }) {
  const { id } = queryKey[1];
  const { data } = await API.graphql({
    query: getQuoteQuery,
    variables: {
      id
    }
  });
  return data.getQuote;
}

export async function getRequestQuotes({ pageParam = 0, queryKey }) {
  const { request, limit, } = queryKey[1];
  const subQuoteIds = request.data.request.quoteIds.slice(pageParam, pageParam+limit);
  let quotes = await Promise.all(subQuoteIds.map(id => {
    return API.graphql({
        query: getQuoteQuery,
        variables: {
            id
        }
    });
  }));
  quotes = quotes.map(quote => quote.data.getQuote);
  return { pageParam, quotes };
}

// GET ENTITY TYPE QUERIES
export async function getRequest({ queryKey }) {
  const { id, request } = queryKey[1];
  if(request && request.request && request.request.id === id) return request;
  const { data } = await API.graphql({
    query: getRequestQuery,
    variables: {
      id
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS
  });
  return data.getRequest;
}

export async function getServiceFee({ queryKey }) {
  const { isAuthenticated } = queryKey[1];
  const { data } = await API.graphql({
    query: getServiceFeeQuery,
    variables: { date: (new Date()).toJSON() },
    authMode: isAuthenticated ? authModes.AMAZON_COGNITO_USER_POOLS : authModes.AWS_IAM
  });
  return data.getServiceFee.fee
}