
import {
  Box,
  Button,
  Center, Icon,
  IconButton,
  ScaleFade,
  SimpleGrid,
  Text,
  VStack
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { MdCheck, MdForward } from "react-icons/md";
import { useInfiniteQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getPhotos } from "../../api/queries";
import { cognitoPartnerAtom } from "../../recoil/atoms";
import { getPinpointButtonClickEventProp } from "../../utils/functions";
import Skeletons from "../loaders/skeletons";


export default function PhotoList({
  max,
  onSelect
}) {

  const user = useRecoilValue(cognitoPartnerAtom);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const limit = 6;
  const {pathname} = useLocation();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["photos", { user, limit }], getPhotos, {
    getNextPageParam: previousPage => previousPage.nextToken,
    retryDelay: 0,
  });

  return (
    <VStack
      spacing={4}
      align={"start"}
    >
      <Text
        fontSize={"sm"}
        fontWeight={"medium"}
      > Select Photos By Clicking On Them. </Text>
      <SimpleGrid
        columns={{
          base: 3,
          md: 5
        }}
        spacing={2}
        width={"full"}
      >

        {
          data && data.pages && data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {
                  page.photos.map((photo) => {
                    photo = Object.assign({}, photo);
                    return (
                      <Box
                        as={ScaleFade}
                        in={true}
                        key={photo.id}
                        height={"84"}
                        width={"full"}
                        backgroundImage={photo.imageS3Name}
                        backgroundSize={"cover"}
                        backgroundRepeat={"no-repeat"}
                        backgroundPosition={"center"}
                        backgroundColor={"blackAlpha.200"}
                        borderRadius={"4px"}
                        cursor={"pointer"}
                        {...getPinpointButtonClickEventProp({
                          command: 'select a photo from photos a user uploaded before',
                          currentPage: pathname,
                        })}
                        onClick={
                          () => {
                            setSelectedPhotos(selectedPhotos => {
                              if (max < 2) {
                                onSelect(photo);
                                return [photo];
                              };
                              let photos = selectedPhotos.filter(selectedPhoto => selectedPhoto.id !== photo.id);
                              if (photos.length === selectedPhotos.length) photos.push(photo);
                              return photos;
                            })
                          }
                        }
                      >
                        {
                          selectedPhotos.some(p => p.id === photo.id) && (
                            <Center
                              width={"full"}
                              height={"full"}
                              backgroundColor={"blackAlpha.700"}
                              borderRadius={"inherit"}
                            >
                              <IconButton
                                icon={
                                  <Icon
                                    as={MdCheck}
                                    w={8}
                                    h={8}
                                    color={"white"}
                                  />
                                }
                                opacity={1}
                                rounded={"full"}
                                bg={"blue.400"}

                              />
                            </Center>
                          )
                        }
                      </Box>
                    )
                  })
                }
              </Fragment>
            )
          })
        }
        {
          isLoading && (<Skeletons count={limit} height={"100px"} borderRadius={"8px"} />)
        }
      </SimpleGrid>
      {
        hasNextPage && (
          <Button
            variant={"link"}
            fontSize={"xs"}
            fontWeight={"normal"}
            colorScheme={"blue"}
            isLoading={isLoading}
            onClick={fetchNextPage}
            {...getPinpointButtonClickEventProp({
              command: 'load more images from images a user uploaded before',
              currentPage: pathname,
            })}
          > load more images </Button>
        )
      }
      <Button
        width={"full"}
        height={"44px"}
        rounded={"4px"}
        colorScheme={"green"}
        justifyContent={"space-between"}
        rightIcon={<Icon as={MdForward} />}
        disabled={selectedPhotos.length < 1}
        {...getPinpointButtonClickEventProp({
          command: 'proceed with selected photos after the user selects desired photos',
          currentPage: pathname,
        })}
        onClick={
          () => {
            onSelect(selectedPhotos);
          }
        }
        zIndex={100}
      > Proceed </Button>
    </VStack>
  )
}