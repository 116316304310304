import { Routes as Switch, Route } from 'react-router-dom';
import withoutAuthentication from "./hocs/without-authentication";
import withAuthentication from "./hocs/with-authentication";
import Home from './pages/Home/index';
import Login from './pages/Login';
import Register from './pages/Register';
import OTP from './pages/OneTimePassword';
import Quotes from './pages/Quotes';
import Orders from './pages/Orders';
import Order from './pages/Order';
import { paths } from './utils/constants.js';
import Negotiations from './pages/Negotiations';
import Refund from './pages/Refund';
import Terms from './pages/Terms';
import Settings from './pages/Settings';

export default function Routes() {
  return (
    <Switch>
      <Route exact path={paths.home} element={<Home />} />
      <Route exact path={paths.login} element={withoutAuthentication(Login)} />
      <Route exact path={paths.register} element={withoutAuthentication(Register)} />
      <Route exact path={paths.otp} element={withoutAuthentication(OTP)} />
      <Route exact path={paths.quotes} element={withAuthentication(Quotes)} />
      <Route exact path={paths.orders} element={withAuthentication(Orders)} />
      <Route exact path={paths.order+"/:id"} element={withAuthentication(Order)} />
      <Route exact path={paths.negotiations} element={withAuthentication(Negotiations)} />
      <Route exact path={paths.refund} element={withAuthentication(Refund)} />
      <Route exact path={paths.terms} element={withAuthentication(Terms)} />
      <Route exact path={paths.settings} element={withAuthentication(Settings)} />
    </Switch>
  );
}