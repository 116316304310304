

import {
  Heading,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import Main from '../components/layouts/Main';
import { useToast } from '@chakra-ui/toast';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ButtonPrimary } from '../components/buttons';
import Link from '../components/buttons/link';
import CountrySelect from '../components/inputs/country-select';
import { incognitoSignUpAtom, OTPPhoneNumberAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';
import { getRandomString, storeCustomAuthSession } from '../utils/functions';

export default function CustomerLogin() {


  const [OTPPhoneNumber, setOTPPhoneNumber] = useRecoilState(OTPPhoneNumberAtom);
  const [country, setCountry] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const toast = useToast();

  const signIn = useMutation(async ({ phoneNumber }) => {
    return await Auth.signIn(phoneNumber);
  });

  const signUp = useMutation(async ({ phoneNumber }) => {
    const password = getRandomString(0);
    return await Auth.signUp({
      username: phoneNumber, password,
    });
  });


  useEffect(() => {
    // sign in succeeds, proceed to OTP page;
    if(signIn.isSuccess) {
      storeCustomAuthSession(signIn.data);
      navigate(paths.otp);
    } else if(signIn.isError) {
      const { error } = signIn;
      if (error.code === 'NotAuthorizedException') {
        toast({
          status: "info",
          title: "User with phone number provided does not exist",
          description: "Create an account instead"
        });
        navigate(paths.register);
      } else {
        toast({
          title: 'Error Occured!',
          description: error.message,
          status: 'error',
          duration: 2500,
          isClosable: true,
          position: 'bottom',
        });
      }
    }
  }, [signIn.status]);

  useEffect(() => {
    if(signUp.isSuccess) {
      storeCustomAuthSession(signUp.data);
      navigate(paths.otp);
    } else if(signUp.isError) toast({
      title: 'Error Occured!',
      description: signUp.error.message,
      status: 'error',
      duration: 1500,
      isClosable: true,
      position: 'bottom',
    });
  }, [signUp.status]);


  function onSubmit(formData) {
    const phoneNumberWithPrependedCountryCode = country.dial_code + formData.phoneNumber; 
    formData.phoneNumber = isValidPhoneNumber(phoneNumberWithPrependedCountryCode) ? phoneNumberWithPrependedCountryCode : isValidPhoneNumber(formData.phoneNumber) ? formData.phoneNumber : null;
    if(!formData.phoneNumber) {
      return toast({
        status: "warning",
        title: "Enter a valid phone number",
        position: "bottom",
        duration: 1500
      });
    }
    setOTPPhoneNumber(formData.phoneNumber);
    signIn.mutate(formData);
  }
  

  return (
    <Main
      spacing={5}
      minH={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={useColorModeValue("gray.100","gray.800")}
    >
      <Text
        fontSize={"3xl"}
        fontWeight={"bold"}
        cursor={"pointer"}
      >  </Text>
      
      <Image
        src={"/512.png"}
        width={"54px"}
        height={"54px"}
        m={"auto"}
        rounded={"full"}
        cursor={"pointer"}
        onClick={() => navigate(paths.home)}
      />

      <Heading
        textAlign={"center"}
      >
        Log in to Riaparts
      </Heading>


      <Stack
        as={"form"}
        width={"full"}
        maxW={"lg"}
        bg={useColorModeValue("white","blackAlpha.700")}
        className={useColorModeValue("bxs-uh","")}
        px={4}
        py={6}
        spacing={3}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          color={useColorModeValue("gray.900","gray.100")}
        > Phone Number </Text>
        <HStack
          spacing={2}
          width={"full"}
        >
          <CountrySelect
            mini={true}
            onChangeCallback={
              country => setCountry(country)
            }
          />
          <Input
            type={"tel"}
            fontSize={"xs"}
            width={"full"}
            placeholder={"(9/7)XXXXXXXX"}
            {
              ...register('phoneNumber', {
                required: "Please enter your phone number",
              })
            }
          />
        </HStack>
        {
          errors.phoneNumber && (
            <Text
              fontSize={"sm"}
              color={'red.400'}
            > { errors.phoneNumber.message } </Text>
          )
        }
        
        <ButtonPrimary
          type={"submit"}
          isLoading={signIn.isLoading || signUp.isLoading}
        > Next </ButtonPrimary>

        <Text
          fontSize={"xs"}
          color={useColorModeValue("gray.700","gray.200")}
        >
          Do not have an account with us ?{" "}
          <Link
            fontWeight={"medium"}
            textDecoration={"underline"}
            href={paths.register}
          >Register Here</Link>
        </Text>
        
      </Stack>

    

    </Main>

  );
}
