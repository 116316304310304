import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { getRequests } from "../api/queries";
import useRequestsSubscription from "../hooks/use-requests-subscription";
import { isAuthenticatedAtom } from "../recoil/atoms";



export default function withLiveRequests(Component) {

  return (props) => {

    const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
    const [requests, setRequests] = useState(null);

    // fetch first batch of 10 requests, the rest come in via subscription.  
    const initialRequestsQuery = useQuery(["requests", { limit: 6, isAuthenticated }], getRequests, {
      cacheTime: 0,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
      onSuccess: data => setRequests(data)
    });

    // subscribe to requests
    const { request } = useRequestsSubscription({ isAuthenticated });
    useEffect(() => {
      if(request) {
        // turn request structure to that of requests coming from typesense.
        const configuredRequest = {
          id: request.request.id,
          make: request.request.make,
          model: request.request.model,
          partName: request.autoPart.autoPart.name,
          photos: request.autoPart.autoPart.src,
          status: request.request.status,
          autoPart: request.autoPart,
          request: request.request
        };
        console.clear();
        console.log(configuredRequest);
        setRequests(requests => {
          requests = [...requests, configuredRequest];
          return requests;
        });
      }
    },[request]);

    return (
      <Component requests={{
        requests,
        isLoading: initialRequestsQuery?.isLoading
      }}
      { ...props }
    />
    )
  }

}