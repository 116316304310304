import { authModes } from '../utils/constants';

const dev = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_PHOTO_BUCKET_NAME,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: '',
  },
  appsync: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_REGION,
    aws_appsync_authenticationType: authModes.AMAZON_COGNITO_USER_POOLS,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  sentry: {
    DNS: process.env.REACT_APP_SENTRY_DNS
  },
  pinpoint: {
    APP_ID: process.env.REACT_APP_PINPOINT,
  },
};

const prod = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: 'YOUR_PROD_S3_UPLOADS_BUCKET_NAME',
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: 'YOUR_PROD_API_GATEWAY_URL',
  },
  appsync: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_REGION,
    aws_appsync_authenticationType: authModes.AMAZON_COGNITO_USER_POOLS,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  sentry: {
    DNS: process.env.REACT_APP_SENTRY_DNS
  },
  pinpoint: {
    APP_ID: process.env.REACT_APP_PINPOINT,
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

const configExport = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

export default configExport;
