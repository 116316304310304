import { HStack, VStack } from "@chakra-ui/react";
import Skeletons from "../../loaders/skeletons";


export default function Loading() {
  return (
    <>
      <HStack
        width={"full"}
      >
        <Skeletons
          count={1}
          minWidth={"94px"}
          minHeight={"74px"}
          width={"94px"}
          height={"74px"}
          borderRadius={"8px"}
        />
        <VStack
          spacing={2}
          width={"full"}
          align={"start"}
        >
          <Skeletons
            count={1}
            height={"28px"}
            borderRadius={"8px"}
          />
           <Skeletons
            count={1}
            height={"24px"}
            width={"77%"}
            borderRadius={"8px"}
          />
        </VStack>
      </HStack>

      <Skeletons
        count={5}
        height={"36px"}
      />
    </>
  )
}