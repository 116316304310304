
import { TimeIcon } from "@chakra-ui/icons";
import {
    Box,
    Text,
    Button,
    useColorModeValue,

} from "@chakra-ui/react";
import moment from "moment";



export const MomentsAgo = ({ children, date, ...rest }) => {

    return (
        <Button
            color={useColorModeValue("gray.500","gray.300")}
            p={0}
            outline={0}
            cursor={"pointer"}
            fontWeight={"normal"}
            fontSize={"12px"}
            backgroundColor={"transparent"}
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ bg: "transparent" }}
            as={Text}
            leftIcon={<TimeIcon size={"8px"} />}
            { ...rest }
        > { moment(date).fromNow() } </Button>
    )

}


export const ScrollFade = ({ ...props }) => {
    return (
        <Box
            display={{
                base: "block",
            }}
            padding={"50px"}
            backgroundImage={useColorModeValue("linear-gradient(to bottom, transparent, white)", "linear-gradient(to bottom, transparent, #1A202C)")}
            width={"full"}
            position={"absolute"}
            left={0}
            zIndex={2}
            marginTop={"-100px"}
            { ...props }
        />
    )
}