import { HStack, useBreakpointValue, useToast } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect, useRef } from 'react';
// import { ServoiceFeedback } from 'react-feedback-widget';
import { FiBriefcase, FiSettings, FiTruck } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { cognitoPartnerAtom, isAuthenticatedAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';
import { clearCustomAuthSession } from '../../utils/functions';
import NavigationItem from './item';

export default function NavigationContent(props) {
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedAtom);
  const [user, setUser] = useRecoilState(cognitoPartnerAtom);
  const navigate = useNavigate();
  const toast = useToast();

  const signOutMutation = useMutation(async () => await Auth.signOut());

  useEffect(() => {
    if (signOutMutation.isError) {
      toast({
        title: 'Error Occured!',
        description: 'Failed Sign Out',
        status: 'error',
        duration: 2500,
        isClosable: true,
        position: 'bottom',
      });
      return;
    }
    if (signOutMutation.isSuccess) {
      setUser(null);
      setIsAuthenticated(false);
      clearCustomAuthSession();
      localStorage.clear();
      window.location.reload();
      navigate(paths.home);
    }
  }, [signOutMutation.status]);

  const accountMenuInitialFocusRef = useRef();

  const iconSize = useBreakpointValue({
    base: '20px',
    md: '16px',
  });

  const ROUTES = [
    { path: paths.home, text: 'Home', icon: <GoHome size={iconSize} /> },
    {
      path: paths.quotes,
      text: 'Quotations',
      icon: <FiBriefcase size={iconSize} />,
    },

    { path: paths.orders, text: 'Orders', icon: <FiTruck size={iconSize} /> },
    {
      path: paths.settings,
      text: 'Settings',
      icon: <FiSettings size={iconSize} />,
    },
  ];

  return (
    <HStack
      direction={{
        base: 'row',
      }}
      justifyContent={{
        base: 'center',
        sm: 'center',
      }}
      alignItems={{
        base: 'center',
        md: 'center',
      }}
      spacing={{
        base: 6,
        md: 2,
      }}
      width={'full'}
      {...props}
    >
      {ROUTES.map((route, i) => {
        return <NavigationItem key={i} route={route} />;
      })}
    </HStack>
  );
}
