import {
    Avatar,
    Flex,
    ScaleFade,
    Spacer,
    Text,
    useColorModeValue,
    VStack
  } from "@chakra-ui/react";
  import { forwardRef } from "react";
  import { useRecoilValue } from "recoil";
  import { cognitoPartnerAtom} from "../../../../../recoil/atoms";
  import { formatPrice} from "../../../../../utils/functions";
  
  
  export default forwardRef(function ({ message, isLastMessageBySenderInSubMessageList, ...rest }, ref) {
  
    const user = useRecoilValue(cognitoPartnerAtom);
    const { data } = message;
    const quantity = data && data.quote && data.quote.request && data.quote.request.autoPart.quantity;
  
    const bg = useColorModeValue("brand-blue.50", "brand-blue.800");
    const borderColor = useColorModeValue("brand-blue.100", "brand-blue.900");
  
    return (
      <Flex
        width={"full"}
        pl={10}
        ref={ref}
        {...rest}
      >
        <Spacer />
  
        <VStack
          as={ScaleFade}
          in={true}
          maxW={{ base: "90%", md: "75%" }}
          borderLeftRadius={"12px"}
          borderTopRightRadius={"24px"}
          borderBottomLeftRadius={"24px"}
          borderBottomRightRadius={"0px"}
          bg={bg}
          borderWidth={"1px"}
          borderColor={borderColor}
          p={4}
        >
          <Text
            fontSize={"xs"}
            color={useColorModeValue("gray.800", "gray.300")}
          >
            The Customer has accepted your offer{" "}
            <Text
              as={"b"}
              color={useColorModeValue("gray.700", "gray.300")}
            >{formatPrice(data.quote.autoPart.quotedPrice)}</Text>
            {" "} for {quantity > 1 && "each of"} spare part{" "}.<br />
            {quantity > 1 && (
              <Text
                as={"span"}
              > Total Amount: <Text as={"span"} fontWeight={"black"}>{formatPrice(data.quote.autoPart.quotedPrice * quantity)}</Text> </Text>
            )}
          </Text>
        </VStack>
  
        <Flex
          minH={"full"}
          width={"13px"}
          align={"end"}
        >
  
          {isLastMessageBySenderInSubMessageList && (
            <Flex
              align={"center"}
              justify={"center"}
              p={1}
              transform={"translate(-17px,12px)"}
              rounded={"full"}
              bg={bg}
              borderWidth={"1px"}
              borderColor={borderColor}
            >
              <Avatar
                width={"20px"}
                height={"20px"}
                src={user.imageS3Name}
              />
            </Flex>
          )}
  
        </Flex>
  
  
  
      </Flex>
    )
  });