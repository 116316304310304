import { Heading, Text, Stack } from '@chakra-ui/react';
import Main from '../../components/layouts/Main';
import Search from './search';

export default function Home() {
  const placement = window.innerWidth;

  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        mb={'4'}
      >
        <Heading
          textTransform={'uppercase'}
          textAlign="center"
          fontSize={placement > 500 ? 'xl' : '24px'}
          data-heading="home"
        >
          <Text fontWeight={'hairline'} color={'black'}>
            Connecting you with
          </Text>
          <Text color={'#FF7F00'}>
            <b>autopart customers everywhere</b>
          </Text>
        </Heading>
      </Stack>
      <Search />
    </Main>
  );
}
