import { Box, VStack } from "@chakra-ui/react";
import { Component, createRef } from "react";


export default class ScrollableFeed extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.containerRef = createRef();
    this.scrollEndCallback = createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
  }

  static getDerivedStateFromProps({ tolerablePixels = 40, forceScroll = false, preventAbsoluteTopScroll = true }) {
    return { tolerablePixels, forceScroll, preventAbsoluteTopScroll };
  }

  componentDidMount() {
    // scroll to the bottom when the component first mounts.
    this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
  }

  getSnapshotBeforeUpdate() {
    if(this.state.forceScroll) return null;
    const scrollHeight = this.containerRef.current.scrollHeight;
    const clientHeight = this.containerRef.current.clientHeight;
    const scrollTop = this.containerRef.current.scrollTop;
    return { scrollHeight, clientHeight, scrollTop };
  }

  componentDidUpdate(props,state,snapshot) {
    // scrolls to the bottom if !!forceScroll or the user was already at the bottom before a ui update.
    if (this.state.forceScroll || Math.abs(snapshot.scrollHeight - snapshot.clientHeight - snapshot.scrollTop) < state.tolerablePixels) {
      this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
    }
  }

  handleScroll(e) {
    if(!this.state.preventAbsoluteTopScroll) return;
    window.clearInterval(this.scrollEndCallback.current);
    this.scrollEndCallback.current = setTimeout(this.onScrollEnd,100);
  }

  onScrollEnd(e) {
    if(this.containerRef.current.scrollTop < this.state.tolerablePixels) {
      this.containerRef.current.scrollTop = this.state.tolerablePixels;
    }
  }


  render() {
    const { children, ...rest } = this.props;
    return (
      <VStack
        ref={this.containerRef}
        spacing={5}
        width={"full"}
        onScroll={this.state.preventAbsoluteTopScroll ? this.handleScroll : null}
        { ...rest }
      >
        {
          this.state.preventAbsoluteTopScroll && (
            <Box
              width={"full"}
              minH={this.state.tolerablePixels+"px"}
            />
          )
        }

        { children }

      </VStack>
    )
  }
}


/*

export default function ScrollableFeed({ tolerablePixels = 40, preventAbsoluteTopScroll = true, forceScroll = false, children, ...rest }) {

  const containerRef = useRef();

  const variables = {
    offsetHeight: 0,
    scrollHeight: 0,
    scrollTop: 0
  };

  function getVariablesBeforePaint() {
    // get variables to be used after the ui paint.
    if (!containerRef.current) return;
    variables.scrollHeight = containerRef.current.scrollHeight;
    variables.offsetHeight = containerRef.current.offsetHeight;
    variables.scrollTop = containerRef.current.scrollTop;
  }

  function afterPaint() {
    // scrolls to the bottom if !!forceScroll or the user was already at the bottom before a ui update.
    // console.log("scrollTop :", variables.scrollTop, "calcs:",  (variables.scrollHeight - variables.offsetHeight) - tolerablePixels)
    // console.log(tolerablePixels)
    if (containerRef.current && Math.abs(variables.scrollHeight - variables.offsetHeight - variables.scrollTop) < tolerablePixels) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      Object.assign(variables, { offsetHeight: 0, scrollHeight: 0, scrollTop: 0 });
    }
  }

  useLayoutEffect(getVariablesBeforePaint);
  useEffect(afterPaint);

function handleScroll(e) {
  return;
  const scrollTop = e.target.scrollTop;
  if (scrollTop === 0) e.target.scrollTop = 0.1
}

return (
  <VStack
    ref={containerRef}
    spacing={5}
    width={"full"}
    children={children}

    {...rest}
  />
);
}

*/