
import {
  Heading, Image, Text, useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import ScrollableFeed from "react-scrollable-feed";
import Request from "../../components/requests/mini";
import Main from "../../components/layouts/Main";
import withLiveRequests from "../../hocs/with-live-requests";
import Illustration from "./car.svg";
import { ButtonPrimary } from "../../components/buttons";
import Skeletons from "../../components/loaders/skeletons";
import Link from "../../components/buttons/link";
import { paths } from "../../utils/constants";

const Onboarding = withLiveRequests(function(props) {

  let requests = [], isLoading;
  
  if(props.requests && props.requests.requests) {
    requests = props.requests.requests.requests;
    isLoading = props.requests.isLoading;
  }

  const nextActions = (
    <VStack
      spacing={2}
      width={"full"}
    >
      <ButtonPrimary
        as={Link}
        textDecor={"none"}
        href={paths.register}
        size={"md"}
        minH={"44px"}
        width={"full"}
      > Sign Up </ButtonPrimary>
      <Text
        fontSize={"sm"}
        color={useColorModeValue("gray.800","gray.200")}
        width={"full"}
        textAlign={{
          base: "center",
          md: "left"
        }}
      > Already have an account ? <Link fontWeight={"semibold"} href={paths.login}> Login Here </Link> </Text>
    </VStack>
  );

  return (
    <Main
      flexDirection={{
        base: "column",
        md: "row"
      }}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      pb={4}
    >
      <VStack
        spacing={4}
        px={{
          base: 0,
          md: "44px" 
        }}
        py={{
          ase: 0,
          md: "22px"
        }}
      >
        <Image
          width={{
            base: "320px",
            md: "440px"
          }}
          height={{
            base: "230px",
            md: "314px"
          }}
          src={Illustration}
        />
        <Heading
          fontSize={{
            base: "lg",
            md: "20px"
          }}
          color={useColorModeValue("gray.900", "gray.50")}
          textAlign={{
            base: "center",
            md: "left"
          }}
          maxW={"440px"}
        > Connecting You To Auto Parts Customers</Heading>
        { useBreakpointValue({ base: "", md: nextActions}) }
      </VStack>
      <VStack
        as={ScrollableFeed}
        width={{
          base: "full",
          md: "400px"
        }}
        height={{
          base: "full",
          md: "480px"
        }}
        spacing={4}
        overflowY={"scroll"}
        className={"no-scroll"}
      >
        {
          requests.map(request => {
            return (
              <Request
                request={request}
                key={request.request.id}
              />
            )
          })
        }

        {
          isLoading && (
            <Skeletons count={10} minH={"80px"} borderRadius={"8px"} />
          )
        }
      </VStack>
      { useBreakpointValue({ base: nextActions, md: "" }) }
    </Main>
  )
});

export default Onboarding;