import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import {
  createAutoPart as createAutoPartMutation,
  createQuote as createQuoteMutation,
  createConversation as createConversationMutation,
  createMessage,
  updateQuote as updateQuoteMutation,
  updatePartner as updatePartnerMutation,
} from '../graphql/mutations';
import { authModes } from '../utils/constants';
import { compressImage, getBase64 } from '../utils/functions';

export async function signIn({ phoneNumber }) {
  return Auth.signIn(phoneNumber);
}

export async function createConversation({ user, partner }) {
  const input = {
    members: [
      { id: partner.id, entityType: 'PARTNER' },
      { id: user ? user.sub : '', entityType: 'CUSTOMER' },
    ],
  };
  const { data } = await API.graphql({
    query: createConversationMutation,
    variables: {
      input,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.createConversation;
}
export async function createQuote({
  user,
  request,
  price,
  photos,
  isNew,
  serviceFee,
  expires,
  expiryDate,
}) {
  let today = new Date();
  let nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 7);
  //console.log(nextWeek);
  const input = {
    requestId: request.request.id,
    partnerId: user.sub || user.id,
    status: 'OPEN',
    isNew,
    expiryDate: expires ? new Date(expiryDate).toJSON() : nextWeek.toJSON(),
    comment: JSON.stringify({ serviceFee }),
    photos: photos.map(photo => photo.id),
    autoPartQuote: {
      autoPartId: request.autoPart.autoPart.id,
      quotedPrice: price,
    },
  };
  //console.log(expiryDate);
  const data = await API.graphql({
    query: createQuoteMutation,
    variables: { input },
  });
  const { quote } = data.data.createQuote;
  return quote;
}

export async function updateQuote({
  quote,
  isNew,
  price,
  photos,
  expires,
  expiryDate,
}) {
  const input = {
    id: quote.quote.id,
    photos,
    isNew,
    expiryDate: expires ? new Date(expiryDate).toJSON() : null,
    autoPartQuote: {
      autoPartId: quote.autoPart.autoPart.id,
      quotedPrice: price,
    },
  };
  const { data } = await API.graphql({
    query: updateQuoteMutation,
    variables: {
      input,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });
  return data.updateQuote;
}

export async function updatePartner(input) {
  console.log('I am input field', { ...input });

  const { data } = await API.graphql({
    query: updatePartnerMutation,
    variables: {
      input,
    },
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
  });

  console.log('yyyy', data);

  return data.updatePartner;
}

export async function updatePartnerAttributes({ attributes }) {
  const Partner = await Auth.currentAuthenticatedUser();
  return await Auth.updateUserAttributes(Partner, attributes);
}

export async function uploadPhoto({ uploadUrl, ...data }) {
  const results = await fetch(uploadUrl, {
    method: 'post',
    body: JSON.stringify(data),
  });
  return await results.json();
}

export async function compressAndUploadPhoto({ user, file }) {
  console.log(user);
  const compressedImage = await compressImage(file);
  const base64 = await getBase64(compressedImage);
  const data = await uploadPhoto({
    description: 'abc',
    contentType: file.type,
    file: base64,
    title: 'abc',
    creatorType: 'PARTNER',
    creatorId: user.sub || user.id,
    uploadUrl: `${process.env.REACT_APP_REST_ENDPOINT}/image-upload`,
  });
  return data;
}

export async function compressAndUploadPhotos({ user, files }) {
  let responses = [];
  while (responses.length < files.length) {
    responses.push(
      await compressAndUploadPhoto({ user, file: files[responses.length] })
    );
  }
  return responses;
}

export async function sendMessage({ user, body, conversation }) {
  const { data } = await API.graphql({
    query: createMessage,
    authMode: authModes.AMAZON_COGNITO_USER_POOLS,
    variables: {
      input: {
        message: body,
        senderId: user.sub || user.id,
        senderType: 'PARTNER',
        conversationId: conversation.conversation.id,
      },
    },
  });
  return data.createMessage;
}

export const _createAutoPart = async ({
  name,
  description,
  imageS3Name,
  partNumber,
}) => {
  try {
    const { data } = await API.graphql({
      query: createAutoPartMutation,
      variables: {
        input: {
          name,
          description,
          imageS3Name,
          partNumber,
        },
      },
      authMode: authModes.AMAZON_COGNITO_USER_POOLS,
    });

    return data.createAutoPart;
  } catch (error) {
    console.log(error);
  }
};
