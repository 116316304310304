import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Icon, Stack, Text } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { flatten } from 'lodash';
import { Children, cloneElement, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalCarouselChildrenAtom, modalCarouselStateAtom } from '../../recoil/atoms';
import {GrFormNext, GrFormPrevious} from 'react-icons/gr'
import { getPinpointButtonClickEventProp } from '../../utils/functions';
import { useLocation } from 'react-router-dom';
// import withParentDimensions from '../../hocs/with-parent-dimensions';

const Carousel = function({
  automatic = false,
  buttonPosition = 'middle',
  time = 4000,
  displayButtons = true,
  showModal = true,
  children,
  ...rest
}) {
  
  const setModalCarouselChildren = useSetRecoilState(modalCarouselChildrenAtom);
  const setModalCarouselOpenState = useSetRecoilState(modalCarouselStateAtom);
  const {pathname} = useLocation();

  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    function windowResize() {
      if (elementRef.current) {
        setDimensions({
          height: elementRef.current.clientHeight,
          width: elementRef.current.clientWidth,
        });
      }
    }
    windowResize();
    window.addEventListener('resize', windowResize);
    if (automatic) intervalRef.current = setInterval(scroll, time);
    return () => {
      window.removeEventListener('resize', windowResize);
      window.clearInterval(intervalRef.current);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const intervalRef = useRef();
  const elementRef = useRef();

  const scroll = (plus = 1) => {
    setIndex(index => {
      index = index + plus;
      index =
        index >= children.length ? 0 : index < 0 ? children.length - 1 : index;
      return index;
    });
  };

  useEffect(() => {
    elementRef.current.scrollLeft = index * elementRef.current.clientWidth;
  }, [index]);

  const openModal = function() {
    setModalCarouselOpenState(true);
    setModalCarouselChildren(children)
  }

  //const toggleButtonsDarkBg = useColorModeValue('blue.100', 'blue.50');
  //const imageCountBG = useColorModeValue("gray.100","gray.700");

  return (
    <Flex
      maxW={'full'}
      width={'full'}
      align={'flex-start'}
      justify={'start'}
      overflowX={'hidden'}
      className={'smooth-scroll'}
      ref={elementRef}
      // onMouseEnter={() => window.clearInterval(intervalRef.current)}
      // onMouseLeave={() => {
      //  window.clearInterval(intervalRef.current);
      //  intervalRef.current = window.setInterval(scroll, time);
      // }}
      {...rest}
    >
    
      {
        Children.toArray(children).map(child => {
          return cloneElement(child, {
            width: 'full',
            height: 'full',
            onClick: showModal ? openModal : child.props ? child.props.onClick : undefined,
          });
        })
      }

        {displayButtons && buttonPosition === 'bottom' && (
          <HStack
            alignItems={'center'}
            justifyContent={'center'}
            position={'absolute'}
            marginTop={dimensions.height - 40 + 'px'}
            height={'30px'}
            width={dimensions.width}
            spacing={3}
          >
      <Stack
        px={"4"}
        py={"2"}
        bg={"aliceblue"}
        rounded={"md"}
        onClick={() => {
          setIndex(prev => {
            console.log("decrease",prev)
            if(prev === 0) return 0;
             return prev - 1
          });
          window.clearInterval(intervalRef.current);
          if (automatic)
            intervalRef.current = window.setInterval(scroll, time);
        }}
        {...getPinpointButtonClickEventProp({
          command: 'carousel to show previous photo',
          currentPage: pathname,
        })}
        >
          <Icon as={GrFormPrevious} />
        </Stack>

        <Stack
        px={"4"}
        py={"2"}
        bg={"aliceblue"}
        rounded={"md"}
        onClick={() => {
          setIndex(prev => {
            console.log("increase",prev)
            if(prev === children.length - 1) return prev;
             return prev + 1
          });
          window.clearInterval(intervalRef.current);
          if (automatic)
            intervalRef.current = window.setInterval(scroll, time);
        }}
        {...getPinpointButtonClickEventProp({
          command: 'carousel to show next photo',
          currentPage: pathname,
        })}
        >
          <Icon as={GrFormNext} />
        </Stack>

          </HStack>
        )}

    </Flex>
  )
};

export default Carousel;



/*







export default function Carousel({
  automatic,
  buttonPosition = 'middle',
  time = 4000,
  displayButtons = true,
  showModal = true,
  children,
  ...rest
}) {
  const disclosure = useDisclosure();

  children = flatten(Children.toArray(children)).map(child => {
    return cloneElement(child, {
      width: 'full',
      height: 'full',
      onClick: showModal
        ? disclosure.onOpen
        : child.props
        ? child.props.onClick
        : undefined,
    });
  });

  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [index, setIndex] = useState(0);

  const intervalRef = useRef();
  const elementRef = useRef();

  const scroll = (plus = 1) => {
    setIndex(index => {
      index = index + plus;
      index =
        index >= children.length ? 0 : index < 0 ? children.length - 1 : index;
      return index;
    });
  };

  useEffect(() => {
    elementRef.current.scrollLeft = index * elementRef.current.clientWidth;
  }, [index]);

  useEffect(() => {
    function windowResize() {
      if (elementRef.current) {
        setDimensions({
          height: elementRef.current.clientHeight,
          width: elementRef.current.clientWidth,
        });
      }
    }
    windowResize();
    window.addEventListener('resize', windowResize);
    if (automatic) intervalRef.current = setInterval(scroll, time);
    return () => {
      window.removeEventListener('resize', windowResize);
      window.clearInterval(intervalRef.current);
    };
  }, []);

  const toggleButtonsDarkBg = useColorModeValue('pink.100', 'pink.50');

  const motionPreset = useBreakpointValue({
    base: 'slideInBottom',
    md: 'scale',
  });

  const modalSize = useBreakpointValue({
    base: 'full',
    md: '2xl',
    lg: '3xl',
  });

  return (
    <>
      <Flex
        maxW={'full'}
        width={'full'}
        align={'flex-start'}
        justify={'start'}
        overflowX={'hidden'}
        className={'smooth-scroll'}
        ref={elementRef}
        onMouseEnter={() => window.clearInterval(intervalRef.current)}
        onMouseLeave={() => {
          window.clearInterval(intervalRef.current);
          intervalRef.current = window.setInterval(scroll, time);
        }}
        {...rest}
      >
        {displayButtons && buttonPosition === 'middle' && (
          <IconButton
            position={'absolute'}
            maxW={8}
            height={8}
            rounded={'full'}
            marginLeft={3}
            marginTop={dimensions.height / 2 - 20 + 'px'}
            icon={<ChevronLeftIcon />}
            backgroundColor={'blackAlpha.400'}
            color={'whiteAlpha.700'}
            _hover={{
              backgroundColor: 'blackAlpha.600',
              color: 'white',
            }}
            _focus={{
              border: 'none',
            }}
            onClick={() => {
              window.clearInterval(intervalRef.current);
              scroll(-1);
              if (automatic)
                intervalRef.current = window.setInterval(scroll, time);
            }}
          ></IconButton>
        )}

        {children}

        {displayButtons && buttonPosition === 'middle' && (
          <IconButton
            position={'absolute'}
            maxW={8}
            height={8}
            rounded={'full'}
            marginLeft={dimensions.width - 60 + 'px'}
            marginTop={dimensions.height / 2 - 20 + 'px'}
            icon={<ChevronRightIcon />}
            backgroundColor={'blackAlpha.400'}
            color={'whiteAlpha.700'}
            _hover={{
              backgroundColor: 'blackAlpha.600',
              color: 'white',
            }}
            _focus={{
              border: 'none',
            }}
            onClick={() => {
              window.clearInterval(intervalRef.current);
              scroll();
              if (automatic)
                intervalRef.current = window.setInterval(scroll, time);
            }}
          ></IconButton>
        )}

        {displayButtons && buttonPosition === 'bottom' && (
          <HStack
            alignItems={'center'}
            justifyContent={'center'}
            position={'absolute'}
            marginTop={dimensions.height - 40 + 'px'}
            height={'30px'}
            width={dimensions.width}
            spacing={3}
          >
            {children.map((child, i) => {
              return (
                <Box
                  cursor={'pointer'}
                  display={'block'}
                  bg={index === i ? 'blue.100' : toggleButtonsDarkBg}
                  minWidth={index === i ? '15px' : '10px'}
                  minHeight={index === i ? '15px' : '10px'}
                  rounded={'full'}
                  onClick={() => {
                    setIndex(i);
                    window.clearInterval(intervalRef.current);
                    if (automatic)
                      intervalRef.current = window.setInterval(scroll, time);
                  }}
                />
              );
            })}
          </HStack>
        )}
      </Flex>
      {showModal && (
        <Modal
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          size={modalSize}
          scrollBehavior={'inside'}
          isCentered
          blockScrollOnMount
          closeOnEsc
          motionPreset={motionPreset}
        >
          <ModalOverlay
            bg="blackAlpha.400"
            backdropFilter="auto"
            backdropBlur="5px"
          />
          <ModalContent maxH={'400px'} height={'full'} p={0}>
            <ModalHeader position={'absolute'} width={'full'}>
              <ModalCloseButton color={'white'} />
            </ModalHeader>
            <ModalBody p={0}>
              <Carousel
                showModal={false}
                automatic={true}
                buttonPosition={'bottom'}
                width={'full'}
                height={'full'}
              >
                {children.map(child => {
                  return cloneElement(child, {
                    bg: 'black',
                    p: {
                      base: 4,
                      md: 12,
                    },
                  });
                })}
              </Carousel>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}






*/