import {
  Text, useColorModeValue
} from "@chakra-ui/react";



export default function Toggle({ toggleValue, value, label, setValue }) {
  const defaultToggleBG = useColorModeValue("gray.100","gray.800");
  const defaultToggleColor = useColorModeValue("gray.800","gray.100");
  return (
    <Text
      as={"button"}
      px={4}
      py={1}
      bg={value === toggleValue ? "limegreen" : defaultToggleBG}
      color={value === toggleValue ? "white" : defaultToggleColor}
      fontSize={"xs"}
      fontWeight={"bold"}
      onClick={
        () => setValue(toggleValue)
      }
    > { label } </Text>
  )
}