

import { forwardRef, useEffect, useState } from "react";
import {
  HStack,
  InputGroup,
  InputLeftAddon,
  Select,
  useColorModeValue
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import COUNTRIES from "../../utils/countries";

const CountrySelect = forwardRef(function({register = () => {}, mini = false, onChangeCallback, ...rest }, ref) {

  const [country, setCountry] = useState(COUNTRIES[COUNTRIES.length-2]);

  useEffect(
    () => {
      if(onChangeCallback) {
        onChangeCallback(country);
      }
    },
    [country]
  )

  const bg = useColorModeValue('gray.100','gray.800');
  const color = useColorModeValue('black', 'gray.200');
  return mini ? (
    <Select
      value={JSON.stringify(country)}
      variant={'outline'}
      width={"120px"}
      fontSize={"12px"}
      color={color}
      _placeholder={{
        color: color,
      }}
      {
        ...register('country', {
          validate: false,
          
        })
      }
      onChange={(e) => {
        setCountry(JSON.parse(e.target.value))
      }}
      { ...rest }
    
    >
    {
      COUNTRIES.map(country => {
        return <option key={country.code} value={JSON.stringify(country)}> { country.dial_code } { country.name } </option>
      })
    }
  </Select>
  ) : (
    <InputGroup
      bg={bg}
      as={HStack}
      spacing={0}
      borderRadius={"md"}
      { ...rest }
    >
    <InputLeftAddon
      bg={"transparent"}
      border={"none"}
      outline={"0"}
    >
      <ReactCountryFlag
        countryCode={country.code}
        svg
        style={{
          width: '1em',
          padding: 0,
          margin: 0,
          height: '1em',
          backgroundColor: 'transparent'
        }}
        title={country.code}
      />

    </InputLeftAddon>
    <Select
      value={JSON.stringify(country)}
      bg={'transparent'}
      fontSize={"xs"}
      border={0}
      p={0}
      color={color}
      _placeholder={{
        color: color,
      }}
      _focus={{
        border: 0,
        outline: 0
      }}
      {
        ...register('country', {
          validate: false,
          onChange: (e) => {
            setCountry(JSON.parse(e.target.value))
          }
        })
      }
    
      >
      {
        COUNTRIES.map(country => {
          return <option key={country.code} value={JSON.stringify(country)}> { country.name }</option>
        })
      }
    </Select>
    </InputGroup>
  )
})

export default CountrySelect;