/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Heading,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ButtonPrimary } from '../components/buttons';
import Main from '../components/layouts/Main';
import {
  cognitoPartnerAtom,
  isAuthenticatedAtom,
  OTPPhoneNumberAtom,
  triedQuoteAtom,
} from '../recoil/atoms';
import { paths } from '../utils/constants';
import {
  loadCustomAuthSession,
  storeCustomAuthSession,
} from '../utils/functions';

export default function OTP() {
  const phoneNumber = useRecoilValue(OTPPhoneNumberAtom);
  const setAuthentication = useSetRecoilState(isAuthenticatedAtom);
  const [cognitoUser,setCognitoUser] = useRecoilState(cognitoPartnerAtom);
  const triedQuote = useRecoilValue(triedQuoteAtom);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const answerOTPChallenge = useMutation(async ({ user, otp }) => {
    await Auth.sendCustomChallengeAnswer(user, otp);
  });

  useEffect(() => {
    if (answerOTPChallenge.isSuccess) getCurrentAuthenticatedUser.mutate();
    else if (answerOTPChallenge.isError) {
      toast({
        status: 'error',
        title: 'Something went wrong',
        description: 'Check your OTP again and try again',
        duration: 2000,
        position: 'bottom',
        isClosable: true,
      });
    }
  }, [answerOTPChallenge.isSuccess, answerOTPChallenge.isError]);

  const getCurrentAuthenticatedUser = useMutation(async () => {
    // throws an error when user is not authenticated.
    await Auth.currentSession();
    return await Auth.currentAuthenticatedUser();
  });

  useEffect(() => {
    if (getCurrentAuthenticatedUser.isSuccess) {
      const { attributes } = getCurrentAuthenticatedUser.data;
      /*
      setUserDetails({
        email: attributes['custom:email'] ? attributes['custom:email'] : userDetails.email,
        familyName: attributes['custom:familyName'],
        firstName: attributes['custom:givenName'],
        id: attributes['sub'],
        phoneNumber: attributes['phone_number'],
      });
      */
      // TODO: UNCOMMENT CODE BELOW AND MAKE SURE IT WORKS
      /*
      const dbUser = await _getUser(currentUser.attributes.sub);
      if (dbUser) setUser({ ...currentUser, dbUser: Object.fromEntries(Object.entries(dbUser).filter(([_, v]) => v != null)) });
      */
      
      setCognitoUser(attributes);
      setAuthentication(true);
      navigate(paths.home);
      /*
      if(Boolean(triedQuote)){
        console.log("tried me",triedQuote)
        navigate(triedQuote)
        return
      } else {
        
      }
      */

    }
  }, [
    getCurrentAuthenticatedUser.isSuccess,
    getCurrentAuthenticatedUser.isError,
  ]);

  const resendOTPChallenge = useMutation(
    async () => await Auth.signIn(phoneNumber)
  );

  useEffect(() => {
    if (resendOTPChallenge.isSuccess) {
      storeCustomAuthSession(resendOTPChallenge.data);
      setCognitoUser(resendOTPChallenge.data);
      toast({
        status: 'info',
        title: 'OTP resent successfully',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    } else if (resendOTPChallenge.isError) {
      toast({
        status: 'error',
        title: 'Something went wrong',
        description: 'Could not sent OTP, please try again.',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [resendOTPChallenge.status]);

  async function onSubmit(values) {
    const otp = Object.values(values).reduce(
      (sum, current) => sum + current,
      ''
    );
    const user = await loadCustomAuthSession();
    answerOTPChallenge.mutate({ user, otp });
  }

  return (
    <Main
      spacing={5}
      minH={'100vh'}
      minW={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={useColorModeValue('gray.100', 'gray.800')}
    >
      <Text
        fontSize={'3xl'}
        fontWeight={'bold'}
        cursor={'pointer'}
        onClick={() => navigate(-1)}
      >
        {' '}
      </Text>

      <Image
        src={'/512.png'}
        width={'54px'}
        height={'54px'}
        m={'auto'}
        rounded={'full'}
        cursor={'pointer'}
        onClick={() => navigate(paths.home)}
      />

      <Heading textAlign={'center'}>One Time Password</Heading>

      <Box
        as={'form'}
        onSubmit={handleSubmit(onSubmit)}
        width={'full'}
        maxW={'lg'}
        bg={useColorModeValue('white', 'gray.800')}
        className={'bxs-uh'}
        px={4}
        py={6}
        spacing={3}
      >
        <FormControl
          isInvalid={Object.values(errors).length > 0}
          as={VStack}
          spacing={3}
          alignItems={'start'}
          justifyContent={'flex-start'}
        >
          <Text color={'gray.900'}>
            {' '}
            Enter OTP sent to <b> {phoneNumber} </b>{' '}
          </Text>
          <HStack>
            <PinInput
              otp
              id="otp"
              color={useColorModeValue('black', 'white')}
              _placeholder={{
                color: useColorModeValue('black', 'white'),
              }}
            >
              <PinInputField
                name="otp1"
                {...register('otp1', {
                  required: 'The OTP is required',
                })}
              />
              <PinInputField
                name="otp2"
                {...register('otp2', {
                  required: 'The OTP is required',
                })}
              />
              <PinInputField
                name="otp3"
                {...register('otp3', {
                  required: 'The OTP is required',
                })}
              />
              <PinInputField
                name="otp4"
                {...register('otp4', {
                  required: 'The OTP is required',
                })}
              />
              <PinInputField
                name="otp5"
                {...register('otp5', {
                  required: 'The OTP is required',
                })}
              />
              <PinInputField
                name="otp6"
                {...register('otp6', {
                  required: 'The OTP is required',
                })}
              />
            </PinInput>
          </HStack>
          {errors.phoneNumber && (
            <Text fontSize={'sm'} color={'red.400'}>
              {' '}
              {errors.phoneNumber.message}{' '}
            </Text>
          )}

          {resendOTPChallenge.isLoading ? (
            <Spinner size={'sm'} />
          ) : (
            <Text
              my={2}
              color={'blue'}
              _hover={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={resendOTPChallenge.mutate}
            >
              {' '}
              Resend OTP{' '}
            </Text>
          )}

          <ButtonPrimary
            type={'submit'}
            width={'full'}
            isLoading={
              answerOTPChallenge.isLoading ||
              getCurrentAuthenticatedUser.isLoading
            }
          >
            {' '}
            Next{' '}
          </ButtonPrimary>
        </FormControl>
      </Box>
    </Main>
  );
}
