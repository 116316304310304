import moment from 'moment';
import { atom } from 'recoil';
import { localPersist } from '../utils/functions';
import { localPersistEffect } from './atom-effects';

const dangerouslyAllowMutability =
  process.env.REACT_APP_STAGE === 'prod' ? false : true;

export const messageStateAtom = atom({
  key: 'messageState',
  default: [],
  effects_UNSTABLE: [localPersist],
  dangerouslyAllowMutability,
});

export const quoteUpdatedAtom = atom({
  key: 'quoteUpdatedAtom',
  default: false,
});

export const isAuthenticatedAtom = atom({
  key: 'isAuthenticated',
  default: false,
  effects_UNSTABLE: [localPersist],
});

export const requestDrawerIsOpenAtom = atom({
  key: 'request-drawer-is-open',
  default: false,
});

export const triedQuoteAtom = atom({
  key: 'tried-Quote-Atom',
  default: null,
});

export const autoFillOTPAtom = atom({
  key: 'AutoFillOTPAtom',
  default: '',
});

export const quoteDrawerIsOpenAtom = atom({
  key: 'quote-drawer-is-open',
  default: false,
});

export const OTPPhoneNumberAtom = atom({
  key: 'OTPPhoneNumber',
  default: null,
  effects_UNSTABLE: [localPersist],
  dangerouslyAllowMutability,
});

export const cognitoPartnerAtom = atom({
  key: 'cognitoPartner',
  default: null,
  effects_UNSTABLE: [localPersist],
  dangerouslyAllowMutability,
});

export const requestsSearchKeyAtom = atom({
  key: 'requests-search-key',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
});

export const requestsSearchFiltersAtom = atom({
  key: 'autoparts-filters',
  default: null,
});

export const requestsSearchCarMakeFilterAtom = atom({
  key: 'autoparts-search-filter-car-make',
  default: '',
});

export const requestsSearchCarModelFilterAtom = atom({
  key: 'autoparts-search-filter-car-model',
  default: '',
});

export const autoPartsSearchCategoriesAtom = atom({
  key: 'autoparts-search-categores',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const activeSubCategoryAtom = atom({
  key: 'active-sub-category',
  default: -1,
});

// export const cognitoPartnerIdAtom;

export const partnerContactDetailsAtom = atom({
  key: 'partnerContactDetails',
  default: {
    firstName: '',
    familyName: '',
    phoneNumber: '',
    businessName: '',
    address: '',
    email: '',
    id: '',
  },
  effects_UNSTABLE: [localPersist],
  dangerouslyAllowMutability,
});

export const requestAtom = atom({
  key: 'selected-request-101',
  default: null,
  effects_UNSTABLE: [],
});

export const quotedPriceAtom = atom({
  key: 'quoted-price-atom',
  default: 0,
});
export const serviceFeeInclusiveAtom = atom({
  key: 'service-fee-inclusive',
  default: true,
});
export const serviceFeeAtom = atom({
  key: 'service-fee',
  default: 0,
});
export const quotedAutoPartIsNew = atom({
  key: 'quoted-autopart-is-new',
  default: true,
});
export const quotePhotosAtom = atom({
  key: 'quote-photos',
  default: [],
});
export const quoteExpiryDateAtom = atom({
  key: 'quote-expiry-date',
  default: moment(new Date().valueOf() + 604800000).format('YYYY-MM-DD'),
});
export const quoteExpiresAtom = atom({
  key: 'quote-expires',
  default: false,
});

export const activeSettingsTabAtom = atom({
  key: 'active-settings-tab',
  default: 'personal',
  effects_UNSTABLE: [],
});

export const activePaymentsTabAtom = atom({
  key: 'active-payments-tab',
  default: 'bank',
  effects_UNSTABLE: [],
});

export const authenticatedHomeQuotesAtom = atom({
  key: 'authenticated-home-quotes',
  default: null,
  effects_UNSTABLE: [],
});

export const authenticatedHomeRequestsAtom = atom({
  key: 'authenticated-home-requests',
  default: null,
  effects_UNSTABLE: [],
});

export const quotesSearchKeyAtom = atom({
  key: 'quotes-search-key',
  default: '',
  effects_UNSTABLE: [],
});

export const searchKeyAtom = atom({
  key: 'search-key',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
});

export const modalCarouselStateAtom = atom({
  key: 'modal-carousel-state',
  default: false,
});

export const modalCarouselChildrenAtom = atom({
  key: 'image-modal-carousel-children',
  default: null,
});

export const conversationsAtom = atom({
  key: 'conversationsAtom',
  default: [],
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedConversationAtom = atom({
  key: 'selectedConversation',
  default: null,
});

export const postRenderMessagesAtom = atom({
  key: 'post-render-messages',
  default: [],
});

export const lastMessageAtom = atom({
  key: 'last-message',
  default: null,
});
export const newMessageIdAtom = atom({
  key: 'new-message-id',
  default: null,
});

export const requestIdAtom = atom({
  key: 'request-id',
  default: null,
  effects_UNSTABLE: [],
});

export const quoteIdAtom = atom({
  key: 'order-id',
  default: null,
  effects_UNSTABLE: [],
});

export const quoteAtom = atom({
  key: 'quote-1',
  default: null,
  effects_UNSTABLE: [],
});

export const nextFilteredRequestsToken = atom({
  key: 'requests-token',
  default: null,
  effects_UNSTABLE: [({ onSet, setSelf }) => {}],
});

export const nextQuotesToken = atom({
  key: 'quotes-token',
  default: null,
  effects_UNSTABLE: [({ onSet, setSelf }) => {}],
});

export const uploadedPhotosAtom = atom({
  key: 'uploadedPhotos',
  default: [],
});

export const creditCardsAtom = atom({
  key: 'credit-cards',
  default: null,
  effects_UNSTABLE: [],
});

export const mobileMoneyAccountsAtom = atom({
  key: 'mobile-money-accounts',
  default: null,
  effects_UNSTABLE: [],
});

export const requestsNextTokenAtom = atom({
  key: 'requests-next-token',
  default: undefined,
});

export const requestsDateRangeAtom = atom({
  key: 'requests-date-range',
  default: {
    start: null,
    end: null,
  },
});

export const requestsAtom = atom({
  key: 'requests-page-atom',
  default: [],
  effects_UNSTABLE: [],
});

export const requestsTokenAtom = atom({
  key: 'requests-page-token-1',
  default: null,
  effects_UNSTABLE: [],
});

export const requestsHasMoreAtom = atom({
  key: 'requests-page-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const requestsFilterAtom = atom({
  key: 'requests-filter',
  default: 'all',
  effects_UNSTABLE: [],
});

// search page atoms

export const searchNextRequestsToken = atom({
  key: 'search-request-next-token',
  default: null,
  effects_UNSTABLE: [],
});

export const searchRequestsAtom = atom({
  key: 'search-requests',
  default: [],
  effects_UNSTABLE: [],
});

export const searchRequestsToken = atom({
  key: 'search-requests-token',
  default: null,
  effects_UNSTABLE: [],
});

export const searchRequestsHasMoreAtom = atom({
  key: 'search-requests-has-more',
  default: false,
  effects_UNSTABLE: [],
});

// home page atoms

export const homeRequestsAtom = atom({
  key: 'home-requests-atom',
  default: [],
  effects_UNSTABLE: [],
});

export const homeRequestsTokenAtom = atom({
  key: 'home-requests-token-1',
  default: null,
  effects_UNSTABLE: [],
});
export const homeRequestsHasMoreAtom = atom({
  key: 'home-requests-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const homeQuotesAtom = atom({
  key: 'home-quotes-atom',
  default: [],
  effects_UNSTABLE: [],
});

export const homeQuotesTokenAtom = atom({
  key: 'home-quotes-token-1',
  default: null,
  effects_UNSTABLE: [],
});
export const homeQuotesHasMoreAtom = atom({
  key: 'home-quotes-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const requestsPageAtom = atom({
  key: 'requests-page',
  default: [],
  effects_UNSTABLE: [],
});

export const requestsPageDateRangeAtom = atom({
  key: 'requests-page-date-range',
  default: {
    start: null,
    end: null,
  },
  effects_UNSTABLE: [],
});

export const requestsPageNextToken = atom({
  key: 'requests-page-next-token',
  default: null,
  effects_UNSTABLE: [],
});

export const quotesNextTokenAtom = atom({
  key: 'quotes-next-token',
  default: null,
  effects_UNSTABLE: [],
});

export const quotesDateRangeAtom = atom({
  key: 'quotes-date-range',
  default: {
    start: null,
    end: null,
  },
});

export const quotesFilterAtom = atom({
  key: 'quotes-filter',
  default: 'all',
  effects_UNSTABLE: [],
});

export const quotesAtom = atom({
  key: 'quotes-atom',
  default: [],
  effects_UNSTABLE: [],
});

export const quotesHasMoreAtom = atom({
  key: 'quotes-has-more',
  default: false,
  effects_UNSTABLE: [],
});

export const ordersNextTokenAtom = atom({
  key: 'orders-next-token',
  default: null,
});

export const ordersDateRangeAtom = atom({
  key: 'orders-date-range',
  default: {
    start: null,
    end: null,
  },
});

export const ordersFilterAtom = atom({
  key: 'orders-filter',
  default: 'all',
  effects_UNSTABLE: [],
});

export const ordersAtom = atom({
  key: 'orders-atom',
  default: [],
  effects_UNSTABLE: [],
  dangerouslyAllowMutability,
});

export const ordersHasMoreAtom = atom({
  key: 'orders-has-more-4',
  default: false,
  effects_UNSTABLE: [],
});

export const orderAtom = atom({
  key: 'customer-order',
  default: false,
});
