import {
  Box, Flex,
  IconButton, Input, ScaleFade, SimpleGrid, Spacer, Stack, Text, useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdAddAPhoto, MdClose } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { compressAndUploadPhotos} from '../../api/mutations';
import { cognitoPartnerAtom } from '../../recoil/atoms';
import { getPinpointButtonClickEventProp } from '../../utils/functions';
import { ButtonPrimary } from '../buttons';


export default function PhotoUpload({
  description = "",
  callback = () => {
    alert('default')
  },
  max
}) {
  
  const user = useRecoilValue(cognitoPartnerAtom);
  const [previewFiles, setPreviewFiles] = useState([]);
  const {pathname} = useLocation();

  const {
    handleSubmit,
    register
  } = useForm();
  
  const toast = useToast();

  const uploadPhoto = useMutation(compressAndUploadPhotos);

  useEffect(() => {
    if(uploadPhoto.isSuccess) {
      callback(uploadPhoto.data);
    } else if(uploadPhoto.isError) {
      toast({
        status: "error",
        title: "Failed to upload your image.",
        description: "Something went wrong, please try again.",
        duration: 3000,
        position: "bottom"
      });
    }
  }, [uploadPhoto.status]);

  const uploadBoxBG = useColorModeValue("gray.100","blue.100");

  return (
    <Stack
      as={"form"}
      onSubmit={
        handleSubmit(({ files }) => {
          uploadPhoto.mutate({ user, files });
        })
      }
      method={"POST"}
      encType={"multipart/form-data"}
    >
      <label htmlFor={"fileInput"}>
        <VStack
          alignItems={"center"}
          py={20}
          borderRadius={"12px"}
          bg={uploadBoxBG}
          cursor={"pointer"}
          backgroundImage={previewFiles[0]}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          backgroundRepeat={"no-repeat"}
          opacity={uploadPhoto.isLoading ? "0.5" : "1"}
        >
          <Flex
            w={10}
            h={10}
            rounded={"full"}
            bg={"brand.400"}
            align={"center"}
            justify={"center"}
          > <MdAddAPhoto size={"18px"} /> </Flex>
        </VStack>
      </label>
      <Input
        id={"fileInput"}
        type={"file"}
        multiple={max > 1}
        name={"files"}
        w={"0px"}
        height={"0px"}
        border={"none"}
        disabled={uploadPhoto.isLoading}
        {
          ...register("files")
        }
        onChange={
          e => {
            const files = Array.from(e.target.files).map(file => URL.createObjectURL(file));
            setPreviewFiles(max > 1 ? oldFiles => oldFiles.concat(files) : files);
          }
        }
      /> 
      { max > 1 && previewFiles.length && (
        <Text
          fontSize={"md"}
          fontWeight={"medium"}
        > Selected photos </Text>
      )}
      <SimpleGrid
        columns={{
          base: 3,
          md: 5
        }}
        gap={3}
      >
        {
          max > 1 && previewFiles.map(url => {
            return (
              <Box
                key={url}
                as={ScaleFade}
                in={true}
                backgroundImage={url}
                backgroundPosition={"center"}
                backgroundSize={"cover"}
                backgroundRepeat={"no-repeat"}
                width={"full"}
                height={"84px"}
                borderRadius={"4px"}
                onLoad={
                  () => {
                    // free up memory
                    URL.revokeObjectURL(url);
                  }
                }
              >
                <Flex width={"full"} p={1}>
                  <Spacer />
                  <IconButton
                    size={"xs"}
                    rounded={"full"}
                    icon={<MdClose size={"12px"} />}
                    colorScheme={"gray"}
                    onClick={
                      () => setPreviewFiles(files => files.filter(file => file !== url))
                    }
                    {...getPinpointButtonClickEventProp({
                      command: 'upload selected photos by user',
                      currentPage: pathname,
                    })}
                  />
                </Flex>
              </Box>
            )
          })
        }
      </SimpleGrid>

      {
        previewFiles.length && (
          <ButtonPrimary
            type={"submit"}
            size={"sm"}
            isLoading={uploadPhoto.isLoading}
          > Upload </ButtonPrimary>
        )
      }
      
    </Stack>
  )
}
