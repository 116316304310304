
import API from '@aws-amplify/api';
import
    {
        Alert,
        AlertIcon,
        Box,
        Button,
        Flex,
        FormControl,
        FormErrorMessage,
        Icon,
        Input,
        InputGroup,
        InputLeftAddon,
        InputRightAddon,
        Select,
        useColorModeValue,
        useToast,
        VStack
    } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { updatePartner } from '../../graphql/mutations';
import { cognitoPartnerAtom } from '../../recoil/atoms';
import { authModes } from '../../utils/constants';
import { isAirtelNumber, isMTNNumber } from '../../utils/functions';

export default function MobileMoney({ mobileMoneyDetail }) {
  console.log(
    '🚀 ~ file: mobile-money.js ~ line 34 ~ MobileMoney ~ mobileMoneyDetail',
    mobileMoneyDetail
  );
  const toast = useToast();

  const [user, setUser] = useRecoilState(cognitoPartnerAtom);
  console.log(
    '🚀 ~ file: mobile-money.js ~ line 38 ~ MobileMoney ~ user',
    user
  );

  const [name, setName] = useState(
    mobileMoneyDetail ? mobileMoneyDetail.name : ''
  );
  const [accountProvider, setAccountProvider] = useState(
    mobileMoneyDetail
      ? mobileMoneyDetail.processor
      : isAirtelNumber('260' + user.phoneNumber.slice(-9))
      ? 'AIRTEL'
      : isMTNNumber('260' + user.phoneNumber.slice(-9)) ? "MTN": "AIRTEL"
  );
  const [phoneNumber, setPhoneNumber] = useState(
    mobileMoneyDetail ? mobileMoneyDetail.number : user.phoneNumber.slice(-9)
  );
  const [errors, setErrors] = useState({
    name: { isError: false, message: '' },
    accountProvider: { isError: false, message: '' },
    phoneNumber: { isError: false, message: '' },
  });

  const updateMobileMoneyDetails = async () => {
    try {
      const { data } = await API.graphql({
        query: updatePartner,
        variables: {
          input: {
            mobileMoneyDetails: [
              { number: phoneNumber, name, processor: accountProvider },
            ],
          },
        },
        authMode: authModes.AMAZON_COGNITO_USER_POOLS,
      });
      console.log(
        '🚀 ~ file: mobile-money.js ~ line 57 ~ updateMobileMoneyDetails ~ data',
        data
      );

      if (data.updatePartner.code) {
        throw new Error(data.updatePartner.message);
      }

      setUser(data.updatePartner);

      toast({
        status: 'success',
        title: 'Updated Successifully',
        description: '',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: mobile-money.js ~ line 71 ~ updateMobileMoneyDetails ~ error',
        error
      );
      toast({
        status: 'error',
        title: 'Could not update',
        description: error.message,
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  useEffect(() => {
    const errorObj = errors;

    //* Validate Name
    let isNameValid = true;

    if (!name) {
      errorObj.name.isError = true;
      errorObj.name.message = 'Full name required';
      isNameValid = false;
    }

    if (name && name.split(' ').length < 2) {
      errorObj.name.isError = true;
      errorObj.name.message = 'Full name required';
      isNameValid = false;
    }

    if (isNameValid) {
      errorObj.name.isError = false;
      errorObj.name.message = '';
    }

    //* Validate Phone Number

    let isPhoneNumberValid = true;

    if (!phoneNumber) {
      errorObj.phoneNumber.isError = true;
      errorObj.phoneNumber.message = 'Enter your Mobile Money Number';
      isPhoneNumberValid = false;
    }

    if (!['96', '76', '97', '77'].includes(phoneNumber.slice(0, 2))) {
      errorObj.phoneNumber.isError = true;
      errorObj.phoneNumber.message = 'Only MTN Momo or Airtel Money Allowed';
      isPhoneNumberValid = false;
    }

    if (accountProvider === 'AIRTEL' && !isAirtelNumber('260' + phoneNumber)) {
      console.log('airtel');
      errorObj.phoneNumber.isError = true;
      errorObj.phoneNumber.message = 'Please enter a valid Airtel Money number';
      isPhoneNumberValid = false;
    }

    if (accountProvider === 'MTN' && !isMTNNumber('260' + phoneNumber)) {
      console.log('mtn');
      errorObj.phoneNumber.isError = true;
      errorObj.phoneNumber.message =
        'Please enter a valid MTN Money (MoMo) number';
      isPhoneNumberValid = false;
    }

    if (isPhoneNumberValid) {
      errorObj.phoneNumber.isError = false;
      errorObj.phoneNumber.message = '';
    }

    setErrors(errorObj);
  }, [name, phoneNumber, accountProvider]);

  return (
    <Box minW={'full'} p={3}>
      <VStack width={'full'} as={Flex} align={'flex-start'}>
        <Alert status="warning">
          <AlertIcon />
          Riaparts is not responsible if the wrong {accountProvider} Money
          Number is provided below. Ensure the correct details are given
        </Alert>
        <FormControl isInvalid={errors.accountProvider.isError}>
          <Select
            backgroundColor={useColorModeValue('white', 'gray.900')}
            _placeholder={{
              color: useColorModeValue('gray.700', 'gray.400'),
            }}
            id="mobileMoneyProvider"
            value={accountProvider}
            onChange={e => {
              setAccountProvider(e.target.value);
            }}
          >
            <option value={'AIRTEL'}> AIRTEL MONEY</option>
            <option value={'MTN'}> MTN </option>
          </Select>
          {errors.accountProvider.isError && (
            <FormErrorMessage>
              {errors.accountProvider.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={errors.name.isError}>
          <Input
            backgroundColor={useColorModeValue('white', 'gray.900')}
            _placeholder={{
              color: useColorModeValue('gray.700', 'gray.400'),
            }}
            id="name"
            type={'text'}
            placeholder="Full Name"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          {errors.name.isError && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={errors.phoneNumber.isError}>
          <Box gap={'2'}>
            <InputGroup>
              <InputLeftAddon p={'1'} fontSize={'xs'}>
                +260
              </InputLeftAddon>
              <Input
                fontSize={'lg'}
                placeholder="97/6 000000"
                variant="unstyled"
                value={phoneNumber}
                fontWeight={'semibold'}
                data-input="phoneNumber"
                pl={'5'}
                bg={'gray.50'}
                maxLength={9}
                onChange={e => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <InputRightAddon>
                <Icon w={4} h={4} as={AiFillEdit} />
              </InputRightAddon>
            </InputGroup>
          </Box>

          {errors.phoneNumber.isError && (
            <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
          )}
        </FormControl>

        <Button
          onClick={updateMobileMoneyDetails}
          width={'full'}
          my={5}
          backgroundColor={'blue.500'}
          color={'white'}
          _hover={{
            bg: 'blue.500',
          }}
        >
          {' '}
          Submit{' '}
        </Button>
      </VStack>
    </Box>
  );
}