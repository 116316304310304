import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { uniq } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { updateQuote } from '../../../api/mutations';
import { getQuote } from '../../../api/queries';
import {
  cognitoPartnerAtom,
  isAuthenticatedAtom,
  quoteAtom,
  quotedAutoPartIsNew,
  quotedPriceAtom,
  quoteDrawerIsOpenAtom,
  quoteExpiresAtom,
  quoteExpiryDateAtom,
  quotePhotosAtom,
  quoteUpdatedAtom,
  serviceFeeAtom,
  serviceFeeInclusiveAtom,
} from '../../../recoil/atoms';
import { paths } from '../../../utils/constants';
import { getPinpointButtonClickEventProp } from '../../../utils/functions';
import { ButtonPrimary } from '../../buttons';
import Content from './content';
import Loading from './loading';

export default function QuoteDrawer() {
  /* states */
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const user = useRecoilValue(cognitoPartnerAtom);
  const [, setQuoteUpdated] = useRecoilState(quoteUpdatedAtom);
  const [quote, setQuote] = useRecoilState(quoteAtom);
  const [isOpen, setIsOpen] = useRecoilState(quoteDrawerIsOpenAtom);
  const [price, setPrice] = useRecoilState(quotedPriceAtom);
  const [serviceFee] = useRecoilState(serviceFeeAtom);
  const [serviceFeeInclusive, setServiceFeeInclusive] = useRecoilState(
    serviceFeeInclusiveAtom
  );
  const [isNew, setIsNew] = useRecoilState(quotedAutoPartIsNew);
  const [photos, setPhotos] = useRecoilState(quotePhotosAtom);
  const [expiryDate] = useRecoilState(quoteExpiryDateAtom);
  const [expires] = useRecoilState(quoteExpiresAtom);

  /* useful hooks and refs */
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const saveChangesButtonRef = useRef();

  
  /* getQuote logic */
  const [quoteId, setQuoteId] = useState(null);
  const { isLoading, isError } = useQuery(
    ['quote', { id: quoteId }],
    getQuote,
    {
      enabled: !!quoteId,
      retryDelay: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: data => setQuote(data),
      onError: error => console.clear() || console.log(error),
    }
  );
  // trigger getQuoteQuery if the quoteAtom is not set or the quote.quote.id !== quoteId, which we get from the hash in the URI.
  useEffect(() => {
    if (!isOpen) return;
    let quoteId = location.hash.split('-')[1];
    quoteId = quoteId && quoteId.replace('#', '');
    if (!quote || (quoteId && quote.quote.id !== quoteId)) setQuoteId(quoteId);
  }, [location.hash, isOpen]);

  // edit quote logic
  const updateQuoteMutation = useMutation(updateQuote, {
    onSuccess: () => {
      setQuoteUpdated(true);
      toast({
        status: 'info',
        title: 'You quotation was updated successfully',
        description: 'We will let you know when the customer makes an order.',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });
      setPhotos([]);
      setPrice(0);
      setIsNew(true);
      setServiceFeeInclusive(true);
      setIsOpen(false);
      navigate(paths.quotes);
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Something went wrong',
        description: 'Could not update your quotation, please try again.',
        duration: 2000,
        position: 'bottom',
        isClosable: true,
      });
    },
  });

  function saveChangesInitiator() {
    if (!isAuthenticated) {
      return toast({
        status: 'info',
        title: 'Sign in',
        description: 'You need to be signed in for you to submit a quote.',
        position: 'bottom',
        isClosable: true,
        duration: 3000,
      });
    } else if (price < 1) {
      return toast({
        status: 'info',
        title: 'Enter a valid price',
        description: `The price cannot be ZERO`,
        position: 'bottom',
        isClosable: true,
        duration: 3000,
      });
    } /* else if (!photos.length) {
      return toast({
        status: 'info',
        title: 'Supply some photos of the autopart',
        description:
          'Photos act as proof of existence and capture the users attention.',
        position: 'bottom',
        isClosable: true,
        duration: 2500,
      });
    } */ else
      updateQuoteMutation
        .mutateAsync({
          user,
          quote,
          photos: photos && photos.length > 0 ? uniq(photos.map(photo => photo.id)) : [],
          price,
          isNew,
          serviceFeeInclusive,
          serviceFee,
          expiryDate,
          expires,
        })
        .then(res => {
          console.log('update response', res);
        });
  }

  const close = () => {
    setQuote(null);
    setQuoteId(null);
    setIsOpen(false);
    navigate(location.pathname);
  };

  const placement = useBreakpointValue({
    base: 'bottom',
    md: 'right',
  });

  const size = useBreakpointValue({
    base: '',
    md: 'sm',
  });

  return (
    <Drawer
      size={size}
      placement={placement}
      isOpen={isOpen}
      onClose={close}
      initialFocusRef={saveChangesButtonRef}
    >
      <DrawerOverlay />

      <DrawerContent
        borderTopRadius={{
          base: '20px',
          md: '0',
        }}
        p={0}
        maxH={{
          base: '94vh',
          md: '100vh',
        }}
      >
        <DrawerCloseButton />
        <DrawerBody as={VStack} spacing={4} py={6} width={'full'} px={4} mt={3}>
          {isLoading || !quote ? <Loading /> : <Content quote={quote} />}
        </DrawerBody>
        <DrawerFooter width={'full'} padding={4}>
          {quote && quote.quote && quote.quote.status === 'OPEN' && (
            <ButtonPrimary
              width={'full'}
              ref={saveChangesButtonRef}
              disabled={isLoading || isError || quote === null}
              isLoading={isLoading || updateQuoteMutation.isLoading}
              onClick={saveChangesInitiator}
              {...getPinpointButtonClickEventProp({
                command: 'save quote changes after editing the quote',
                currentPage: location.pathname,
              })}
            >
              {' '}
              Save Changes{' '}
            </ButtonPrimary>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
