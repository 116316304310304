import {
  Avatar, Flex,
  HStack,
  ScaleFade,
  Spacer,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
  useToast,
  Box
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { sendMessage, updateQuote } from "../../../../../api/mutations";
import { cognitoPartnerAtom, selectedConversationAtom } from "../../../../../recoil/atoms";
import { formatPrice, getCustomerFromConversationMembers } from "../../../../../utils/functions";



export default forwardRef(function ({ message, isLastMessageBySenderInSubMessageList, ...rest }, ref) {

  const user = useRecoilValue(cognitoPartnerAtom);
  const conversation = useRecoilValue(selectedConversationAtom);
  const customer = getCustomerFromConversationMembers(conversation.conversation.members);
  const { data, quote } = message;
  const quantity = data && data.quote && data.quote.request && data.quote.request.autoPart.quantity;

  const toast = useToast();

  const updateQuoteMutation = useMutation(updateQuote);
  const sendMessageMutation = useMutation(sendMessage)

  async function acceptProposal() {
    try {
      const quote = await updateQuoteMutation.mutateAsync({
        quote: data.quote,
        price: data.proposedAmount
      });
      sendMessageMutation.mutate({
        user, conversation,
        body: JSON.stringify({
          type: "proposal-acceptance",
          quote, acceptedAmount: data.proposedAmount
        })
      });
    } catch (error) {
      console.log(error);
      showToast();
    }
  }

  async function declineProposal() {
    try {
      await sendMessageMutation.mutateAsync({
        user, conversation,
        body: JSON.stringify({
          type: "proposal-decline",
          quote: data.quote, declinedAmount: data.proposedAmount
        })
      });
    } catch (error) {
      console.log(error);
      showToast();
    }
  }

  function showToast() {
    toast({
      status: "error",
      title: "Something went wrong",
      description: "Please try again.",
      duration: 2400,
      position: "bottom"
    });
  }

  const bg = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.100", "brand-blue.900");

  return (
    <Flex
      width={"full"}
      pr={10}
      ref={ref}
      {...rest}
    >
      <Flex
        minH={"full"}
        width={"13px"}
        align={"end"}
      >

        {isLastMessageBySenderInSubMessageList && (
          <Flex
            align={"center"}
            justify={"center"}
            p={1}
            transform={"translate(0px,12px)"}
            rounded={"full"}
            bg={bg}
            borderWidth={"1px"}
            borderColor={borderColor}
          >
            <Avatar
              width={"20px"}
              height={"20px"}
              src={customer.imageS3Name}
            />
          </Flex>
        )}

      </Flex>

      <VStack
        as={ScaleFade}
        in={true}
        maxW={{ base: "90%", md: "75%" }}
        borderTopRadius={"12px"}
        borderBottomRightRadius={"24px"}
        borderTopLeftRadius={"24px"}
        borderBottomLeftRadius={"0px"}
        bg={bg}
        borderWidth={"1px"}
        borderColor={borderColor}
        p={4}
      >
        <Text
          fontSize={"xs"}
          color={useColorModeValue("gray.700", "gray.300")}
        >
          This customer proposed {" "}
          <Text
            as={"b"}
            color={useColorModeValue("gray.700", "gray.300")}
          >{formatPrice(data.proposedAmount)}</Text>
          {" "} {quantity > 1 ? ('for each') : ('for this')} spare part.<br />
          {quantity > 1 && (
            <Text
              as={"span"}
            > Amounting To: <Text as={"span"} fontWeight={"black"}>{formatPrice(data.proposedAmount * quantity)}</Text> </Text>
          )}
        </Text>
        <HStack
          width={"full"}
        justifyContent={'center'}
        >
          {
            sendMessageMutation.isLoading || updateQuoteMutation.isLoading ? <Spinner colorScheme={'blue'} size={"xs"} /> : (
                <>
                    <Box
            as={"button"}
            color={"red"}
            fontSize={"xs"}
            fontWeight={"semibold"}
            onClick={declineProposal}
          >  decline </Box>
          <Spacer />
          <Box
            as={"button"}
            color={'blue.300'}
            fontSize={"xs"}
            fontWeight={"semibold"}
            onClick={acceptProposal}
          > accept </Box>
                </>
            )
          }
        </HStack>
      </VStack>

      <Spacer />
    </Flex>
  )
})