import {
  Collapse,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import { Fragment, startTransition, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdSearch } from "react-icons/io";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useRecoilState } from "recoil";
import { searchRequests } from "../../../api/queries";
import { ButtonPrimary } from "../../../components/buttons";
import Request from "../../../components/requests";
// import AutoPart from "../../../components/cards/autopart";
// import AutoPartDrawer from "../../../components/drawers/autopart";
import ErrorAlert from "../../../components/error/alert";
import Skeletons from "../../../components/loaders/skeletons";
import {
  requestsSearchFiltersAtom,
  requestsSearchKeyAtom
} from '../../../recoil/atoms';
import { useLocation } from "react-router-dom";
import { getPinpointButtonClickEventProp } from "../../../utils/functions";

export default function Search() {

  const [key, setKey] = useRecoilState(requestsSearchKeyAtom);
  const [inputValue, setInputValue] = useState("");
  const [filters, setFilters] = useRecoilState(requestsSearchFiltersAtom);
  const { pathname } = useLocation();
  const limit = 10;

  const {
    handleSubmit,
    register
  } = useForm();

  const {
    ref,
    inView
  } = useInView()

  const {
    isLoading,
    isError,
    fetchNextPage,
    isSuccess,
    data,
    refetch,
  } = useInfiniteQuery(["requests", { key, filters, limit }], searchRequests, {
    getNextPageParam: previousPage => {
      if(!previousPage) return;
      let fetched = previousPage.page * limit;
      if(fetched < previousPage.found) return previousPage.page + 1;
    },
    retryDelay: 0,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // fetch more autoparts whenever the last autopart comes into view;
    inView && fetchNextPage();
  },[inView]);

  const hasData = !!data;

  const searchResultsTextColor = useColorModeValue("gray.800","gray.200");
  const noResultsBG = useColorModeValue("gray.100","gray.700");
  const noResultsColor = useColorModeValue("gray.700","gray.200")

  // filters modal disclosure, passed as a ref from the SearchFilters component.
  const filtersDisclosure = useRef();

  useEffect(() => {
    key && setInputValue(key);
  },[]);

  const onChange = (e) => {
    setInputValue(e.target.value);
    startTransition(() => {
      setKey(e.target.value);
    });
  }

  return (
    <>
    <Stack
      spacing={4}
      width={"full"}
    >

      <Flex
        as={"form"}
        width={"full"}
        // onSubmit={handleSubmit(submit)}
      >
        <InputGroup
          width={"full"}
          bg={"none"}
          as={HStack}
          spacing={3}
        >
          
          { /* ref is open menu function */ }

          <Input
            bg={useColorModeValue('white','gray.700')}
            borderColor={useColorModeValue("gray.200","gray.800")}
            placeholder={"Search for customer requests"}
            fontSize={{ base: "xs", md: "md" }}
            value={inputValue}
            onChange={onChange}
          />
          <ButtonPrimary
            borderRadius={"8px"}
            alignItems={"center"}
            py={2}
            px={{ base: 2, md: 4 }}
            m={0}
            fontSize={"sm"}
            {...getPinpointButtonClickEventProp({
              command: 'search for a request',
              currentPage: pathname,
            })}  
          >
            { useBreakpointValue({ base: "", md: "search "}) }
            <Icon
              as={IoMdSearch}
              size={"16px"}
              ml={{ base: 0, md: 2 }}
            />
          </ButtonPrimary>
        </InputGroup>
      </Flex>

      {
        key && (hasData && data.pages.length) && !filters && (!filtersDisclosure.current || !filtersDisclosure.current.isOpen) && (
          <Text
            fontSize={{
              base: "xs",
              md: "sm"
            }}
            color={"gray.500"}
          >
            <Text
              as={"span"}
              fontWeight={"medium"}
              cursor={"pointer"}
              color={"blue"}
              textDecor={"underline"}
              onClick={filtersDisclosure.current?.onOpen}
              {...getPinpointButtonClickEventProp({
                command: 'open filters drop down menu on search request',
                currentPage: pathname,
              })}
            >Apply filters</Text>
            {" "} to your search to find autoparts for your exact car make and model.
          </Text>
        )
      }

        <Collapse
          in={isLoading || isSuccess || (hasData && data.pages.length) || isError }
        >
          <VStack
            spacing={4}
          >
            {
              !!key && (
                <HStack
                  width={"full"}
                >
                  <Text
                    fontWeight={"medium"}
                    color={searchResultsTextColor}
                  > Search Results </Text>
                  <Spacer />
                  { isLoading ? <Spinner size={"xs"} color={"gray.700"} /> : isSuccess ? (
                    <Text
                      color={searchResultsTextColor}
                      fontSize={"xs"}
                    > found <b>{ hasData && data.pages[data.pages.length-1].found }</b> results </Text>
                  ) : (
                    "error"
                  )}
                </HStack>
              )
            }

            <SimpleGrid
              columns={{
                base: 1,
                md: 3,
                lg: 4,
                xl: 5
              }}
              p={"1px"}
              spacing={4}
              maxH={{
                base: "400px",
                md: "700px"
              }}
              width={"full"}
              overflowY={"scroll"}
              className={"no-scroll"}
              pb={"45px"}
            >
              {
                !isLoading && hasData && data.pages < 1 && (
                  <VStack
                    width={"full"}
                    height={"full"}
                    bg={noResultsBG}
                    color={noResultsColor}
                    borderRadius={"8px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text> No results found for your search </Text>
                  </VStack>
                )
              }

              {
               !isLoading && isSuccess && hasData && data && data.pages && data.pages.map((page,pageIndex) => {
                  if(!page) return "";
                  return (
                    <Fragment key={pageIndex}>
                      {
                        page.hits.map((request,requestsIndex) => {
                          //console.log(request)
                          if(request.status === "CLOSED") return;

                         return (
                          <Request
                          request={request}
                          key={request.id}
                          ref={pageIndex === data.pages.length-1 && requestsIndex === page.hits.length-3 ? ref : undefined}
                        />
                         )
                      })
                      }
                    </Fragment>
                  );
                })
              }

              {
                isError && (
                  <ErrorAlert again={refetch} />
                )
              }
                

                {
                  isLoading && (
                    <Skeletons
                      count={limit}
                      minH={{
                        base: "78px",
                        md: "212px"
                      }}
                      borderRadius={"8px"}
                    />
                  )
                }

            </SimpleGrid>
          </VStack>
        </Collapse>
    </Stack>

    { /* autoparts drawer that shows autopart details when an autopart is clicked.*/ }

    </>
  )
}


/*


 <Stack
          spacing={2}
          p={4}
          borderRadius={"sm"}
          boxShadow={"xs"}
        >
          <HStack
            width={"full"}
            justifyContent={"space-between"}
          >
            <Text
              fontWeight={"semibold"}
              color={"gray.900"}
            > Filters </Text>
            <Button
              size={"sm"}
              fontSize={"xl"}
              colorScheme={"whiteAlpha"}
              color={"gray.600"}
              onClick={filterDisclosure.onClose}
            > &times; </Button>
          </HStack>
          <Divider />
          <DropDown
            label="Car Make, Model &amp; Year"
            opened={true}
          >
            <HStack
              borderRadius={"sm"}
              
            >
              <Input
                width={"full"}
                bg={"gray.50"}
                size={"sm"}
                fontSize={"xs"}
                placeholder={"Make"}
              />
              <Input
                width={"full"}
                bg={"gray.50"}
                size={"sm"}
                fontSize={"xs"}
                placeholder={"Model"}
              />
              <Input
                width={"full"}
                bg={"gray.50"}
                size={"sm"}
                fontSize={"xs"}
                placeholder={"Year"}
              />
            </HStack>
          </DropDown>

          <DropDown
            label={"Categories"}
          >
            <FormControl>
              {
                categories.map(category => (
                  <HStack
                    spacing={4}>
                    <Checkbox size={"sm"}/>
                    <Text
                      fontSize={"sm"}
                    > { category.name } </Text>
                  </HStack>
                ))
              }
            </FormControl>
          </DropDown>

          <ButtonPrimary
            size={"sm"}
            
          > Apply Filters </ButtonPrimary>
        </Stack>

*/


/*





*/