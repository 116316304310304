/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, } from 'react';
import {
  Text,
  useColorModeValue,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  SimpleGrid,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { cognitoPartnerAtom } from '../recoil/atoms';
import Order from "../components/cards/order/index";
import Main from '../components/layouts/Main';
import Skeletons from '../components/loaders/skeletons';
import { useInfiniteQuery } from 'react-query';
import { searchOrders } from '../api/queries';
import { Fragment } from 'react';
import { useInView } from "react-intersection-observer";
import { useLocation } from 'react-router-dom';
import { getPinpointButtonClickEventProp } from '../utils/functions';



const limit = 10;

export default function Orders() {

  const user = useRecoilValue(cognitoPartnerAtom);
  const [status, setStatus] = useState("all");
  const {pathname} = useLocation();

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["orders", { status, user, limit }], searchOrders, {
    getNextPageParam: previousPage => previousPage ? previousPage.nextToken : null,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const {
    ref,
    inView
  } = useInView();

  useEffect(() => {
    // fetch more orders whenever the last autopart comes into view;
    inView && hasNextPage && fetchNextPage();
  }, [inView, hasNextPage]);

  const noOrderBG = useColorModeValue("gray.100", "gray.700");
  const noOrdersColor = useColorModeValue("gray.600", "gray.300");

  const tabProps = {
    py: 1,
    px: 2,
    borderRadius: "16px",
    _focus: {
      border: "none"
    },
    color: useColorModeValue("gray.600", "gray.200"),
    bg: useColorModeValue("white", "gray.700"),
    cursor: "pointer",
    _selected: {
      bg: "brand.400",
      color: "gray.800",
      fontWeight: "bold",
    },
    fontWeight: "normal",
    fontSize: "10px"
  };

  return (

    <Main>

      <Text
        fontSize={"xl"}
        fontWeight={"bold"}
        color={useColorModeValue("gray.900", "gray.50")}
      > Customer Orders </Text>

      <Tabs
        variant={"unstyled"}
        size={"sm"}
        width={"full"}
        overflowX={"scroll"}
        className={"no-scroll"}
      >
        <TabList
          as={HStack}
          overflow={"scroll"}
          width={"full"}
          p={"1px"}
          className={"no-scroll"}
        >
          <Tab
            {...tabProps}
            onClick={
              () => setStatus("all")
            }
            {...getPinpointButtonClickEventProp({
              command: 'orders filter by all',
              currentPage: pathname,
            })}
          > all </Tab>

          <Tab
            {...tabProps}
            onClick={
              () => setStatus("CLOSED")
            }
            {...getPinpointButtonClickEventProp({
              command: 'orders filter by closed',
              currentPage: pathname,
            })}
          > completed </Tab>

          <Tab
            {...tabProps}
            onClick={
              () => setStatus("IN_PROGRESS")
            }
            {...getPinpointButtonClickEventProp({
              command: 'orders filter by in progress',
              currentPage: pathname,
            })}
          > delivering </Tab>


          <Tab
            {...tabProps}
            minW={"90px"}
            onClick={
              () => setStatus("OPEN")
            }
            {...getPinpointButtonClickEventProp({
              command: 'orders filter by open',
              currentPage: pathname,
            })}
          > awaiting payment </Tab>

        </TabList>
      </Tabs>

      <SimpleGrid
        columns={{
          base: 1,
          md: 3,
          lg: 4,
          xl: 5
        }}
        p={"1px"}
        spacing={4}
        width={"full"}
      >
        {
          data && data.pages && data.pages.map((page, pageIndex) => {
            return (
              <Fragment key={pageIndex}>
                {page && page.orders.map((order, orderIndex) => {
                  return (
                    <Order
                      key={order.order.id}
                      order={order}
                      ref={pageIndex === data.pages.length - 1 && orderIndex === page.orders.length - 1 ? ref : null}
                    />
                  )
                })}
              </Fragment>
            )
          })
        }

        {isLoading && <Skeletons height={"132px"} borderRadius={"4px"} count={10} />}

      </SimpleGrid>

      {
          !isLoading && data && (!data.pages.length ||  data.pages[0].orders.length < 1) && (
            <VStack
              spacing={2}
              p={4}
              py={12}
              borderRadius={"8px"}
              bg={noOrderBG}
              color={noOrdersColor}
              width={"full"}
            >
              <Text> {status === "all" ? "You have not made any orders yet." : "No orders found for your search criteria."} </Text>
            </VStack>
          )
        }
        {isLoading && <Skeletons height={"132px"} borderRadius={"4px"} count={10} />}
    </Main>

  )
}