
import {
  Link as ChakraLink, useColorModeValue
} from "@chakra-ui/react";


import { forwardRef } from 'react';
import { useNavigate } from "react-router-dom";

const Link = forwardRef((props, ref) => {
  
  const { href, children, ...rest } = props;
  const navigate = useNavigate();

  return (
    <ChakraLink
      href={href}
      ref={ref}
      onClick={
        e => {
          e.preventDefault();
          navigate(href)
        }
      }
      color={useColorModeValue("blue","blue.200")}
      textDecor={"none"}
      _focus={{
        outline: 0,
        fontWeight: "semibold"
      }}
      { ...rest }
    >
      { children }
    </ChakraLink>
  )
});

export default Link;