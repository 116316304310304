

import { useRecoilValue } from "recoil";
import { isAuthenticatedAtom } from "../../recoil/atoms";
import Authenticated from "./authenticated";
import Onboarding from "./onboarding";

export default function Home({ ...rest }) {
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  return isAuthenticated ? <Authenticated /> : <Onboarding />
}